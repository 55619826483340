<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-center">
                            <img src="assets/images/log111.png" alt="logo.png">
                        </div>

                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-8 text-center" style="padding-left: 14%">
                                <form>
                                    <div class="form-group display-table vertical-align">
                                        <input type="search" name="query" [(ngModel)]="query" class="form-control form-control-lg input-lg" style="font-family:Arial, FontAwesome; text-align: left; " placeholder="&#xF002; Search Borrower by Name, NIC N°, Phone or Collateral Reference" (keyup.enter)="onBeginSearch()">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="card m-t-10 ">
                            <div class="card-header ">
                                <div class="row align-items-end ">
                                    <div class="col-lg-7 ">
                                        <h5 class="text-danger">Members Blacklist</h5>
                                        &nbsp;&nbsp;&nbsp;
                                        <h5>Total: {{pageChapter.totalElements}}</h5>
                                    </div>
                                    <div class="col-lg-5">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <select #pag name="pag" class="form-control searchForm" (change)="onChangeNumber(pag.value)">
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="500">500</option>
                                                    <option value="0">ALL</option>
                                                </select>
                                            </div>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block ">
                                <div class="table-responsive">
                                    <table id="demo-foo-filtering " class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg ">
                                        <thead>
                                            <tr class="footable-header table-inverse ">
                                                <th class="footable-sortable footable-first-visible " style="display: table-cell; ">Member N°<span class="fooicon fooicon-sort "></span></th>
                                                <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Member Name<span class="fooicon fooicon-sort "></span></th>
                                                <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Credit Union<span class="fooicon fooicon-sort "></span></th>
                                                <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Branch<span class="fooicon fooicon-sort "></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let p of filterPbs">
                                                <td class="footable-first-visible " style="display: table-cell; ">{{p.num_cli}}</td>
                                                <td style="display: table-cell; ">{{p.client_name}}</td>
                                                <td style="display: table-cell; ">{{p.bank_name}}</td>
                                                <td style="display: table-cell; ">
                                                    {{p.branch_name}}
                                                </td>

                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr class="footable-paging text-center ">
                                                <td colspan="5 ">
                                                    <ul class="pagination ">
                                                        <li class="footable-page-nav" data-page="prev"><a class="footable-page-link" href="javascript:void(0)" (click)="previousClick()">Previous</a></li>
                                                        <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==currentSelectedPage}" data-page="1"><a (click)="getPaginationWithIndex(i, pagnum)" class="footable-page-link" href="javascript:void(0)">{{i}}</a></li>
                                                        <li class="footable-page-nav" data-page="next"><a class="footable-page-link" href="javascript:void(0)" (click)="nextClick()">Next</a></li>

                                                    </ul>
                                                    <div class="divider "></div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
