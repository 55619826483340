import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
import { AuthService } from "./auth.service";
//import { CryptoService } from './crypto.service';
import { APIResponse } from "./models";
import { TrameUtilService } from "./trame-util.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // req.url.indexOf('patients/new') === -1 &&
  constructor(
    private auth: AuthService,
    private router: Router,
    private cs: TrameUtilService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.indexOf("login") === -1 &&
      req.url.indexOf("register") === -1 &&
      req.url.indexOf("upload") === -1 &&
      req.url.indexOf("change-pass") === -1 &&
      req.url.indexOf("forgot/password") === -1 &&
      req.url.indexOf("verify/otp") === -1 &&
      req.url.indexOf("set-new-password") === -1
    ) {
      let authReq: HttpRequest<any>;
      authReq = req.clone({
        setHeaders: {
          //'User-Agent': `${this.auth.getDeviceInfo()}`,
          UUID: `${this.auth.getToken()}`,
          PRINTBY: `${this.auth.getUser().name}`,
        },
        body: this.cs.encrypt(JSON.stringify(req.body)),
      });
      // console.log(authReq);
      // send cloned request with header to the next handler.
      return next.handle(authReq).pipe(
        map(
          (event) => {
            if (
              event instanceof HttpResponse &&
              req.url.indexOf("i18n") === -1
            ) {
              event = event.clone({
                body: this.cs.GetResponse<APIResponse>(event.body),
              });
            }
            // showLoader(false);
            //console.log(event);
            return event;
          },
          catchError((error: HttpErrorResponse) => {
            let errorMessage = "";
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Error Code: ${
                error.status
              }\nMessage: ${this.cs.decrypt(error.error)}`;
            }
            // showLoader(false);
            return throwError(errorMessage);
          })
        )
      );
    } else {
      return next.handle(req).pipe(
        map(
          (event) => {
            if (event instanceof HttpResponse) {
              event = event.clone({
                body: this.cs.GetEntityFromTrame<HttpResponse<APIResponse>>(
                  event.body
                ),
              });
            }
            //console.log(event);
            // showLoader(false);
            return event;
          },
          catchError((error: HttpErrorResponse) => {
            if (error.message === "Access Denied") {
              this.auth.clearTokens();
              this.router.navigate(["/login"]);
            }
            // showLoader(false);
            return throwError(error);
          })
        )
      );
    }
  }
}
