import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/DataService';
import { BillingResponseType, APIResponse, PageChapter, SituationRequestType, BillingDetail, Operations } from 'src/app/shared/models';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  page: number;
  pageCredit: PageChapter;
  pages: Array<number>;
  bills: Array<BillingResponseType>;
  srt: SituationRequestType;
  bilDet: Array<BillingDetail>;
  showDetail: boolean;
  operations: Array<Operations>;
  detailTot: number;
  tot: number;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService) {

  }

  ngOnInit() {
    this.initView();
  }

  initView() {
    this.page = 0;
    this.pageCredit = {} as PageChapter;
    this.bills = new Array<BillingResponseType>();
    this.srt = {} as SituationRequestType;
    this.bilDet = new Array<BillingDetail>();
    this.getOpreationsList();
    this.getbillings(this.page);
    this.showDetail = false;
  }

  getbillings(page: number): void {
    showLoader(true);
    this.ds.get(`/settings/billing-list`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.pageCredit = res.body.data;
        this.pages = new Array(this.pageCredit.totalPages);
        this.bills = this.pageCredit.content;

        this.tot = 0;
        this.bills.forEach(item => {
          item.agc.forEach((line) => {
            this.tot += Number(line.TOTAL_AMOUNT);
          });
        });
      }
    }, (err) => {
     // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }

  getBillingDetail(bank: string, branch: string) {
    this.srt.bank = bank;
    this.srt.branch = branch;

    // console.log(this.srt);
    showLoader(true);
    this.ds.post(`/settings/billing-detail`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        // console.log(res.body.data);
        this.bilDet = res.body.data;
        this.showDetail = true;
        this.detailTot = 0;
        this.bilDet.forEach(item => {
          this.detailTot += Number(item.TOTAL_AMOUNT);
        });
      } else {
        //console.log(res.body.message);
        this.alert.error('SERVER ERROR', res.body.message); 
        showLoader(false);
      }
    }, (err) => {
      showLoader(false);
      //console.log(err);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });

  }

  closeDetail() {
    this.showDetail = false;
  }

  getOpreationsList = function () {
    this.operations = [
      { name: 'SEARCH', value: 'S' },
      { name: 'BLACKLISTING', value: 'B' },
      { name: 'REMOVAL FROM BLACKLIST', value: 'RB' },
      { name: 'VISUALIZING POSIBLE DELIQUENT LOANS', value: 'DL' },
      { name: 'VISUALIZING LOANS', value: 'LP' },
      { name: 'VISUALIZING LOAN SITUATIONS', value: 'LS' },
    ];
  }

  getOperationName(value) {
    return this.operations.filter((item) => {
      return item.value === value;
    })[0];
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.getbillings(i);
  }

  backClicked() {
    this.loc.back();
  }

}
