<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Blacklist History</h5>
                    <span>This page list all existing blacklist history</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-12">
                        <button class="btn btn-mat waves-effect waves-light btn-primary" (click)="printBlacklistReport()"><i class="fa fa-print"></i>Print</button>
                        <div class="card m-t-10">
                            <div class="card-header">
                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>List</h5>
                                    </div>
                                    <div class="col-lg-5">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <select #pag name="pag" class="form-control searchForm" (change)="onChangeNumber(pag.value)">
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="500">500</option>
                                                    <option value="0">ALL</option>
                                                </select>
                                            </div>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">

                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped  footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Member N°<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Member Name<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Status<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Approval Left<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Admin Approuver<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Chapter Admin Approver<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Date & Time<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Action<span class="fooicon fooicon-sort"></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let bl of filterBls">
                                                <td class="footable-first-visible" style="display: table-cell;">{{bl.num_cli}}</td>
                                                <td style="display: table-cell;">{{bl.client_name}}</td>
                                                <td style="display: table-cell;">
                                                    <span *ngIf="bl.is_blocked  == 0" class="pcoded-badge label label-warning">UnBlacklisted</span>
                                                    <span *ngIf="bl.is_blocked == 1" class="pcoded-badge label label-primary">Pending Approval</span>
                                                </td>
                                                <td style="display: table-cell;">
                                                    <span *ngIf="bl.admin_id && bl.chapter_admin_id" class="pcoded-badge label label-success">0</span>
                                                    <span *ngIf="bl.admin_id && !bl.chapter_admin_id" class="pcoded-badge label label-warning">1</span>
                                                    <span *ngIf="!bl.admin_id && !bl.chapter_admin_id" class="pcoded-badge label label-danger">2</span>
                                                </td>
                                                <td style="display: table-cell;">{{bl.admin_name ? bl.admin_name : "Unavailable"}}</td>
                                                <td style="display: table-cell;">{{bl.chapter_admin_name ? bl.chapter_admin_name : "Unavailable"}}</td>
                                                <td style="display: table-cell;">{{bl.date_unblocked}}</td>
                                                <td style="display: table-cell;">
                                                    <a *ngIf="bl.is_approved && usr.roleId==1" routerLink="/management/blacklist-history/detail/{{bl.sit_id}}" class="tabledit-edit-button btn btn-primary waves-effect waves-light"><span class="fa fa-eye"></span></a>
                                                    <a *ngIf="!bl.is_approved && usr.roleId==2" routerLink="/management/blacklist-history/detail/{{bl.sit_id}}" class="tabledit-edit-button btn btn-primary waves-effect waves-light"><span class="fa fa-eye"></span></a>
                                                    <button type="button" (click)="onUnblock(bl.id)" class="tabledit-delete-button btn btn-danger waves-effect waves-light m-l-10"><span class="icofont icofont-unlocked" title="Un-blacklist"></span></button>
                                                </td>

                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr class="footable-paging">
                                                <td colspan="5">
                                                    <ul class="pagination ">
                                                        <li class="footable-page-nav" data-page="prev"><a class="footable-page-link" href="javascript:void(0)" (click)="previousClick()">Previous</a></li>
                                                        <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==currentSelectedPage}" data-page="1"><a (click)="getPaginationWithIndex(i, pagnum)" class="footable-page-link" href="javascript:void(0)">{{i}}</a></li>
                                                        <li class="footable-page-nav" data-page="next"><a class="footable-page-link" href="javascript:void(0)" (click)="nextClick()">Next</a></li>

                                                    </ul>
                                                    <div class="divider"></div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
