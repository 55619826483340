import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { DataService } from 'src/app/shared/DataService';
import { APIResponse, BankResponseType, AgencyResponseType, Agency, SituationRequestType, Situation, PageChapter, User, Bank, Chapter } from 'src/app/shared/models';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/printer.service';

declare var showLoader;

@Component({
  selector: 'app-loan-analysis',
  templateUrl: './loan-analysis.component.html',
  styleUrls: ['./loan-analysis.component.css']
})
export class LoanAnalysisComponent implements OnInit {

  page: number;
  pageCredit: PageChapter;
  pages: Array<number>;
  banks: Array<BankResponseType>;
  fbanks: Array<BankResponseType>;
  fbank: BankResponseType;
  branchs: Array<Agency>;
  sbranchs: Array<Agency>;
  sebranchs: Array<Agency>;
  showBranch: boolean;
  srt: SituationRequestType;
  sits: Array<Situation>;
  filterSits: Array<Situation>;
  usr: User;
  base64File: string;
  currentSelectedPage: number = 0;
  pag: number;
  pagnum: number;

  constructor(private ds: DataService, private loc: Location, private auth: AuthService, private alert: ToastrService) {
    this.initView();
  }

  ngOnInit(): void {

  }

  initView() {
    this.page = 0;
    this.pageCredit = {} as PageChapter;
    this.usr = {} as User;
    this.sits = new Array<Situation>();
    this.filterSits = new Array<Situation>();
    this.banks = new Array<BankResponseType>();
    this.fbanks = new Array<BankResponseType>();
    this.fbank = {} as BankResponseType;
    this.fbank.bank = {} as Bank;
    this.fbank.chapter = {} as Chapter;
    this.branchs = new Array<Agency>();
    this.sbranchs = new Array<Agency>();
    this.sebranchs = new Array<Agency>();
    this.showBranch = false;
    this.srt = {} as SituationRequestType;
    // this.sits = new Array<Situation>();
    this.base64File = '';
    this.pages =[];
    this.pagnum = 20;
    this.usr = this.auth.getUser();
    this.getbanks();
    //this.getBranches();
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.banks = res.body.data;
        this.fbanks = this.banks.filter((x) => {
          return x.chapter?.code.toLowerCase() === this.usr.codchap.toLowerCase();
        });

        if(this.usr.bqId != null) {
          this.fbank = this.banks.find((x) => {
            return x.bank.code.toLowerCase() === this.usr.bqId.toLowerCase();
          });
        }

        this.getBranches();
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getBranches(): void {
    showLoader(true);
    this.ds.get(`/branches/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.branchs = res.body.data;
        this.sbranchs = this.branchs.filter((x) => {
          return x.bqId === this.fbank.bank.id;
        });
        this.sebranchs = this.branchs.filter((x) => {
          return x.bqId === this.fbank.bank.id;
        });
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onSelectBank(value: string) {
    if (value == null && value === undefined && value === '') {
      this.showBranch = false;
    } else {
      this.sbranchs = this.branchs.filter((x) => {
        return Number(x.bqId) === Number(value);
      });
      let bk = this.banks.find((x) => {
        return Number(x.bank.id) === Number(value);
      });
      this.srt.bank = bk.bank.code;
      this.showBranch = true;
    }

  }

  onLoadData(page: number) {
      if (this.usr.roleId == 1) {
        this.srt.isBilling = false;
      } else if (this.usr.roleId == 3) {
        this.srt.bank = this.usr.bqId;
        this.srt.isBilling = true;
        this.srt.username = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.username : '';
        this.srt.name = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.name : '';
      }

      // console.log(this.srt);
      showLoader(true);
      this.ds.post(`/situations/list/${this.pagnum}?page=${page}`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
        if (res.body.status === 200) {
          showLoader(false);
          //console.log(res.body.data);
          this.pageCredit = res.body.data;
          this.pages = Array(this.pageCredit.totalPages).fill(0).map((x,i)=>i);
          this.sits = this.pageCredit.content;
          this.filterSits = this.pageCredit.content;
          //console.log(this.sits);
        } else {
          //console.log(res.body.message);
          this.alert.error('ERROR', res.body.message);
          showLoader(false);
        }
      }, (err) => {
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
        //console.log(err);
      });
  }

  setPage(i) {
    this.onLoadData(i);
  }

  getPaginationWithIndex(index: number, pag: any) {
    this.setPage(index);
  }

  active(index: number) {
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  onChangeNumber(pag: number){
    try {
      this.pagnum = pag;
      this.onLoadData(0);
    } catch (error) {

    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.pageCredit.totalPages - 1) {
      this.setPage(++this.currentSelectedPage);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.setPage(--this.currentSelectedPage);
    }
  }


  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterSits = this.sits.filter((cre) => {
        return cre.numdos.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          cre.numcli.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          cre.nomcli.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    } else {
      this.filterSits = this.sits;
    }
  }

  onDelete(id: number) {
    showLoader(true)
    this.ds.delete('/situations/delete/' + id)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.status === 200) {
          showLoader(false);
          this.initView();
        } else {
          showLoader(false);
          this.alert.error('ERROR', res.body.message);
        }
      }, (err) => {
        //console.log(err);
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });

  }

  backClicked() {
    this.loc.back();
  }

  printLoanAnalReport(): void {
    showLoader(true);
    this.ds.post(`/situations/reports/list`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

}
