import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID} from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';

//Some import before, add this
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialogModule } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ManagementModule } from './features/management/management.module';
import { ReportsModule } from './features/reports/reports.module';
import { AppRoutingModule } from './app-routing.module';
import { AccountModule } from './features/account/account.module';
import { TrameUtilService } from './shared/trame-util.service';
import { AuthService } from './shared/auth.service';
import { AuthGuard } from './shared/auth.guard';
import { EncrDecrService } from './shared/EncrDecrService';
import { InvokerService } from './shared/invoker.service';
import { DataService } from './shared/DataService';
import { HttpClientModule } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TreeviewModule } from 'ngx-treeview';
import { MenuServiceEn } from './shared/menu.en.service';
import { ConfigurationModule } from './features/configuration/configuration.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PaginationComponent } from './features/account/pagination/pagination.component';
import { MerchandConfigurationComponent } from './features/configuration/merchand-configuration/merchand-configuration.component';
import { RetroCommissionModuleComponent } from './features/account/retro-commission-module/retro-commission-module.component';
import { UploadSynchronisationComponent } from './features/configuration/upload-synchronisation/upload-synchronisation.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

registerLocaleData(localeFr, 'fr')
registerLocaleData(localeHu, 'hu')

@NgModule({
  declarations: [
    AppComponent,
    UploadSynchronisationComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    ManagementModule,
    ReportsModule,
    AccountModule,
    ConfigurationModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    UiSwitchModule,
    MatDialogModule,
    SweetAlert2Module.forRoot(),
    TreeviewModule.forRoot(), // required animations module
    ToastrModule.forRoot(),
  ],
  providers: [
    TrameUtilService,
    AuthService,
    AuthGuard,
    EncrDecrService,
    InvokerService,
    DataService,
    MenuServiceEn,
    DeviceDetectorService,
    { provide: MAT_DATE_LOCALE, useValue: 'en' }, //you can change useValue
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
