import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users/users.component';
import { ManagementRoutingModule } from './management-routing-module';
import { ChapterListComponent } from './chapter-list/chapter-list.component';
import { ChapterNewComponent } from './chapter-new/chapter-new.component';
import { ChapterEditComponent } from './chapter-edit/chapter-edit.component';
import { BrancheListComponent } from './branche-list/branche-list.component';
import { BrancheNewComponent } from './branche-new/branche-new.component';
import { BrancheEditComponent } from './branche-edit/branche-edit.component';
import { CreditEditComponent } from './credit-edit/credit-edit.component';
import { CreditNewComponent } from './credit-new/credit-new.component';
import { CreditListComponent } from './credit-list/credit-list.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserNewComponent } from './user-new/user-new.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { LoanAnalysisComponent } from './loan-analysis/loan-analysis.component';
import { BlacklistHistComponent } from './blacklist-hist/blacklist-hist.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { UserRoleComponent } from './user-role/user-role.component';
import { TreeviewModule } from 'ngx-treeview';
import { HomeComponent } from './home/home.component';
import { LoanAnalysisDetailComponent } from './loan-analysis-detail/loan-analysis-detail.component';
import { SearchBorrowersComponent } from './search-borrowers/search-borrowers.component';
import { PermanentBlockComponent } from './permanent-block/permanent-block.component';
import { UnblockComponent } from './unblock/unblock.component';
import { BlacklistDetailComponent } from './blacklist-detail/blacklist-detail.component';
import { UiSwitchModule } from 'ngx-ui-switch';


@NgModule({
  declarations: [
    UsersComponent,
    ChapterListComponent,
    ChapterNewComponent,
    ChapterEditComponent,
    BrancheListComponent,
    BrancheNewComponent,
    BrancheEditComponent,
    CreditEditComponent,
    CreditNewComponent,
    CreditListComponent,
    UserListComponent,
    UserNewComponent,
    UserEditComponent,
    LoanAnalysisComponent,
    BlacklistHistComponent,
    UserRoleComponent,
    HomeComponent,
    LoanAnalysisDetailComponent,
    SearchBorrowersComponent,
    PermanentBlockComponent,
    UnblockComponent,
    BlacklistDetailComponent
  ],
  imports: [
    CommonModule,
    ManagementRoutingModule,
    SharedModule,
    FormsModule,
    UiSwitchModule,
    TreeviewModule.forRoot(),
    
  ],
  exports: [
    UsersComponent,
    ChapterListComponent,
    ChapterNewComponent,
    ChapterEditComponent,
    CreditEditComponent,
    CreditNewComponent,
    CreditListComponent,
    UserListComponent,
    UserNewComponent,
    UserEditComponent,
    LoanAnalysisComponent,
    BlacklistHistComponent,
    UserRoleComponent,
    HomeComponent,
    LoanAnalysisDetailComponent,
    SearchBorrowersComponent,
    PermanentBlockComponent,
    UnblockComponent,
    BlacklistDetailComponent
  ]
})
export class ManagementModule { }