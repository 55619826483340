import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LoanResponseType, SituationRequestType, PageChapter, BankResponseType, Agency, APIResponse, User, Bank, Chapter, LoanBalanceResponseType } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/printer.service';

declare var showLoader;

@Component({
  selector: 'app-loan-portfolio',
  templateUrl: './loan-portfolio.component.html',
  styleUrls: ['./loan-portfolio.component.css']
})
export class LoanPortfolioComponent implements OnInit {

  page: number;
  pageCredit: PageChapter;
  pages: Array<number>;
  banks: Array<BankResponseType>;
  fbanks: Array<BankResponseType>;
  fbank: BankResponseType;
  branchs: Array<Agency>;
  sbranchs: Array<Agency>;
  sebranchs: Array<Agency>;
  showBranch: boolean;
  srt: SituationRequestType;
  lrts: Array<LoanResponseType>;
  lbrt: LoanBalanceResponseType;
  filterLrts: Array<LoanResponseType>;
  usr: User;
  balance: number;
  base64File: string;

  constructor(private ds: DataService, private loc: Location, private auth: AuthService, private alert: ToastrService) {
    this.initView();

  }

  ngOnInit(): void {
  }

  initView() {
    this.page = 0;
    this.pageCredit = {} as PageChapter;
    this.lrts = new Array<LoanResponseType>();
    this.filterLrts = new Array<LoanResponseType>();
    this.banks = new Array<BankResponseType>();
    this.fbanks = new Array<BankResponseType>();
    this.branchs = new Array<Agency>();
    this.sebranchs = new Array<Agency>();
    this.fbank = {} as BankResponseType;
    this.fbank.bank = {} as Bank;
    this.fbank.chapter = {} as Chapter;
    this.branchs = new Array<Agency>();
    this.sbranchs = new Array<Agency>();
    this.showBranch = false;
    this.srt = {} as SituationRequestType;
    this.usr = {} as User;
    this.lbrt = {} as LoanBalanceResponseType;
    this.balance = 0;
    this.base64File = '';
    // this.sits = new Array<Situation>();
    this.usr = this.auth.getUser();
    this.getbanks();
   // this.getBranches();
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.banks = res.body.data;


        this.fbanks = this.banks.filter((x) => {
          return this.usr.codchap ? x.chapter?.code.toLowerCase() === this.usr.codchap.toLowerCase() : x;
        });


        if(this.usr.bqId != null) {
          this.fbank = this.banks.find((x) => {
            return x.bank.code.toLowerCase() === this.usr.bqId.toLowerCase();
          });
        }

        this.getBranches();
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getBranches(): void {
    showLoader(true);
    this.ds.get(`/branches/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.branchs = res.body.data;
        this.sbranchs = this.branchs.filter((x) => {
          return Number(x.bqId) === Number(this.fbank.bank.id);
        });
        this.sebranchs = this.branchs.filter((x) => {
          return x.bqId === this.fbank.bank.id;
        });
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }


  onSelectBank(value: string) {
    if (value == null && value === undefined && value === '') {
      this.showBranch = false;
    } else {
      this.sbranchs = this.branchs.filter((x) => {
        return Number(x.bqId) === Number(value);
      });
      let bk = this.banks.find((x) => {
        return Number(x.bank.id) === Number(value);
      });
      this.srt.bank = bk.bank.code;
      this.showBranch = true;
    }

  }

  onSelectBank1(value: string) {
    if (value == null && value === undefined && value === '') {
      this.showBranch = false;
    } else {
     // console.log(this.branchs);
      this.sbranchs = this.branchs.filter((x) => {
        let bank = this.fbanks.find(bk => bk.bank.id === x.bqId);
        return value.toLowerCase() === bank.bank.code.toLowerCase();
      });
      //console.log(this.sbranchs);
      this.showBranch = true;
    }

  }

  onLoadData(page: number) {
    showLoader(true);
    if (this.usr.roleId != 2) {
      if (this.usr.roleId == 1) {
        this.srt.isBilling = false;
      } else if (this.usr.roleId == 3) {
        this.srt.bank = this.usr.bqId;
        this.srt.isBilling = true;
        this.srt.username = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.username : '';
        this.srt.name = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.name : '';
      }
      this.ds.post(`/loans/list?page=${page}`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
        if (res.body.status === 200) {
          showLoader(false);
          this.pageCredit = res.body.data;
          //console.log(this.pageCredit);
          this.pages = new Array(this.pageCredit.totalPages);
          this.lrts = this.pageCredit.content;
          this.filterLrts = this.pageCredit.content;
         // console.log(this.filterLrts);
        } else {
          //  console.log(res.body.message);
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
        }
      }, (err) => {
        showLoader(false);
        //console.log(err);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });
    }
  }

  getLoansBalances() {
    this.balance = 0;
    if (this.usr.roleId != 2) {
      if (this.usr.roleId == 1) {
        this.srt.isBilling = false;
      } else if (this.usr.roleId == 3) {
        this.srt.bank = this.usr.bqId;
        this.srt.isBilling = true;
        this.srt.username = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.username : '';
        this.srt.name = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.name : '';
      }
      showLoader(true);
      this.ds.post(`/loans/balance`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
        if (res.body.status === 200) {
          showLoader(false);
          this.balance = res.body.data;
        } else {
          // console.log(res.body.message);
          showLoader(false);
        }
      }, (err) => {
        showLoader(false);
        //console.log(err);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });
    }
  }

  printLoanPortofolioReport() {
    //if (this.usr.roleId != 2) {
      if (this.usr.roleId == 1) {
        this.srt.isBilling = false;
      } else if (this.usr.roleId == 3) {
        this.srt.bank = this.usr.bqId;
        this.srt.isBilling = true;
        this.srt.username = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.username : '';
        this.srt.name = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.name : '';
      }
      showLoader(true);
      this.ds.post(`/loans/reports/list`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {

        if (res.body.status === 200) {
          showLoader(false);
          this.base64File = res.body.data;
          setTimeout(() => {
            Printer.print2(encodeURI(this.base64File), 'application/pdf');
          }, 300);
         // console.log(res.body.data);
        }else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
          //console.log(res.body.message);
        }
      }, (err) => {
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });
   // }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.onLoadData(i);
  }


  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterLrts = this.lrts.filter((cre) => {
        return cre.loan.numcli.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          cre.loan.nomcli.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          cre.loan.numdos.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    } else {
      this.filterLrts = this.lrts;
    }
  }

  onDelete(id: number) {
    showLoader(true)
    this.ds.delete('/situations/delete/' + id)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.status === 200) {
          showLoader(false);
          this.initView();
        } else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
        }
      }, (err) => {
        // console.log(err);
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });

  }

  backClicked() {
    this.loc.back();
  }

}
