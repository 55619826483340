import { Component, OnInit } from '@angular/core';
import { AuthModel, APIResponse, User } from 'src/app/shared/models';
import { UUID } from 'angular2-uuid';
import { Md5 } from 'md5-typescript';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { DataService } from 'src/app/shared/DataService';
import { AuthService } from 'src/app/shared/auth.service';
import { Router } from '@angular/router';
import { TrameUtilService } from 'src/app/shared/trame-util.service';

declare var showLoader;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login: AuthModel;
  headers: HttpHeaders;
  msg: string;
  pwd: string;

  constructor(private ds: DataService, private auth: AuthService,
    private router: Router, private trame: TrameUtilService) {
    this.headers = new HttpHeaders();
    this.initView();
  }

  ngOnInit(): void {
  }

  //#region CRUD OPERATIONS
  onSignIn(): void {
    // this.login.password = Md5.init(this.pwd);
    this.login.password = btoa(this.pwd)
    this.login.uuid = UUID.UUID();
    this.login.module = 'CRM';

    showLoader(true);
    this.ds.post('/users/login', JSON.stringify(this.login))
      .subscribe((res: HttpResponse<APIResponse>) => {
        showLoader(false);

        if (res.body.status === 200) {

          const info = res.body.data as User;
          this.auth.setToken(this.login.uuid);

          this.auth.setUser(info);
          this.router.navigateByUrl('/home');
          setTimeout(() => {
            localStorage.removeItem("defaultPassword");
          }, 10);
          setTimeout(() => {
            location.reload();
          }, 20);
        } else if (res.body.status === 4232) {
          localStorage.setItem("defaultPassword", "true");
          localStorage.setItem("UUID", res.body.data);
          this.router.navigateByUrl('/change-default-password');
        } else {
          showLoader(false);
          this.msg = res.body.message;
        }
      }), (err) => {
        showLoader(false);
      }

  }

  forgotPassword() {
    localStorage.setItem("forgotPassword", "true");
  }
  //#endregion

  //#region UI Controlers
  initView(): void {
    this.login = {} as AuthModel;
  }
  //#endregion

}
