import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersLogComponent } from './users-log/users-log.component';
import { BillingComponent } from './billing/billing.component';
import { ReportsRoutingModule } from './reports-routing-module';
import { FormsModule } from '@angular/forms';
import { BillingSimulComponent } from './billing-simul/billing-simul.component';
import { PaymentHistComponent } from './payment-hist/payment-hist.component';
import { LoanAgeingComponent } from './loan-ageing/loan-ageing.component';
import { LoanPortfolioComponent } from './loan-portfolio/loan-portfolio.component';
import { LoanPortfolioBalanceComponent } from './loan-portfolio-balance/loan-portfolio-balance.component';
import { MonthSituationComponent } from './month-situation/month-situation.component';
import { ProvisionComponent } from './provision/provision.component';



@NgModule({
  declarations: [
    UsersLogComponent, 
    BillingComponent, 
    BillingSimulComponent, 
    PaymentHistComponent, 
    LoanAgeingComponent, 
    LoanPortfolioComponent, 
    LoanPortfolioBalanceComponent, 
    MonthSituationComponent, 
    ProvisionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReportsRoutingModule
  ],
  exports: [
    UsersLogComponent, 
    BillingComponent, 
    BillingSimulComponent, 
    PaymentHistComponent, 
    LoanAgeingComponent, 
    LoanPortfolioComponent, 
    LoanPortfolioBalanceComponent, 
    MonthSituationComponent, 
    ProvisionComponent
  ]
})
export class ReportsModule { }
