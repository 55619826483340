import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "./shared/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  isLogin = false;
  isChangeDefaultPassword = false;
  isForgotPassword = false;
  isOtpVerification = false;
  isSetNewPassword = false;

  constructor(private router: Router, private auth: AuthService) {
    if (this.auth.getUser() == null && this.auth.getUser() == undefined) {
      if (localStorage.getItem("defaultPassword") == "true") {
        this.router.navigateByUrl("/change-default-password");
      } else if (localStorage.getItem("forgotPassword") == "true") {
        this.router.navigateByUrl("/forgot-password");
      } else if (localStorage.getItem("otpVerification") == "true") {
        this.router.navigateByUrl("/otp-verification");
      } else if (localStorage.getItem("setNewPassword") == "true") {
        this.router.navigateByUrl("/set-new-password");
      } else {
        this.router.navigateByUrl("/login");
      }
    }
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.urlAfterRedirects === "/login") {
          this.isLogin = true;
        } else if (val.urlAfterRedirects === "/change-default-password") {
          this.isChangeDefaultPassword = true;
        } else if (val.urlAfterRedirects === "/forgot-password") {
          this.isForgotPassword = true;
        } else if (val.urlAfterRedirects === "/otp-verification") {
          this.isOtpVerification = true;
        } else if (val.urlAfterRedirects === "/set-new-password") {
          this.isSetNewPassword = true;
        } else {
          this.isLogin = false;
        }
      }
    });
  }
}
