<div class="page-header card">
  <div class="row align-items-end">
    <div class="col-lg-7">
      <div class="page-header-title">
        <a href="javascript:void(0);" (click)="backClicked()"
          ><i class="feather icon-arrow-left bg-c-blue"></i
        ></a>
        <div class="d-inline">
          <h5>Possible Deliquent Loans</h5>
          <span>This page list all existing Possible Deliquent Loans</span>
        </div>
      </div>
    </div>
    <div class="col-lg-5">
      <button
        (click)="initView()"
        class="btn waves-effect waves-light btn-info float-right"
      >
        <i class="ti-reload"></i>Reload
      </button>
    </div>
  </div>
</div>

<div class="pcoded-inner-content">
  <div class="main-body">
    <div class="page-wrapper">
      <div class="page-body">
        <div class="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-10">
            <div class="row" *ngIf="usr.roleId == 1 || usr.roleId == 6">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Credit Union</label>

                  <select
                    name="bank "
                    #bank
                    class="form-control"
                    (change)="onSelectBank(bank.value)"
                  >
                    <option value="">&nbsp;</option>
                    <option *ngFor="let b of banks" value="{{ b.bank.id }} ">
                      {{ b.bank.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Branch</label>
                  <select
                    [(ngModel)]="srt.branch"
                    name="branch "
                    class="form-control"
                    [disabled]="!showBranch"
                  >
                    <option value="">&nbsp;</option>
                    <option *ngFor="let s of sbranchs" value="{{ s.code }} ">
                      {{ s.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <button
                  class="btn btn-mat waves-effect waves-light btn-info m-l-20 mt"
                  (click)="onLoadData(0)"
                  [disabled]="!srt.branch || !srt.bank"
                >
                  Load Data
                </button>

                <button
                  (click)="printLoanAnalReport()"
                  class="btn btn-mat waves-effect waves-light btn-primary m-l-30 mt"
                  [disabled]="!srt.branch || !srt.bank"
                >
                  <i class="fa fa-print"></i>Print
                </button>
              </div>
            </div>

            <div class="row" *ngIf="usr.roleId == 2">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Credit Union</label>

                  <select
                    name="bank"
                    #bank
                    class="form-control"
                    (change)="onSelectBank(bank.value)"
                  >
                    <option value="">&nbsp;</option>
                    <option *ngFor="let b of fbanks" [value]="b.bank.id">
                      {{ b.bank.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Branch</label>
                  <select
                    [(ngModel)]="srt.branch"
                    name="branch "
                    class="form-control"
                    [disabled]="!showBranch"
                  >
                    <option value="">&nbsp;</option>
                    <option *ngFor="let s of sbranchs" [value]="s.code">
                      {{ s.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <button
                  class="btn btn-mat waves-effect waves-light btn-info m-l-20 mt"
                  (click)="onLoadData(0)"
                  [disabled]="!srt.branch || !srt.bank"
                >
                  Load Data
                </button>

                <button
                  (click)="printLoanAnalReport()"
                  class="btn btn-mat waves-effect waves-light btn-primary m-l-30 mt"
                  [disabled]="!srt.branch || !srt.bank"
                >
                  <i class="fa fa-print"></i>Print
                </button>
              </div>
            </div>

            <div class="row" *ngIf="usr.roleId != 2 && usr.roleId == 3">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Branch</label>
                  <select
                    [(ngModel)]="srt.branch"
                    name="branch "
                    class="form-control"
                  >
                    <option value="">&nbsp;</option>
                    <option *ngFor="let s of sebranchs" value="{{ s.code }} ">
                      {{ s.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <button
                  class="btn btn-mat waves-effect waves-light btn-info m-l-20 mt"
                  (click)="onLoadData(0)"
                  [disabled]="!srt.branch"
                >
                  Load Data
                </button>

                <button
                  (click)="printLoanAnalReport()"
                  class="btn btn-mat waves-effect waves-light btn-primary m-l-30 mt"
                  [disabled]="!srt.branch"
                >
                  <i class="fa fa-print"></i>Print
                </button>
              </div>
            </div>

            <div class="card m-t-10">
              <div class="card-header">
                <div class="row align-items-end">
                  <div class="col-lg-6">
                    <h5>List of Borrowers</h5>
                  </div>
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-md-3" *ngIf="usr.roleId == 1">
                        <select
                          #pag
                          name="pag"
                          class="form-control searchForm"
                          (change)="onChangeNumber(pag.value)"
                          [disabled]="!srt.branch || !srt.bank"
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                          <option value="0">ALL</option>
                        </select>
                      </div>
                      <div class="col-md-3" *ngIf="usr.roleId != 1">
                        <select
                          #pag
                          name="pag"
                          class="form-control searchForm"
                          (change)="onChangeNumber(pag.value)"
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                          <option value="0">ALL</option>
                        </select>
                      </div>
                      <div class="col-md-9">
                        <input
                          type="text"
                          class="form-control searchForm"
                          placeholder="Search"
                          #qElem
                          (keyup)="onSearch(qElem.value)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-block">
                <div class="table-responsive">
                  <table
                    id="demo-foo-filtering "
                    class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg"
                  >
                    <thead>
                      <tr class="footable-header table-inverse">
                        <th
                          class="footable-sortable footable-first-visible"
                          style="display: table-cell"
                        >
                          File N°<span class="fooicon fooicon-sort"></span>
                        </th>
                        <th
                          data-breakpoints="xs "
                          class="footable-sortable"
                          style="display: table-cell"
                        >
                          Member N°<span class="fooicon fooicon-sort"></span>
                        </th>
                        <th
                          data-breakpoints="xs "
                          class="footable-sortable"
                          style="display: table-cell"
                        >
                          Member Name<span class="fooicon fooicon-sort"></span>
                        </th>
                        <th
                          data-breakpoints="xs "
                          class="footable-sortable"
                          style="display: table-cell"
                        >
                          Status<span class="fooicon fooicon-sort"></span>
                        </th>
                        <th
                          data-breakpoints="xs "
                          class="footable-sortable"
                          style="display: table-cell"
                        >
                          Action<span class="fooicon fooicon-sort"></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sit of filterSits">
                        <td
                          class="footable-first-visible"
                          style="display: table-cell"
                        >
                          {{ sit.numdos }}
                        </td>
                        <td style="display: table-cell">{{ sit.numcli }}</td>
                        <td style="display: table-cell">{{ sit.nomcli }}</td>
                        <td style="display: table-cell">
                          <span
                            *ngIf="sit.isPermanentBlocked == 0"
                            class="pcoded-badge label label-warning"
                            >Deliquency Threat</span
                          >
                          <span
                            *ngIf="sit.isPermanentBlocked == 1"
                            class="pcoded-badge label label-danger"
                            >Blacklisted</span
                          >
                          <span
                            *ngIf="sit.isPermanentBlocked == 2"
                            class="pcoded-badge label label-info"
                            >Pending Approval</span
                          >
                        </td>
                        <td style="display: table-cell">
                          <a
                            routerLink="/management/loan-analysis/detail/{{
                              sit.id
                            }}"
                            class="tabl-edit-edit-button btn btn-default waves-effect waves-light"
                            ><span class="fa fa-eye"></span
                          ></a>
                          <a
                            *ngIf="
                              sit.isPermanentBlocked == 0 && usr.roleId == 3
                            "
                            routerLink="/management/borrowers/permanent-block/{{
                              sit.id
                            }}"
                            class="tabl-edit-edit-button btn btn-danger waves-effect waves-light m-l-10"
                            ><span>Blacklist</span></a
                          >
                          <a
                            *ngIf="
                              sit.isPermanentBlocked == 1 &&
                              usr.roleId == 3 &&
                              sit.isBlocked == 1
                            "
                            routerLink="/management/borrowers/unblock/{{
                              sit.id
                            }}"
                            class="tabl-edit-edit-button btn btn-primary waves-effect waves-light m-l-10"
                            ><span>Unblacklist</span></a
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="footable-paging text-center">
                        <td colspan="5 ">
                          <ul class="pagination">
                            <li class="footable-page-nav" data-page="prev">
                              <a
                                class="footable-page-link"
                                href="javascript:void(0)"
                                (click)="previousClick()"
                                >Previous</a
                              >
                            </li>
                            <li
                              *ngFor="let p of pages; let i = index"
                              class="footable-page visible"
                              [ngClass]="{ active: i == currentSelectedPage }"
                              data-page="1"
                            >
                              <a
                                (click)="getPaginationWithIndex(i, pagnum)"
                                class="footable-page-link"
                                href="javascript:void(0)"
                                >{{ i }}</a
                              >
                            </li>
                            <li class="footable-page-nav" data-page="next">
                              <a
                                class="footable-page-link"
                                href="javascript:void(0)"
                                (click)="nextClick()"
                                >Next</a
                              >
                            </li>
                          </ul>
                          <div class="divider"></div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
