import { Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
// import { AuthUser } from './models';
//import { CookieService } from 'ngx-cookie-service';
import { EncrDecrService } from './EncrDecrService';
import { User } from './models';

declare var imprint: any;


@Injectable()
export class AuthService {
  public static TOKEN_HEADER_NAME = 'Authorization';
  protected USER_KEY = 'syjndkdhzo';
  protected TOKEN_KEY = 'dhlesbj';
  protected LANG = 'lang';

  protected expiredDate = new Date();
  constructor(private crypto: EncrDecrService, private dvs: DeviceDetectorService) {
    this.expiredDate.setDate( this.expiredDate.getDate() + 1 );
  }

 
    /**
   * Get active user from session.
   * @return Object: UserInfo
   */
  getUser(): User {
    if (localStorage.getItem(this.USER_KEY) !== null && localStorage.getItem(this.USER_KEY) !== undefined) {
      let usr = this.crypto.decrypt(localStorage.getItem(this.USER_KEY));
      return JSON.parse(usr) as User;
    } else {
      return null
    }

  }

  /**
   * Set active user in session.
   * @param object: UserInfo
   * @return void
   */
  setUser(user: User): void {
    let usr = this.crypto.encrypt(JSON.stringify(user));
    localStorage.setItem(this.USER_KEY, usr);
  }

  /**
   * Get's user token from local storage
   * @return string
   */
  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  /**
   * Set user token in local storage
   * @return void
   */
  setToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  /**
   * Removes values of hpCode, token and user from local storage
   */
  public clearTokens(): void {
    localStorage.clear();
    localStorage.clear();
  }

  /**
   * Returns Browser information of connected device
   */
  public getDeviceInfo(): DeviceInfo {
    return this.dvs.getDeviceInfo();
  }
}
