<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Billing Statement</h5>
                    <span>This page allows specific user to preview bills</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-8">
                        <div class="row">

                            <div class=" col-sm-3" *ngIf="usr.roleId==1 || usr.roleId==6">
                                <div class="form-group">
                                    <label class="col-form-label">Credit Union</label>

                                    <select #bank class="form-control" (change)="onCreditUnionChange(bank.value)">
        
                                                <option value="">&nbsp;</option>
                                                <option *ngFor="let b of banks" value="{{b.bank.code}}">{{b.bank.name}}</option>
                                                </select>

                                </div>
                                
                            </div>

                            <div class=" col-sm-3" *ngIf="usr.roleId==2">
                                <div class="form-group">
                                    <label class="col-form-label">Credit Union</label>

                                    <select #bank class="form-control" (change)="onCreditUnionChange(bank.value)">
        
                                                <option value="">&nbsp;</option>
                                                <option *ngFor="let b of fbanks" value="{{b.bank.code}}">{{b.bank.name}}</option>
                                                </select>

                                </div>
                            </div>

                            <div class="col-sm-3">
                                <!-- <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 m-t-35" (click)="onLoadData(0)">Fetch Balances</button> -->

                                <div class="form-group">
                                    <label class="col-form-label">Period</label>
                                    <input type="month" class="form-control" #period [(ngModel)]="selectedPeriod" (change)="onPeriodChange(period.value)">
                                </div>
                            </div>

                            <div class="col-sm-2">
                                <!-- <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 m-t-35" (click)="onLoadData(0)">Fetch Balances</button> -->

                                <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="generatePdf()" *ngIf="usr.roleId == 1 || usr.roleId == 2" [disabled]="!bkId"><i class="fa fa-print"></i>EXPORT PDF</button>
                                <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="generatePdf()" *ngIf="usr.roleId != 1 && usr.roleId != 2"><i class="fa fa-print"></i>EXPORT PDF</button>
                            </div>

                            <div class="col-sm-2">

                                <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="exportExcel()" *ngIf="usr.roleId == 1 || usr.roleId == 2" [disabled]="!bkId"><i class="fa fa-print"></i>EXPORT EXCEL</button>
                                <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="exportExcel()" *ngIf="usr.roleId != 1 && usr.roleId != 2"><i class="fa fa-print"></i>EXPORT EXCEL</button>
                            </div>
                        </div>
                        <div class="card m-t-10">
                            <div class="card-block">
                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Credit Union<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Branch<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Member<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Name<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">File N°<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Loan Date<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Motif<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Loan Amount<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Charge Amount<span class="fooicon fooicon-sort"></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let pr of pageOfItems">
                                                <td class="footable-first-visible" style="display: table-cell;">{{pr.loan.bqId}}</td>
                                                <td style="display: table-cell;">{{pr.loan.agccpt}}</td>
                                                <td style="display: table-cell;">{{pr.loan.numcli}}</td>
                                                <td style="display: table-cell;">{{pr.loan.nomcli}}</td>
                                                <td class="footable-first-visible" style="display: table-cell;">{{pr.loan.numdos}}</td>
                                                <td style="display: table-cell;">{{pr.loan.datedem}}</td>
                                                <td style="display: table-cell;">{{pr.loan.motifdem}}</td>
                                                <td style="display: table-cell;">{{pr.loan.mtpret}}</td>
                                                <td style="display: table-cell;">{{pr.chargeAmount}}</td>
                                            </tr>
        
                                        </tbody>
        
                                    </table>

                                    <div class="card-footer pb-0 pt-3">
                                        <app-pagination [items]="prs.paymentListResponse" (changePage)="onChangePage($event)"></app-pagination>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>