<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Loan Ageing Balance</h5>
                    <span>This page list all existing Loan Ageing Balance by Agency</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-12">

                        <div class="card m-t-40">
                            <div class="card-block">
                                <div class="row" *ngIf="usr.roleId == 1 || usr.roleId==6">
                                    <div class="col-sm-1"></div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Credit Union</label>

                                            <select #bank name="bank" class="form-control" (change)="onSelectBank(bank.value)">
                
                                                        <option value="">&nbsp;</option>
                                                        <option *ngFor="let b of banks" [value]="b.bank.id">{{b.bank.name}}</option>
                                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Branch</label>
                                            <select [(ngModel)]="srt.branch" name="branch" class="form-control" [disabled]="!showBranch">
                                                        <option value="">&nbsp;</option>
                                                        <option *ngFor="let s of sbranchs" [value]="s.code">{{s.name}}</option>
                                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <!-- <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 m-t-35" (click)="onLoadData(0)" [disabled]="!srt.branch || !srt.bank">Load Data</button> -->

                                        <button (click)="printLoanAgeingReport()" class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" [disabled]="!srt.branch || !srt.bank"><i class="fa fa-print"></i>Print</button>
                                    </div>
                                </div>

                                <div class="row" *ngIf="usr.roleId == 2">
                                    <div class="col-sm-1"></div>
                                    <div class=" col-sm-4 ">
                                        <div class="form-group ">
                                            <label class="col-form-label ">Credit Union</label>

                                            <select name="bank" #bank class="form-control" (change)="onSelectBank(bank.value)">
                
                                                        <option value="">&nbsp;</option>
                                                        <option *ngFor="let b of fbanks" [value]="b.bank.id">{{b.bank.name}}</option>
                                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-4 ">
                                        <div class="form-group ">
                                            <label class="col-form-label ">Branch</label>
                                            <select [(ngModel)]="srt.branch" name="branch " class="form-control " [disabled]="!showBranch ">
                                                        <option value="">&nbsp;</option>
                                                        <option *ngFor="let s of sbranchs " [value]="s.code">{{s.name}}</option>
                                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-2 ">
                                        <!-- <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 mt " (click)="onLoadData(0) " [disabled]="!srt.branch || !srt.bank ">Load Data</button> -->

                                        <button (click)="printLoanAgeingReport()" class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" [disabled]="!srt.branch || !srt.bank"><i class="fa fa-print"></i>Print</button>
                                    </div>
                                </div>

                                <div class="row " *ngIf="usr.roleId !=2 && usr.roleId==3 ">
                                    <div class="col-sm-3"></div>
                                    <div class="col-sm-4 ">
                                        <div class="form-group ">
                                            <label class="col-form-label ">Branch</label>
                                            <select [(ngModel)]="srt.branch" name="branch " class="form-control ">
                                                        <option value="">&nbsp;</option>
                                                        <option *ngFor="let s of sebranchs " value="{{s.code}} ">{{s.name}}</option>
                                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-2 ">
                                        <!-- <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 mt " (click)="onLoadData(0) " [disabled]="!srt.branch">Load Data</button> -->

                                        <button (click)="printLoanAgeingReport()" class="btn btn-mat waves-effect waves-light btn-primary m-l-30 mt " [disabled]="!srt.branch"><i class="fa fa-print "></i>Print</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="card m-t-10">
                            <div class="card-header">
                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>Loan Ageing Balance</h5>
                                        &nbsp;&nbsp;&nbsp;
                                        <h5>Total: {{pageCredit.totalElements}}</h5>
                                    </div>
                                    <div class="col-lg-5">
                                        <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)">
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">

                                <table id="demo-foo-filtering" class="table table-striped table-responsive footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                    <thead>
                                        <tr class="footable-header table-inverse">
                                            <th rowspan="2" class="footable-sortable footable-first-visible" style="display: table-cell;">Member</th>
                                            <th rowspan="2" class="footable-sortable" style="display: table-cell;">Member Name</th>
                                            <th rowspan="2" class="footable-sortable" style="display: table-cell;">Credit Union</th>
                                            <th rowspan="2" class="footable-sortable" style="display: table-cell;">Branch</th>
                                            <th rowspan="2" class="footable-sortable" style="display: table-cell;">date of loan</th>
                                            <th rowspan="2" class="footable-sortable" style="display: table-cell;">loan amount</th>
                                            <th rowspan="2" class="footable-sortable" style="display: table-cell;">Purpose</th>
                                            <th rowspan="2" class="footable-sortable" style="display: table-cell;">Balance</th>
                                            <th style="border-left: solid #EAEFF0 1px; border-right: solid #EAEFF0 1px; border-bottom: solid #EAEFF0 1px;" colspan="2" class="text-center" translate="views.DELINQUENCY">Delinquency situation</th>
                                            <th rowspan="2" class="footable-sortable" style="display: table-cell;">collateral security</th>
                                        </tr>
                                        <tr class="footable-header table-inverse">
                                            <th class="footable-sortable text-center" style="display: table-cell; border-left: solid #EAEFF0 1px; border-right: solid #EAEFF0 1px;">days</th>
                                            <th class="footable-sortable text-center" style="display: table-cell; border-left: solid #EAEFF0 1px; border-right: solid #EAEFF0 1px;">amount</th>
                                        </tr>


                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let s of filterSits">
                                            <td class="footable-first-visible" style="display: table-cell">{{s.client != null ? s.client.numcli : ""}}</td>
                                            <td style="display: table-cell;">{{s.client != null ? s.client.name : ""}}</td>
                                            <td style="display: table-cell;">{{s.sitLoan.bqId}}</td>
                                            <td style="display: table-cell;">{{s.sitLoan.agccpt}}</td>
                                            <td style="display: table-cell;">{{s.sitLoan.datacc | date}}</td>
                                            <td style="display: table-cell;">{{s.sitLoan.mtpret | number}}</td>
                                            <td style="display: table-cell;">{{s.loan != null ? s.loan.motifdem: ""}}</td>
                                            <td style="display: table-cell;">{{s.sitLoan.montrest | number}}</td>
                                            <td style="display: table-cell;">{{s.sitLoan.retard | number}}</td>
                                            <td style="display: table-cell;">{{s.sitLoan.valretard | number}}</td>
                                            <td style="display: table-cell;">{{s.garantie != null ? s.garantie.catpro: "Unavailable"}}</td>
                                        </tr>

                                    </tbody>
                                    <tfoot>
                                        <tr class="footable-paging">
                                            <td colspan="5">
                                                <ul class="pagination">
                                                    
                                                    <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==page}" data-page="1"><a (click)="setPage(i, $event)" class="footable-page-link" href="#">{{i}}</a></li>
                                                  
                                                </ul>
                                                <div class="divider"></div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>

                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>