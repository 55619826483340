import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/DataService';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { User, SearchRequest, PageChapter, PermanentlyBlockedResponse, APIResponse, FinalSearchResult, LoanSearchResponse, SearchReportRequest } from 'src/app/shared/models';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/printer.service';

declare var showLoader;

@Component({
  selector: 'app-search-borrowers',
  templateUrl: './search-borrowers.component.html',
  styleUrls: ['./search-borrowers.component.css']
})
export class SearchBorrowersComponent implements OnInit {

  fsr: FinalSearchResult;
  lsrs: Array<LoanSearchResponse>;
  filterLsrs: Array<LoanSearchResponse>;
  params: Array<String>;
  usr: User;
  search: SearchRequest;
  seconds: number;
  searchRes: String;
  isVisible: boolean;
  canPrint: boolean;
  base64File: string;
  srr: SearchReportRequest;

  constructor(private ds: DataService, private route: ActivatedRoute, private auth: AuthService, private loc: Location, private alert: ToastrService, private router: Router) {
    this.initView();
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {

  }

  initView() {
    this.fsr = {} as FinalSearchResult;
    this.fsr.loans = new Array<LoanSearchResponse>();
    this.lsrs = new Array<LoanSearchResponse>();
    this.filterLsrs = new Array<LoanSearchResponse>();
    this.params = new Array<String>();
    this.usr = {} as User;
    this.search = {} as SearchRequest;
    this.srr = {} as SearchReportRequest;
    this.search.query = new Array<string>();
    this.usr = this.auth.getUser();
    this.isVisible = false;
    this.canPrint = false;
    this.base64File = '';
    this.threatRoute();
  }

  beginSearch() {
    this.search.bank = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.bqId : '';
    this.search.branch = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.agccpt : '';
    this.search.isBilling = this.usr.roleId == 3 || this.usr.roleId == 2 ? true : false;
    this.search.username = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.username : '';
    this.search.name = this.usr.roleId == 3 || this.usr.roleId == 2 ? this.usr.name : '';

    // console.log(this.srt);
    showLoader(true);
    this.ds.post(`/situations/search`, this.search).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.fsr = res.body.data;
        // console.log(res.body.data);
        // let receivedLoans = res.body.data.loans.forEach(loan => {
        //   loan['Datacc'] = loan['datacc'];
        //   loan['MTPRET'] = loan['mtpret'];
        //   loan['MOTIFDEM'] = loan['motifdem'];
        //   loan['RETARD'] = loan['retard'];
        // });

        this.seconds = (this.fsr.exec_time / 1000) % 60;
        const loans: LoanSearchResponse[] = this.fsr.loans;//this.trimLoanSearchResponses(this.fsr.loans);
        //console.log(this.seconds);
        //this.lsrs = this.fsr.loans;
        this.lsrs = loans.filter(
          (thing, i, arr) => arr.findIndex(t => t.numdos === thing.numdos && t.client.bank === thing.client.bank && t.client.branch === thing.client.branch && t.client.name === thing.client.name && t.client.cni === thing.client.cni) === i
        );
        this.filterLsrs = loans;
        if (this.fsr.loans.length == 0) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
        if (this.fsr.loans.length != 0) {
          this.canPrint = true;
        } else {
          this.canPrint = false;
        }
      } else {
        this.alert.error('ERROR', res.body.message);
        showLoader(false);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  threatRoute() {
    this.route.queryParams
      .subscribe(params => {
        let res = params.query;
        //console.log(params.query);
        this.searchRes = params.query;
        let qr: Array<String>;
        qr = res.split(",");
        qr.forEach((x) => {
          this.search.query.push(x.trim());

        });

        //console.log(this.search);
        this.beginSearch();
      });
  }

  onBeginSearch(value: string) {
    //console.log(value);
    let str = value.replace(" ", "&");
    //console.log(str);
    this.search.query = new Array<string>();
    if (value != null) {
      this.router.navigate(['/search/result'], { queryParams: { query: value } });
    }
  }

  backClicked() {
    this.loc.back();
  }

  /**
   * this function would trim the loan response array to only the properties defined in its interface
   * @param loanArray - array of loans returned from API
   * @returns - trimmed array
   */
  trimLoanSearchResponses(loanArray: LoanSearchResponse[]): LoanSearchResponse[] {
    const loanSearchResponse: LoanSearchResponse = {
      client: null,
      color: null,
      datacc: null,
      garantee: null,
      montrest: null,
      motifdem: null,
      mtpret: null,
      numdos: null,
      retard: null,
      valretard: null
    };
    const trimmedLoans = loanArray.map((loan: LoanSearchResponse) => {
      const trimmedObject = {} as LoanSearchResponse;
      const loanKeys = Object.keys(loan);
      for (const key of loanKeys) {
        if (key in loanSearchResponse) {
          trimmedObject[key] = loan[key];
        }
      }
      return trimmedObject;
    });
    return trimmedLoans;
  }


  printAdminSearchReport(): void {
    showLoader(true);
    this.srr.query = this.search.query;
    this.srr.lsrs = this.lsrs;
    this.ds.post(`/situations/reports/search/result/admin`, this.srr).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      //console.log(err);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  printClientSearchReport(): void {
    showLoader(true);
    this.srr.query = this.search.query;
    this.srr.lsrs = this.lsrs;
    this.ds.post(`/situations/reports/search/result`, this.srr).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

}
