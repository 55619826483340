<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Loan Portfolio Balances</h5>
                    <span>This page list existing Loan Portfolio Balance by Credit Union</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-8">

                        <div class="row">

                            <div class=" col-sm-4" *ngIf="usr.roleId==1 || usr.roleId==6">
                                <div class="form-group">
                                    <label class="col-form-label">Credit Union</label>

                                    <select [(ngModel)]="srt.bank" name="bank" class="form-control">
        
                                                <option value="">&nbsp;</option>
                                                <option *ngFor="let b of banks" value="{{b.bank.code}}">{{b.bank.name}}</option>
                                                </select>

                                </div>
                            </div>

                            <div class="col-sm-4" *ngIf="usr.roleId !=2 && usr.roleId==3">
                                <div class="form-group">
                                    <label class="col-form-label">Credit Union</label>

                                    <select disabled [(ngModel)]="srt.bank" name="bank" class="form-control">
        
                                                <option value="">&nbsp;</option>
                                                <option *ngFor="let b of banks" value="{{b.bank.code}}">{{b.bank.name}}</option>
                                                </select>

                                </div>
                            </div>

                            <div class=" col-sm-4" *ngIf="usr.roleId==2">
                                <div class="form-group">
                                    <label class="col-form-label">Credit Union</label>

                                    <select [(ngModel)]="srt.bank" name="bank" class="form-control">
        
                                                <option value="">&nbsp;</option>
                                                <option *ngFor="let b of fbanks" value="{{b.bank.code}}">{{b.bank.name}}</option>
                                                </select>

                                </div>
                            </div>

                            <!-- <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="col-form-label">Branch</label>
                                    <select [(ngModel)]="srt.branch" name="branch" class="form-control" [disabled]="!showBranch">
                                                <option value="">&nbsp;</option>
                                                <option *ngFor="let s of sbranchs" value="{{s.code}}">{{s.name}}</option>
                                                </select>

                                </div>
                            </div> -->
                            <div class="col-sm-8" *ngIf="usr.roleId == 1 || usr.roleId==2 || usr.roleId==6">
                                <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 m-t-35" (click)="onLoadData(0)" [disabled]="!srt.bank">Fetch Balances</button>

                                <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="printLoanBalanceReport()" [disabled]="!srt.bank"><i class="fa fa-print"></i>Print</button>
                            </div>
                            <div class="col-sm-8" *ngIf="usr.roleId !=2 && usr.roleId==3">
                                <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="printLoanBalanceReport()" [disabled]="!srt.bank"><i class="fa fa-print"></i>Print</button>
                            </div>
                        </div>

                        <div class="card m-t-10">
                            <div class="card-header">
                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>Loan Portfolio Balances</h5>
                                    </div>
                                    <div class="col-lg-5">
                                        <!-- <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">
                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Branch</th>
                                                <th class="footable-sortable text-right" style="display: table-cell;">Total Loan Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let lr of lbts">
                                                <td class="footable-first-visible" style="display: table-cell;">{{lr.branch}}</td>
                                                <td style="display: table-cell;" class="text-right">{{lr.balance | number}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td class="f-w-700"> TOTAL AMOUNT</td>
                                                <td class="text-right f-w-700">{{amntTot | number}}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>