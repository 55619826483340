import { Component, OnInit } from '@angular/core';
import { Chapter, APIResponse } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Component({
  selector: 'app-chapter-new',
  templateUrl: './chapter-new.component.html',
  styleUrls: ['./chapter-new.component.css']
})
export class ChapterNewComponent implements OnInit {

  chap: Chapter;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService) { 
    this.initView();
  }

  ngOnInit(): void {
  }

  onCreate() {
    showLoader(true);
    this.ds.post('/chapters/new', this.chap).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
       showLoader(false);
        this.alert.success('SUCCESS', 'CHAPTER SAVE SUCCESSFULLY');
        this.initView();
      } else {
        //console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
     this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
     //console.log(err);
    });
  }

  initView() {
    this.chap = {} as Chapter;
  }
  backClicked() {
    this.loc.back();
  }
}
