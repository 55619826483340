import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth.service';
import { DataService } from 'src/app/shared/DataService';
import { Agency, APIResponse, BankResponseType, MonthSituation, PageChapter, Payment, PayDetailResponse, SituationRequestType, User } from 'src/app/shared/models';
import { Printer } from 'src/app/shared/printer.service';
import { HttpResponse } from '@angular/common/http';

declare var showLoader;

@Component({
  selector: 'app-month-situation',
  templateUrl: './month-situation.component.html',
  styleUrls: ['./month-situation.component.css']
})
export class MonthSituationComponent implements OnInit {

  page: number;
  pageCredit: PageChapter;
  pages: Array<number>;
  banks: Array<BankResponseType>;
  fbanks: Array<BankResponseType>;
  branchs: Array<Agency>;
  sbranchs: Array<Agency>;
  showBranch: boolean;
  srt: MonthSituation;
  lrts: Array<PayDetailResponse>;
  filterLrts: Array<PayDetailResponse>;
  base64File: string;
  usr: User;
  currentSelectedPage: number = 0;
  pag: number;
  pagnum: number;
  dt: Date;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService, private auth: AuthService) {
    this.initView();
    this.usr = this.auth.getUser();
  }

  ngOnInit(): void {
  }

  initView() {
    this.page = 0;
    this.pageCredit = {} as PageChapter;
    this.lrts = new Array<PayDetailResponse>();
    this.filterLrts = new Array<PayDetailResponse>();
    this.banks = new Array<BankResponseType>();
    this.fbanks = new Array<BankResponseType>();
    this.branchs = new Array<Agency>();
    this.sbranchs = new Array<Agency>();
    this.showBranch = false;
    this.srt = {} as MonthSituation;
    this.base64File = '';
    this.pages =[];
    this.pagnum = 20;
    this.getbanks();
  }



  onLoadData(page: number) {
    this.filterLrts = new Array<PayDetailResponse>();
    this.srt.dat = new Date(this.dt);
    //console.log(this.srt);
    showLoader(true);
    this.ds.post(`/payments/situations/${this.pagnum}?page=${page}`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.pageCredit = res.body.data;
        //console.log(this.pageCredit);
        this.pages = Array(this.pageCredit.totalPages).fill(0).map((x,i)=>i);
        this.lrts = this.pageCredit.content;
        this.lrts.sort((a,b)=>a.BANK.localeCompare(b.BANK));

        this.filterLrts = this.lrts;
        //console.log(this.filterLrts);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
       // console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      //console.log(err);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  setPage(i) {
    this.onLoadData(i);
  }

  getPaginationWithIndex(index: number, pag: any) {
    this.setPage(index);
  }

  active(index: number) {
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  onChangeNumber(pag: number){
    try {
      this.pagnum = pag;
      this.onLoadData(0);
    } catch (error) {

    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.pageCredit.totalPages - 1) {
      this.setPage(++this.currentSelectedPage);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.setPage(--this.currentSelectedPage);
    }
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.banks = res.body.data;
        this.fbanks = this.banks.filter(x => x.chapter?.code.toLowerCase() === this.usr.codchap.toLowerCase());
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }


  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterLrts = this.lrts.filter((cre) => {
        return cre.BANK.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        cre.CHARGE.toString().indexOf(query.toLowerCase()) !== -1 ||
        cre.UNIT_CHARGE.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1

      });
    } else {
      this.filterLrts = this.lrts;
    }
  }

  printPaymentReportList() {
    showLoader(true);
    this.ds.post(`/payments/situations/reports/list`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  printPaymentReport(pId: number) {
    showLoader(true);
    this.ds.get(`/licences/payment/${pId}`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  backClicked() {
    this.loc.back();
  }

}
