import { HttpEventType, HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/shared/DataService';
import { ToastrService } from 'ngx-toastr';
import { APIResponse } from 'src/app/shared/models';

declare var showLoader;

@Component({
  selector: 'app-upload-synchronisation',
  templateUrl: './upload-synchronisation.component.html',
  styleUrls: ['./upload-synchronisation.component.css']
})
export class UploadSynchronisationComponent implements OnInit {

  @ViewChild("takeInput", {static: false}) inputVar: ElementRef;

  selectedFile: File = null;

  constructor(private ds: DataService, private alert: ToastrService) { }

  ngOnInit(): void {
  }

  onFileChange(event: any): void {
    this.selectedFile = event.target.files[0];
  }

  uploadFile(): void {
    if (this.selectedFile) {
      showLoader(true);
      this.ds.uploadFile(this.selectedFile).then(
        (res: APIResponse) => {
          console.log(res)
          if (res.status === 200) {
            showLoader(false);
            //console.log(res.body.data);
            this.alert.success('SUCCESS', 'YOUR MERCHANT CONFIGURATION HAS BEEN UPDATED SUCCESSFULLY');
            this.inputVar.nativeElement.value = "";
          } else {
            showLoader(false);
            this.alert.error('UNEXPECTED UPLOAD ERROR', res.data);
            //console.log(res.body.message);
          }
        }, (err) => {
          //console.log(err);
          showLoader(false);
          this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
        }
      );
    }
  }

  

}
