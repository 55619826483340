import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Agency, AlertMessage, APIResponse, BankResponseType } from 'src/app/shared/models';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/shared/DataService';
import { ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

declare var showLoader;

@Component({
  selector: 'app-alert-message-edit',
  templateUrl: './alert-message-edit.component.html',
  styleUrls: ['./alert-message-edit.component.css']
})
export class AlertMessageEditComponent implements OnInit {

  alrt: AlertMessage;
  cId: number;

  constructor(private ds: DataService, private route: ActivatedRoute, private loc: Location, private alert: ToastrService ) { 
    this.initView();
    
  }

  ngOnInit(): void {
  }

  getChapterById(): void {
    showLoader(true);
    this.ds.get(`/alerts/${this.cId}`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.alrt = res.body.data;
       // console.log(this.chap);
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      //showLoader(false); 
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }

  onUpdate() {
    showLoader(true);
    this.ds.put('/alerts/update/'+this.cId, this.alrt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
       showLoader(false);
        this.alert.success('SUCCESS', 'ALERT MESSAGE SAVE SUCCESSFULLY');
        //this.initView();
      } else {
       // console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
     this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
     //console.log(err);
    });
  }

  initView() {
    this.alrt = {} as AlertMessage;
    this.route.params.subscribe((param) => {
      this.cId = param.id;
      this.getChapterById();
    });

  }

  backClicked() {
    this.loc.back();
  }

}
