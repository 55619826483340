import { Component, OnInit } from '@angular/core';
import { User, MegaMenu } from '../models';
import { AuthService } from '../auth.service';
import { MenuServiceEn } from '../menu.en.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  items: Array<MegaMenu>;
  user: User;
  hpCode: string;
  profilImg: Array<string>;

  constructor(private auth: AuthService, private _men: MenuServiceEn) {
    this.initView();
  }

  ngOnInit() {

  }
  initView() {
    this.items = new Array<MegaMenu>();
    try {
      this.user = this.auth.getUser();
      this.items = this._men.getMenuItems();

      if (this.user.roleId !== 1 && this.user.roleId !== 2) {
        if (!this.user.isLicenceActive) {
          this.items = [
                          {
                              "value": "C00",
                              "text": "Account",
                              "icon": "feather icon-user",
                              "children": [
                                  {
                                      "value": "C001",
                                      "path": "/account/change-pass",
                                      "text": "Change Password"
                                  },
                                  {
                                      "value": "C002",
                                      "path": "/account/payment",
                                      "text": "Payment"
                                  }
                              ]
                          }
                      ]
        }
      }

    } catch (error) {
      this.user = {} as User;
    }
  }

  /**
   * Check if user menu codes contains parsed code
   * @param code
   * @returns true | false
   */
  hasCode(code: string): boolean {
    if (!this.user.roles) {
      return false;
    }

    return this.user.roles.indexOf(code) !== -1 ? true : false;
  }

}
