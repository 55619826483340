import { HttpEventType, HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UploadFileService } from 'src/app/shared/upload-file.service';

declare var showLoader;

@Component({
  selector: 'app-import-contact',
  templateUrl: './import-contact.component.html',
  styleUrls: ['./import-contact.component.css']
})
export class ImportContactComponent implements OnInit {
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';

  fileInfos: Observable<any>;

  constructor(private uploadService: UploadFileService, private alert: ToastrService) { }

  ngOnInit() {
    //this.fileInfos = this.uploadService.getFiles();
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    //console.log(this.selectedFiles);
  }

  upload() {
    this.progress = 0;
    showLoader(true);
    this.currentFile = this.selectedFiles.item(0);
    this.uploadService.upload(this.currentFile).subscribe(
      event => {
        //console.log(event);
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpHeaderResponse) {
         // console.log(event);
          if (event.status === 200) {
            this.alert.success("CONTACT IMPORTATION WAS SUCCESSFUL");
           // console.log(event.body.message);
            showLoader(false);
            //this.reloadFunc();
          } else {
            showLoader(false);
            this.alert.warning("CONTACT IMPORTATION FAILED");
            //console.log(event.body.message);
          }
          this.message = event.statusText;
         // console.log(this.message);
         // this.fileInfos = this.uploadService.getFiles();
        }
      },
      err => {
        showLoader(false);
        //console.log(err);
        this.progress = 0;
        this.message = 'Could not upload the file!';
        this.currentFile = undefined;
      });
  
    this.selectedFiles = undefined;
  }

}
