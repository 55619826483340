<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Alert Message</h5>
                    <span>This page list camccul alert message</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-12">
                        <!-- <button class="btn btn-mat waves-effect waves-light btn-primary" (click)="printUserReport()"><i class="fa fa-print"></i>Print</button> -->
                        <button routerLink="/configuration/alerts/new" class="btn btn-mat waves-effect waves-light btn-info m-l-10" *ngIf="ro"><i class="fa fa-plus"></i>New Alert Message</button>
                        <div class="card m-t-10">
                            <div class="card-header">

                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>List of Message</h5>
                                        &nbsp;&nbsp;&nbsp;
                                        <h5>Total: {{pageUser.totalElements}}</h5>
                                    </div>
                                    <div class="col-lg-5">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <select #pag name="pag" class="form-control searchForm" (change)="onChangeNumber(pag.value)">
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="500">500</option>
                                                    <option value="0">ALL</option>
                                                </select>
                                            </div>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">

                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Created Date<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Title<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Message<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Status<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Action<span class="fooicon fooicon-sort" *ngIf="ro"></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let alrt of filterAlerts">
                                                <td class="footable-first-visible" style="display: table-cell;">{{alrt.createdAt | date}}</td>
                                                <td style="display: table-cell;">{{alrt.title}}</td>
                                                <td style="display: table-cell;">{{alrt.msg}}</td>
                                                <td style="display: table-cell;">
                                                    <span *ngIf="alrt.state" class="pcoded-badge label label-success">Activated</span>
                                                    <span *ngIf="!alrt.state" class="pcoded-badge label label-danger">Desactivated</span>
                                                </td>

                                                <td style="display: table-cell;" *ngIf="ro">
                                                    <a routerLink="/configuration/alerts/edit/{{alrt.id}}" class="tabledit-edit-button btn btn-primary waves-effect waves-light"><span class="icofont icofont-ui-edit"></span></a>
                                                    <button type="button" class="tabledit-info-button btn btn-info waves-effect waves-light m-l-10" data-toggle="modal" data-target="#large-Modal" (click)="msg =alrt.msg"><span class="fa fa-envelope"></span></button>
                                                    <button type="button" (click)="onDelete(alrt.id)" class="tabledit-delete-button btn btn-danger waves-effect waves-light m-l-10"><span class="icofont icofont-ui-delete"></span></button>

                                                    <ui-switch class="switch m-l-10" size="small" [(ngModel)]="alrt.state" (change)="changeStatus(alrt)"></ui-switch>

                                                    <!-- <label class="switch m-l-10" *ngIf="usr.roleId != 1">
                                                        <input type="checkbox" checked (change)="changeStatus(usr)">
                                                        <span class="slider round"></span>
                                                      </label> -->
                                                </td>

                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr class="footable-paging">
                                                <td colspan="5">
                                                    <ul class="pagination ">
                                                        <li class="footable-page-nav" data-page="prev"><a class="footable-page-link" href="javascript:void(0)" (click)="previousClick()">Previous</a></li>
                                                        <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==currentSelectedPage}" data-page="1"><a (click)="getPaginationWithIndex(i, pagnum)" class="footable-page-link" href="javascript:void(0)">{{i}}</a></li>
                                                        <li class="footable-page-nav" data-page="next"><a class="footable-page-link" href="javascript:void(0)" (click)="nextClick()">Next</a></li>

                                                    </ul>
                                                    <div class="divider"></div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="large-Modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Select Credit Unions or Branches</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
            </div>
            <div class="modal-body" style="height: 28rem !important; overflow-y: scroll;">
                <div class="row">
                    <div class="col-md-6">
                        <h5 class="text-cr">List of Credits Unions</h5>
                        <div class="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th class="text-center m-l-5"><input type="checkbox" [value]="banks" (change)="onSelectAllc($event)"></th>
                                        <th>Code</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let b of banks">
                                        <td class="text-center"><input type="checkbox" [checked]="b.selected" (change)="onSelectedc($event, b)"></td>
                                        <td>{{b.bank.code}}</td>
                                        <td>{{b.bank.name}}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h5 class="text-cr">List of Branches</h5>

                        <div class="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th class="text-center m-l-5"><input type="checkbox" [value]="branchs" (change)="onSelectAllb($event)"></th>
                                        <th>Code</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let bh of branchs">
                                        <td class="text-center"><input type="checkbox" [checked]="bh.selected" (change)="onSelectedb($event, bh)"></td>
                                        <td>{{bh.code}}</td>
                                        <td>{{bh.name}}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary waves-effect waves-light " (click)="sendSmsNew()">Send SMS</button>
            </div>
        </div>
    </div>
</div>