<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Users Roles</h5>
                    <span>This page is made to configure roles</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row">
                    <ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand" let-onCheckedChange="onCheckedChange">
                        <div class="form-inline row-item">
                            <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" [class.icon-arrow-right5]="item.collapsed" [class.icon-arrow-down5]="!item.collapsed"></i>
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()" [disabled]="item.disabled" [indeterminate]="item.indeterminate" />
                                <label class="custom-control-label" (click)="item.checked = !item.checked; onCheckedChange()">
                            {{item.text}}
                          </label>
                            </div>
                        </div>
                    </ng-template>
                </div>

                <div class="row">
                    <div class="col-sm-2"></div>

                    <div class="col-sm-7">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-end">
                                    <div class="col-lg-12">
                                        <h5>List / Add / Edit Roles</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">
                                <form #rolForm="ngForm">
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <input type="text" [(ngModel)]="role.name" name="name" class="form-control" placeholder="Role Name" required>
                                            </div>
                                            <div class="form-group">
                                                <select [(ngModel)]="role.privilege" name="privilege" class="form-control" required placeholder="Select a Privilege">
                                                        <option>Select a Privilege</option>
                                                        <option value="R">Read Only</option>
                                                        <option value="RW">Read / Write</option>
                                                        </select>
                                            </div>


                                            <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">

                                                <tbody>
                                                    <tr *ngFor="let rl of listRoles">
                                                        <td style="display: table-cell;" (click)="onRoleClick(rl.id)"><a href="javascript:void(0)" style="cursor: pointer;">{{rl.name}}</a></td>
                                                        <!-- <td>
                                                        <button (click)="onRoleClick(rl.id)" class="btn btn-primary waves-effect waves-light"><span class="icofont icofont-ui-edit"></span></button>
                                                    </td> -->

                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>
                                        <div class="col-sm-1"></div>
                                        <div class="col-sm-6">
                                            <ngx-treeview [items]="items" [itemTemplate]="itemTemplate" (selectedChange)="onSelectedChange($event)">
                                            </ngx-treeview>
                                        </div>
                                    </div>

                                    <button *ngIf="!canUpd" class="btn btn-mat waves-effect waves-light btn-inverse float-right" [disabled]="!rolForm.form.valid" (click)="onCreateRole()">Save Changes</button>

                                    <button *ngIf="canUpd" class="btn btn-mat waves-effect waves-light btn-primary float-right" [disabled]="!rolForm.form.valid" (click)="onUpdate()">Save Changes</button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-1"></div>
                </div>

            </div>
        </div>
    </div>
</div>