import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/DataService';
import { Location } from '@angular/common';
import { AppSetting, APIResponse } from 'src/app/shared/models';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Component({
  selector: 'app-general-setup',
  templateUrl: './general-setup.component.html',
  styleUrls: ['./general-setup.component.css']
})
export class GeneralSetupComponent implements OnInit {

  sttg: AppSetting;
  sttgs: Array<AppSetting>;
  canUpd: boolean;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService) {
    this.initView();
   }

  ngOnInit(): void {
    
  }

  initView() {
    this.sttg = {} as AppSetting;
    this.sttgs = Array<AppSetting>();
    this.getSettings();
  }

  getSettings(): void {
    showLoader(true);
    this.ds.get(`/settings/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        //console.log(res.body.data);
        this.sttgs = res.body.data;
        if(this.sttgs.length !== 0) {
          this.sttg = this.sttgs[0];
          this.canUpd = false;
          //console.log(this.sttg);
        } else {
          this.canUpd = true;
        }
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }

  onCreate() {
    //console.log(this.sttg);
    showLoader(true);
    this.ds.post('/settings/new', this.sttg).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
       showLoader(false);
        this.alert.success('SUCCESS', 'SETTING SAVE SUCCESSFULLY');
        this.initView();
      } else {
       // console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
     this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
     //console.log(err);
    });
  }

  onUpdate() {
    showLoader(true);
    this.sttg.mifiFeeRate = this.sttg.mifiFeeRate / 100
    this.ds.put('/settings/update/'+this.sttg.id, this.sttg).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
       showLoader(false);
        this.alert.success('SUCCESS', 'SETTING SAVE SUCCESSFULLY');
        this.initView();
      } else {
        //console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
     this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
     //console.log(err);
    });
  }


  backClicked() {
    this.loc.back();
  }

}
