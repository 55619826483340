import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/shared/DataService';
import { Location } from '@angular/common';
import { Bank, Chapter, APIResponse } from 'src/app/shared/models';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Component({
  selector: 'app-credit-edit',
  templateUrl: './credit-edit.component.html',
  styleUrls: ['./credit-edit.component.css']
})
export class CreditEditComponent implements OnInit {

  @ViewChild('chapter', { static: true }) chapter: ElementRef;

  cre: Bank;
  cId: number;
  chaps: Array<Chapter>;

  constructor(private ds: DataService, private route: ActivatedRoute, private loc: Location, private router: Router, private alert: ToastrService) {
    this.initView();
    
  }

  ngOnInit(): void {
  }


  getChapters(): void {
    showLoader(true);
    this.ds.get(`/chapters/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.chaps = res.body.data;
        this.chaps.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getBankById(): void {
    showLoader(true);
    this.ds.get(`/banks/${this.cId}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.cre = res.body.data;
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onUpdate() {
    // showLoader(true);
    this.ds.put('/banks/update/' + this.cId, this.cre).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.alert.success('SUCCESS', 'CREDIT UNION SAVE SUCCESSFULLY');
        this.router.navigate(["/management/credit-union"]);
      } else {
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
      //console.log(err);
    });
  }

  initView() {
    this.cre = {} as Bank;
    this.chaps = new Array<Chapter>();
    this.getChapters();
    this.route.params.subscribe((param) => {
      this.cId = param.id;
      this.getBankById();
    });
  }

  onChange() {
    //console.log(this.chapter);
    //this.cre.chapterId = value;
  }

  backClicked() {
    this.loc.back();
  }

}
