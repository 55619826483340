import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { User, UserRole, APIResponse, BankResponseType, Chapter, Agency } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Md5 } from 'md5-typescript';
import { AuthService } from 'src/app/shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.css']
})
export class UserNewComponent implements OnInit {

  usr: User;
  pwd: string;
  roles: Array<UserRole>;
  banks: Array<BankResponseType>;
  chaps: Array<Chapter>;
  branchs: Array<Agency>;
  selectbanks: Array<BankResponseType>;
  selectbranchs: Array<Agency>;
  showChap: boolean;
  showCre: boolean;
  showBranch: boolean;
  currentUser: User;


  constructor(private ds: DataService, private loc: Location, private router: Router, private alert: ToastrService, private auth: AuthService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  onCreate() {
    this.usr.password = Md5.init(this.pwd);
    showLoader(true);
    this.ds.post('/users/new', this.usr).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.alert.success('SUCCESS', 'USER SAVE SUCCESSFULLY');
        this.initView();
      } else {
        showLoader(false);
        this.router.navigate(["/management/users"]);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
      //console.log(err);
    });
  }

  getRoles(): void {
    showLoader(true);
    this.ds.get(`/roles/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.roles = res.body.data;
        if (this.currentUser.roleId === 2) {
          this.roles = this.roles.filter(role => role.id === 3 || role.id === 4);
        }
        // console.log(this.roles);
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.banks = res.body.data;
        if (this.currentUser.roleId === 2) {
          this.banks = this.banks.filter(bank => bank.chapter?.code === this.currentUser.codchap);
        }
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getChapters(): void {
    showLoader(true);
    this.ds.get(`/chapters/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.chaps = res.body.data;
        if (this.currentUser.roleId === 2) {
          this.chaps = this.chaps.filter(chapter => chapter.code === this.currentUser.codchap);
        }
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onChangeRol(val: number) {
    if (Number(val) === Number(2)) {
      this.showChap = true;
      this.showCre = false;
      this.showBranch = false;
    } else if (Number(val) === Number(3) || Number(val) === Number(4)) {
      this.showChap = true;
      this.showCre = true;
      this.showBranch = true;
    } else {
      this.showChap = false;
      this.showCre = false;
      this.showBranch = false;
    }
  }

  onChangeChap(val: string) {
    if (Number(this.usr.roleId) === Number(2)) {
      this.selectbanks = this.banks;
    } else {
      this.showCre = true;
      this.selectbanks = this.banks.filter((x) => {
        return val.toLowerCase() === x.chapter.code.toLowerCase();
      });
    }

  }

  /***
   * This function sets the list of branches to display when a chapter is selected
   * @param val The chapter code
   */
  onChangeChapter(val: string) {
    if (this.usr.roleId == 2) {
      this.selectbanks = this.banks;
    } else {
      this.showCre = true;
      this.selectbanks = this.banks.filter(bank => {
        if (bank.chapter) {
          return bank.chapter.code == val;
        }
      });
    }
  }

  /***
   * This function sets the list of branches to display when a bank/MFI is selected.
   * @param val The bank code (bqid) of the bank/MFI.
   */
  onChangeBank(val: string) {
    this.showBranch = true;
    const userBank = this.banks.find(bank => bank.bank.code === val);
    this.selectbranchs = this.branchs.filter(branch => branch.bqId == userBank.bank.id);
  }

  getBranches(): void {
    showLoader(true);
    this.ds.get(`/branches/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.branchs = res.body.data;
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  initView() {
    this.usr = {} as User;
    this.currentUser = {} as User;
    this.pwd = '';
    this.usr.active = true;
    this.roles = new Array<UserRole>();
    this.banks = new Array<BankResponseType>();
    this.chaps = new Array<Chapter>();
    this.branchs = new Array<Agency>();
    this.selectbanks = new Array<BankResponseType>();
    this.selectbranchs = new Array<Agency>();
    this.showCre = false;
    this.showChap = false;
    this.showBranch = false;
    this.currentUser = this.auth.getUser();
    this.getChapters();
    this.getbanks();
    this.getBranches();
    this.getRoles();
  }

  backClicked() {
    this.loc.back();
  }

}
