import { Injectable } from '@angular/core';
import { MegaMenu } from './models';
import { TreeviewItem } from 'ngx-treeview';

@Injectable()
export class MenuServiceEn {
  public seperator: string;
  public secuVal: string;
  public agci: string;
  public index: number[];
  constructor() {
    this.seperator = 'Þ';
    this.secuVal = 'ITAWB';
    this.agci = '%';
    this.index = [1, 6, 12, 15, 4, 7, 14, 9, 16, 11, 5, 2, 13, 10, 3, 8];
  }

  public getMenuItems(): Array<MegaMenu> {
    return [
      // {
      //   value: 'A00', text: 'Dashboard', icon: 'fa fa-dashboard', children: [
      //     {
      //       value: 'A01', text: 'Dashboard', children: [
      //         { value: 'A011', path: 'dashboard', text: 'Dashboard' }
      //       ]
      //     }
      //   ]
      // },
      {
        value: 'C00', text: 'Account', icon: 'feather icon-user', children: [
          { value: 'C001', path: '/account/change-pass', text: 'Change Password' },
          { value: 'C002', path: '/account/payment', text: 'Payment' },
          { value: 'C003', path: '/account/invoice', text: 'Billing Statement' },
          // { value: 'C004', path: '/account/retro-commission', text: 'Retro Commission' },
        ]
      },
      {
        value: 'D00', text: 'Management', icon: 'feather icon-layers', children: [
          { value: 'D001', path: '/management/chapters', text: 'Chapters' },
          { value: 'D002', path: '/management/credit-union', text: 'Credit Unions' },
          { value: 'D003', path: '/management/branches', text: 'Branches' },
          { value: 'D004', path: '/management/users/role', text: 'Users Role' },
          { value: 'D005', path: '/management/users', text: 'Users' },
          { value: 'D006', path: '/management/loan-analysis', text: 'Loan Analysis' },
          { value: 'D007', path: '/management/blacklist-history', text: 'Blacklist History' }
        ]
      },
      {
        value: 'E00', text: 'Reports', icon: 'feather icon-pie-chart', children: [
          { value: 'E001', path: '/reports/user-log', text: 'User Logs' },
          { value: 'E002', path: '/reports/loan-situation', text: 'Loan Ageing Balance' },
          { value: 'E003', path: '/reports/loan-portfolio', text: 'Loan Portfolio' },
          { value: 'E004', path: '/reports/loan-portfolio/balances', text: 'Loan Portfolio Balances' },
          // { value: 'E005', path: '/reports/billing', text: 'Billing' },
          { value: 'E006', path: '/reports/billing-summary', text: 'Billing Summary' },
          { value: 'E007', path: '/reports/payment-history', text: 'Payment History' },
          { value: 'E008', path: '/reports/month-situation', text: 'Monthly Payment History' },
          //{ value: 'E009', path: '/reports/provision', text: 'Provision' },
        ]
      },
      {
        value: 'F00', text: 'Configurations', icon: 'feather icon-settings', children: [
          { value: 'F001', path: '/configuration/access-charges', text: 'Access Charges' },
          { value: 'F002', path: '/configuration/licences', text: 'Licences' },
          { value: 'F003', path: '/configuration/general-setup', text: 'Settings' },
          { value: 'F004', path: '/configuration/alerts', text: 'Alert Message' },
          { value: 'F005', path: '/configuration/upload', text: 'Import Contact' },
          { value: 'F006', path: '/configuration/merchant-config', text: 'Merchant Settings' },
          { value: 'F007', path: '/configuration/upload-sync', text: 'Upload Sync' },
        ]
      },
    ];
  }

  getMenuTreeItems(): Array<TreeviewItem> {
    const m1 = new TreeviewItem({
      value: 'A00', text: 'Dashboard'
    });
    const m2 = new TreeviewItem(
      {
        value: 'C00', text: 'Accounts', children: [
          { value: 'C001', text: 'Change Password' },
          { value: 'C002', text: 'Payment' },
          { value: 'C003', text: 'Billing Statement' },
          // { value: 'C004', text: 'Retro Commission' },
        ]
      }
    );
    const m3 = new TreeviewItem(
      {
        value: 'D00', text: 'Managements', children: [
          { value: 'D001', text: 'Chapters' },
          { value: 'D002', text: 'Credit Unions' },
          { value: 'D003', text: 'Branches' },
          { value: 'D004', text: 'Users Role' },
          { value: 'D005', text: 'Users' },
          { value: 'D006', text: 'Loan Analysis' },
          { value: 'D007', text: 'Blacklist History' },
        ]
      }
    );
    const m4 = new TreeviewItem(
      {
        value: 'E00', text: 'Reports', children: [
          { value: 'E001', text: 'User Logs' },
          { value: 'E002', text: 'Loan Ageing Balance' },
          { value: 'E003', text: 'Loan Portfolio' },
          { value: 'E004', text: 'Loan Portfolio Balances' },
          // { value: 'E005', text: 'Billing' },
          { value: 'E006', text: 'Billing summary' },
          { value: 'E007', text: 'Payment History' },
          { value: 'E008', text: 'Monthly Payment History' },
          //{ value: 'E009', text: 'Provision' },
        ]
      }
    );

    const m5 = new TreeviewItem(
      {
        value: 'F00', text: 'Configurations', children: [
          { value: 'F001', text: 'Access Charges' },
          // { value: 'F002', text: 'Licences' },
          { value: 'F003', text: 'Settings' },
          { value: 'F004', text: 'Alert Message' },
          { value: 'F005', text: 'Import Contact' },
          { value: 'F006', text: 'Merchant Settings' },
          { value: 'F007', text: 'Upload Sync' },
        ]
      }
    );

    return [m1, m2, m3, m4, m5];
  }
}
