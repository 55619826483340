<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Merchant Configuration</h5>
                    <span>This page allows admin to configure the merchant code for his micro finance </span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">

                    <div class="col-sm-12">
                        <div class="card m-t-10">
                            <div class="card-block">
                                <form #setForm="ngForm">

                                    <div class="tab-content card-block">

                                        <div class="tab-pane active show" id="home3" role="tabpanel">
                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Label</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="merchantConfig.label" name="label" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Logo</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="merchantConfig.logo" name="logo" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Code</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="merchantConfig.code" name="code" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Merchant Code</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="merchantConfig.merchantCode" name="merchantCode" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Merchant Account</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="merchantConfig.merchantAccount" name="merchantAccount" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Devise</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="merchantConfig.devise" name="devise" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Provider</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="merchantConfig.provider"  name="provider"  class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Deposit Available</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="merchantConfig.depositAvailable" name="depositAvailable" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Withdrawal Available</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="merchantConfig.withdrawalAvailable" name="email" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Mobile Payment Service Fees</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="feesConfig.service" name="fees" class="form-control" required>
                                                </div>
                                            </div>


                                        </div>

                                    </div>

                                    <button class="btn btn-mat waves-effect waves-light btn-inverse   float-right" (click)="onUpdate()">Update</button>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
