import { Component, OnInit } from '@angular/core';
import { Bank, APIResponse, Chapter } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Component({
  selector: 'app-credit-new',
  templateUrl: './credit-new.component.html',
  styleUrls: ['./credit-new.component.css']
})
export class CreditNewComponent implements OnInit {

  cre: Bank;
  chaps: Array<Chapter>;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  getChapters(): void {
    showLoader(true);
    this.ds.get(`/chapters/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.chaps = res.body.data;
        this.chaps.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onCreate() {
    showLoader(true);
    if (this.cre.type !== 'INTERNAL') {
      this.cre.chapterId = 11;
    }
    this.cre.feeAmount = this.cre.feeAmount / 100
    this.ds.post('/banks/new', this.cre).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.alert.success('SUCCESS', 'CREDIT UNION SAVE SUCCESSFULLY');
        this.initView();
      } else {
        //console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
      //console.log(err);
    });
  }

  initView() {
    this.cre = {} as Bank;
    this.cre.chapterId = null;
    this.chaps = new Array<Chapter>();
    this.getChapters();
    this.cre.feeAmount = 0;
    this.cre.loanPortfolioAmount = 0;
  }
  backClicked() {
    this.loc.back();
  }

}
