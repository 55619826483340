import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { User, BankResponseType, APIResponse, Payment, PaymentResponse, ChargesRequest, PaymentResponseToPrint, PaymentResponseToExcel, groupeBySite, PaymentResponseWithFees } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { AuthService } from 'src/app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import { Printer } from 'src/app/shared/printer.service';
import { ExportHelper } from 'src/app/shared/export.service';
import { element } from 'protractor';

declare var showLoader;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  pay: Payment;
  usr: User;
  banks: Array<BankResponseType>;
  fbanks: Array<BankResponseType>;
  fbank: BankResponseType;
  prs: PaymentResponseWithFees;
  uchrg: number = 0;
  base64File: string;
  totlBal: number;
  totFees: number;
  bkId: string;
  chargesRequest: ChargesRequest
  selectedPeriod = this.datePipe.transform(new Date(), 'yyyy-MM')
  pdfHelper : ExportHelper;
  pageOfItems?: Array<any>;
  sites: groupeBySite[] = []; 

  constructor(private ds: DataService, private auth: AuthService, private loc: Location, private alert: ToastrService,
    private datePipe: DatePipe) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.pay = {} as Payment;
    this.usr = {} as User;
    this.banks = new Array<BankResponseType>();
    this.fbanks = new Array<BankResponseType>();
    this.fbank = {} as BankResponseType;
    this.prs = {} as PaymentResponseWithFees;
    this.totlBal = 0;
    this.totFees = 0;
    this.usr = this.auth.getUser();
    this.base64File = '';
    this.bkId = '';
    this.getbanks();
    this.pdfHelper = new ExportHelper(new DatePipe('en'));
    this.chargesRequest = {} as ChargesRequest;
    if (this.usr.roleId == 3 || this.usr.roleId == 4) {
      this.bkId = this.usr.bqId;
      this.chargesRequest.bqId = this.bkId
      this.chargesRequest.monthYear = this.datePipe.transform(this.selectedPeriod, 'MM-yyyy')
      this.getCharges(this.chargesRequest);
    }
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.banks = res.body.data;

        this.fbanks = this.banks.filter((x) => {
          return x.chapter?.code.toLowerCase() === this.usr.codchap.toLowerCase();
        });
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getCharges(request: ChargesRequest): void {
    showLoader(true);
    this.ds.post(`/loans/payment-loans/list`, request).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.prs = res.body.data;
        this.totlBal = 0;
        this.totFees = 0;
        this.prs.paymentListResponse.forEach((x) => {
          this.totlBal += x.loan.mtpret;
          this.totFees += x.chargeAmount;
        });
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onCreditUnionChange(value: string) {
    if (value != null) {
      this.pay.amntPaid = null;
      this.pay.amntf = null;
      this.pay.discount = null;
      this.bkId = value;
      this.chargesRequest.bqId = this.bkId
      this.chargesRequest.monthYear = this.datePipe.transform(this.selectedPeriod, 'MM-yyyy')
      this.getCharges(this.chargesRequest);
    }

  }

  onPeriodChange(value: string) {
    if (value != null) {
      this.pay.amntPaid = null;
      this.pay.amntf = null;
      this.pay.discount = null;
      this.selectedPeriod = this.datePipe.transform(value, 'yyyy-MM')
      this.chargesRequest.bqId = this.bkId
      this.chargesRequest.monthYear = this.datePipe.transform(this.selectedPeriod, 'MM-yyyy')
      this.getCharges(this.chargesRequest);
    }
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}

  showgroup(): void {
    const group = this.prs.paymentListResponse.reduce((acc: any, curr) => {
      let key = curr.loan.agccpt
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(curr)
      return acc
    }, {})

    this.sites = Object.keys(group).map(key => ({
      payment: group[key],
      site: key,
      total: group[key].reduce((acc, curr) => acc + curr.chargeAmount, 0)
    }))
    console.log(this.sites)
  }

  exportExcel() {
    var clientList = [];
    this.prs.paymentListResponse.forEach(element => {
      clientList.push(new PaymentResponseToExcel(element));
    })
    this.pdfHelper.exportexcel(clientList, 'invoice');
  }

  generatePdf() {
    var clientList = [];
    var newList = []
    this.showgroup()
    this.sites = this.sites.sort((a,b) => Number(a.site) - Number(b.site))
    this.sites.forEach(element => {
      element.payment.forEach(pay => {
        clientList.push(new PaymentResponseToPrint(pay));
      })
    })
    this.banks.forEach(element => {
      if (element.bank.code == this.bkId) {
        newList.push(element)
      }
    })
    var total = 0
    total = this.sites.reduce((acc, curr) => acc + curr.total, 0)
    this.pdfHelper.openMiniTabs('invoice', this.sites, total, this.usr.username, newList[0].bank.name, this.selectedPeriod);
  }

  // onPayMethodChange(method: string) {
  //   if (method === 'M' || method === 'O') {
  //     this.getCharges(this.auth.getUser().bqId);
  //   } else {
  //     this.prs = undefined;
  //   }
  // }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  calcDaysOnAmnt(unit_amnt, amnt_pay) {
    return (amnt_pay * 30) / unit_amnt;
  }

  getDuration() {
    if (this.pay.amntPaid != null) {
      var date = new Date();
      // this.pay.periodStrt = this.prs[0].START_DATE == null ? date : this.prs[0].START_DATE;
      // let days = this.getDateDiff(this.pay.periodStrt, new Date(this.prs[0].END_DATE));
      // let ndays = (this.pay.amntPaid * Number(days)) / this.pay.amntDue;
      //console.log(ndays);
      // this.pay.periodEnd = this.addDays(this.pay.periodStrt, Math.round(ndays));
    }

  }

  getDateDiff(date1: Date, date2: Date): number {
    let diff = Math.abs(new Date(date1).getTime() - new Date(date2).getTime());
    //console.log(Math.ceil(diff / (60 * 60 * 24 * 1000)));
    return Math.ceil(diff / (60 * 60 * 24 * 1000));
  }

  printInvoiceReport(): void {
    showLoader(true);
    this.ds.get(`/licences/reports/${this.bkId}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
       // console.log(res.body.data);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
    });
  }

  backClicked() {
    this.loc.back();
  }

}
