<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Users Log</h5>
                    <span>This page list every users activities</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row m-t-50">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-1" style="margin-left: 15px;"></div>
                            <div class=" col-sm-4" *ngIf="usr.roleId==1 && typ">
                                <div class="form-group">
                                    <label class="col-form-label">User</label>

                                    <select #user class="form-control" [(ngModel)]="logUser.name" (change)="onUserChange(user.value)">
                                        <option value="">&nbsp;</option>
                                        <option *ngFor="let usr of filterUsers" value="{{usr.name}}">{{usr.name.toUpperCase()}}</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div class="card ">
                            <div class="card-block">
                                <div class="row">
                                    <div class="col-sm-1"></div>
                                    <div class="col-sm-4">
                                        <div class="form-group ">
                                            <label class="col-form-label ">From</label>
                                            <input type="date" [(ngModel)]="log.from" name="from" required class="form-control ">

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group ">
                                            <label class="col-form-label ">To</label>
                                            <input type="date" [(ngModel)]="log.to" name="to"  required class="form-control ">

                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <button (click)="printLogsReport()" class="btn btn-mat waves-effect waves-light btn-primary m-l-30 mt "><i class="fa fa-print "></i>Print</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>