import { HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/shared/DataService";
import { APIResponse } from "src/app/shared/models";
import { AbstractControl } from "@angular/forms";

declare var showLoader;

@Component({
  selector: "app-change-default-password",
  templateUrl: "./change-default-password.component.html",
  styleUrls: ["./change-default-password.component.css"],
})
export class ChangeDefaultPasswordComponent implements OnInit {
  oldPwd: string;
  newPwd: string;
  confirmPwd: string;
  userUUID: string;
  changePasswordObject: {
    oldPwd: string;
    newPwd: string;
    confirmPwd: string;
  };
  passwordPattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+=-]).{8,}$/;

  constructor(
    private ds: DataService,
    private alert: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.onInit();

    // cleanup
    localStorage.removeItem("defaultPassword");
    localStorage.removeItem("UUID");
  }

  onChangeDefaultPassword() {
    if (this.newPwd != this.confirmPwd) {
      this.alert.error(
        "ERROR",
        "New password is different form password confirmation"
      );
      return;
    }

    this.changePasswordObject.oldPwd = btoa(this.oldPwd);
    this.changePasswordObject.newPwd = btoa(this.newPwd);
    this.changePasswordObject.confirmPwd = btoa(this.confirmPwd);

    showLoader(true);
    this.ds
      .put("/users/change-password/" + this.userUUID, this.changePasswordObject)
      .subscribe({
        next: (res: HttpResponse<APIResponse>) => {
          if (res.body.status === 200) {
            showLoader(false);
            this.alert.success("SUCCESS", "PASSWORD CHANGED SUCCESSFULLY");
            this.router.navigateByUrl("/login");
          } else {
            showLoader(false);
            this.onInit();
            this.alert.error("WARNING", res.body.message);
          }
        },
        error: (err) => {
          showLoader(false);
          this.alert.error(
            "SERVER ERROR",
            "THERE WAS AN INTERNAL SERVER ERROR"
          );
        },
      });
  }

  onInit() {
    this.newPwd = "";
    this.oldPwd = "";
    this.confirmPwd = "";
    this.changePasswordObject = { oldPwd: "", newPwd: "", confirmPwd: "" };
    this.userUUID = localStorage.getItem("UUID");
  }

  validatePassword(): boolean {
    if (this.newPwd && this.newPwd.match(this.passwordPattern)) {
      return true;
    }
    return false;
  }

  confirmationMatchesNewPasswordCheck() {
    if (this.newPwd && this.confirmPwd && this.newPwd == this.confirmPwd) {
      return true;
    }
    return false;
  }
}
