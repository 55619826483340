import { Component, OnInit } from '@angular/core';
import { Agency, BankResponseType, APIResponse } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Component({
  selector: 'app-branche-edit',
  templateUrl: './branche-edit.component.html',
  styleUrls: ['./branche-edit.component.css']
})
export class BrancheEditComponent implements OnInit {

  cId: number;
  agc: Agency;
  banks: Array<BankResponseType>;

  constructor(private ds: DataService, private route: ActivatedRoute, private loc: Location, private router: Router, private alert: ToastrService) {
    this.initView();
  }

  ngOnInit(): void {
    this.initView();
    this.route.params.subscribe((param) => {
      this.cId = param.id;
      this.getBranchById();
    });
  }


  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.banks = res.body.data;
        this.banks.sort((a, b) => (a.bank.name > b.bank.name) ? 1 : ((b.bank.name > a.bank.name) ? -1 : 0));
      //console.log(this.banks);
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }

  getBranchById(): void {
    showLoader(true);
    this.ds.get(`/branches/${this.cId}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.agc = res.body.data;
        //console.log(this.agc);
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false); 
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }

  onUpdate() {
    showLoader(true);
    //console.log(this.agc);
    this.ds.put('/branches/update/'+ this.cId, this.agc).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.alert.success('SUCCESS', 'BRANCHE SAVE SUCCESSFULLY');
        this.router.navigate(["/management/branches"]);
      } else {
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
      //console.log(err);
    });
  }

  initView() {
    this.agc = {} as Agency;
    this.banks = new Array<BankResponseType>();
    this.getbanks();
    this.route.params.subscribe((param) => {
      this.cId = param.id;
      this.getBranchById();
    });
  }

  backClicked() {
    this.loc.back();
  }

}
