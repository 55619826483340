import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { BillingComponent } from './billing/billing.component';
import { UsersLogComponent } from './users-log/users-log.component';
import { LoanAgeingComponent } from './loan-ageing/loan-ageing.component';
import { LoanPortfolioComponent } from './loan-portfolio/loan-portfolio.component';
import { LoanPortfolioBalanceComponent } from './loan-portfolio-balance/loan-portfolio-balance.component';
import { PaymentHistComponent } from './payment-hist/payment-hist.component';
import { BillingSimulComponent } from './billing-simul/billing-simul.component';
import { ProvisionComponent } from './provision/provision.component';
import { MonthSituationComponent } from './month-situation/month-situation.component';


const routes: Routes = [
    { path: 'reports/billing', component: BillingComponent },
    { path: 'reports/loan-situation', component: LoanAgeingComponent },
    { path: 'reports/loan-portfolio', component: LoanPortfolioComponent },
    { path: 'reports/loan-portfolio/balances', component: LoanPortfolioBalanceComponent },
    { path: 'reports/payment-history', component: PaymentHistComponent },
    { path: 'reports/billing-summary', component: BillingSimulComponent },
    { path: 'reports/user-log', component: UsersLogComponent },
    { path: 'reports/provision', component: ProvisionComponent },
    { path: 'reports/month-situation', component: MonthSituationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule,
    SharedModule
  ]
})
export class ReportsRoutingModule { }
