import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DataService {

    constructor(private http: HttpClient) { }
    //public baseUrl = 'http://173.249.63.190:8787/CRMApi';
    public baseUrl = 'https://crmapi.camcculapps.com/CRMApi';
    //public baseUrl = 'https://crmapi.camcculapps.com/CRMApi-new';
    //public baseUrl = 'http://localhost:8787/CRMApi'
    //public baseUrl = 'http://192.168.1.104:8787'
    //public baseUrl = 'http://161.97.171.101:8787/CRMApi';
    //public baseUrl = 'http://173.249.63.190:8787/CRMApi-new';


    async uploadFile(file: File) : Promise<any> {
      const formData: FormData = new FormData();
      formData.append('file', file);
    
      const resp = await fetch(`${this.baseUrl}/file-upload/external-clients/single-file`, {
        method: 'POST',
        body: formData
      });
  

      const apiResponse = await resp.json()
      return apiResponse;
    }

    /**
     * Function to send get request.
     */
    public get(url: string, options?: HttpHeaders): any {
        return this.http.get(`${this.baseUrl}${url}`, { observe: 'response', responseType: 'text' });
    }

    /**
     * Function to send post request
     */
    public post(url: string, data: any, options?: HttpHeaders): Observable<any> {
        return this.http.post(`${this.baseUrl}${url}`, data, { observe: 'response', responseType: 'text' });
    }

    public uploadSync(files: File[], options?: HttpHeaders): Observable<any> {
      const formData: FormData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('files' ,files[i]);
      }

      const req = new HttpRequest('POST', `${this.baseUrl}/file-upload/external-clients`, formData, {
        reportProgress: true,
        responseType: 'json'
      });

      return this.http.request(req);
    }

    /**
     * Function to send put request
     */
    public put(url: string, data: any, options?: HttpHeaders): Observable<any> {
        return this.http.put(`${this.baseUrl}${url}`, data, { observe: 'response', responseType: 'text' });
    }

    /**
     * Function to send put request which will delete an element
     */
    public archive(url: string, data: any, options?: HttpHeaders): Observable<any> {
        return this.http.put(`${this.baseUrl}${url}`, data, { observe: 'response', responseType: 'text' });
    }

    /**
     * Function to send delete request
     */
    public delete(url: string, options?: HttpHeaders): Observable<any> {
        return this.http.delete(`${this.baseUrl}${url}`, { observe: 'response', responseType: 'text' });
    }

    // host: 173.249.63.190
    // username: crm
    // database: camccul_crm
    // password: 092o!hPp

}
