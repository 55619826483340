<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Users</h5>
                    <span>This page allow the admin to create a user</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">

                        <div class="card">
                            <div class="card-header">
                                <h5>New User</h5>
                                <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span>
                            </div>
                            <div class="card-block">
                                <form #usrForm="ngForm">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Role</label>
                                        <div class="col-sm-9">

                                            <select #rol (change)="onChangeRol(rol.value)" [(ngModel)]="usr.roleId" name="roleId" class="form-control" required>
                                                <option value="">Select a value</option>
                                                <option *ngFor="let b of roles" value="{{b.id}}">{{b.name}}</option>
                                                </select>
                                        </div>
                                    </div>
                                    <div class="form-group row" *ngIf="showChap">
                                        <label class="col-sm-3 col-form-label">Select a chapter</label>
                                        <div class="col-sm-9">

                                            <select #chap (change)="onChangeChapter(chap.value)" [(ngModel)]="usr.codchap" name="codchap" class="form-control" required>
                                                <option value="">Select a value</option>
                                                <option *ngFor="let b of chaps" [value]="b.code">{{b.name}}</option>
                                                </select>
                                        </div>
                                    </div>
                                    <div class="form-group row" *ngIf="showCre">
                                        <label class="col-sm-3 col-form-label">Select a Credit Union</label>
                                        <div class="col-sm-9">

                                            <select #bank (change)="onChangeBank(bank.value)" [(ngModel)]="usr.bqId" name="bqId" class="form-control" required>
                                                <option value="">Select a value</option>
                                                <option *ngFor="let b of selectbanks" [value]="b.bank.code">{{b.bank.name}}</option>
                                                </select>
                                        </div>
                                    </div>
                                    <div class="form-group row" *ngIf="showBranch">
                                        <label class="col-sm-3 col-form-label">Select a branch</label>
                                        <div class="col-sm-9">

                                            <select [(ngModel)]="usr.agccpt" name="agccpt" class="form-control" required>
                                                <option value="">Select a value</option>
                                                <option *ngFor="let b of selectbranchs" [value]="b.code">{{b.name}}</option>
                                                </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Username</label>
                                                <div class="col-sm-3"></div>
                                                <div class="col-sm-6">
                                                    <input type="text" [(ngModel)]="usr.username" name="username" required class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group row">

                                                <div class="col-sm-2"></div>
                                                <label class="col-sm-3 col-form-label">Full Name</label>
                                                <div class="col-sm-7">
                                                    <input type="text" [(ngModel)]="usr.name" name="name" required class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Phone</label>
                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="usr.phone" name="phone" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Email</label>
                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="usr.email" name="email" required class="form-control">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-9">
                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Default Password</label>
                                                <div class="col-sm-1"></div>
                                                <div class="col-sm-6">
                                                    <input type="password" [(ngModel)]="pwd" name="password" required class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="checkbox-fade fade-in-primary">
                                                <label>
                                                <input type="checkbox" [checked]="usr.active" (change)="usr.active = !usr.active">
                                                <span class="cr">
                                                <i class="cr-icon icofont icofont-ui-check txt-primary"></i>
                                                </span>
                                                <span>Activate / Desactivate</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <button class="btn btn-mat waves-effect waves-light btn-inverse   float-right" [disabled]="!usrForm.form.valid" (click)="onCreate()">Validate</button>

                                </form>
                            </div>
                        </div>


                    </div>
                    <div class="col-sm-1"></div>
                </div>

            </div>
        </div>
    </div>
</div>
