<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Search Result</h5>
                    <span>This page list all results of the search made</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-12" *ngIf="usr.roleId == 1">

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input type="search" #srch name="searchRes" [(ngModel)]="searchRes" class="form-control" (keydown.enter)="onBeginSearch(srch.value)">
                                    <span>{{lsrs.length}} result(s) ({{seconds}} seconds)</span>
                                    <span class="float-right text-danger" *ngIf="isVisible">No results found matching your query.</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <button (click)="printAdminSearchReport()" [disabled]="!canPrint" class="btn btn-mat waves-effect waves-light btn-primary m-l-30 "><i class="fa fa-print"></i>Print</button>
                            </div>
                        </div>

                        <div class="row" *ngIf="isVisible">
                            <br>
                            <br>
                            <div class="col-md-8">
                                <p class="help-block text-md">Make sure you typed the member's name and National Identity Card number as on their <strong class="f-w-900">National Identity Card</strong>, <strong class="f-w-900">Phone number</strong> or <strong class="f-w-900">Collateral Reference</strong>                                    correctly. The <strong class="f-w-900">Order</strong> and <strong class="f-w-900">Spelling</strong> of the member's name must be well respected and written out fully.</p>
                            </div>
                        </div>

                        <div class="card" *ngIf="!isVisible">
                            <div class="card-header">
                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>result</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">

                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th rowspan="2" class="footable-sortable footable-first-visible" style="display: table-cell;">N°</th>
                                                <th rowspan="2" class="footable-sortable" style="display: table-cell; width: 10%">Credit Union</th>
                                                <th rowspan="2" class="footable-sortable" style="display: table-cell; width: 10%">Branch</th>
                                                <th rowspan="2" class="footable-sortable" style="display: table-cell; width: 10%">Name</th>
                                                <th rowspan="2" class="footable-sortable" style="display: table-cell; width: 10%">Loan Date</th>
                                                <th rowspan="2" class="footable-sortable" style="display: table-cell; width: 10%">Loan Amount</th>
                                                <th rowspan="2" class="footable-sortable" style="display: table-cell; width: 15%">Purpose</th>
                                                <th rowspan="2" class="footable-sortable" style="display: table-cell; width: 15%">Balance</th>
                                                <th style="border-left: solid #EAEFF0 1px; border-right: solid #EAEFF0 1px;" colspan="2" class="text-center" translate="views.DELINQUENCY">Delinquency situation</th>
                                                <th rowspan="2" class="footable-sortable" style="display: table-cell;">collateral security</th>
                                                <th rowspan="2" class="footable-sortable" style="display: table-cell;">Written of Balance</th>
                                            </tr>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable text-center" style="display: table-cell; border-left: solid #EAEFF0 1px; border-right: solid #EAEFF0 1px;">days</th>
                                                <th class="footable-sortable text-center" style="display: table-cell; border-left: solid #EAEFF0 1px; border-right: solid #EAEFF0 1px;">amount</th>
                                            </tr>


                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let s of lsrs; let i = index">
                                                <td class="footable-first-visible" style="display: table-cell">{{i+1}}</td>
                                                <td style="display: table-cell;">{{s.client.bank}}</td>
                                                <td style="display: table-cell;">{{s.client.branch}}</td>
                                                <td style="display: table-cell;">{{s.client.name}}</td>
                                                <td style="display: table-cell;">{{s.datacc != null ? s.datacc : "Unvailable"}}</td>
                                                <td style="display: table-cell;">{{s.mtpret != null ? s.mtpret : "Unavailable"}}</td>
                                                <td style="display: table-cell;">{{s.motifdem != null ? s.motifdem : "Unavailable"}}</td>
                                                <td style="display: table-cell;">{{s.montrest != null ? s.montrest : "Unavailable"}}</td>
                                                <td style="display: table-cell;">{{s.retard != null ? s.retard : "Unavailable"}}</td>
                                                <td style="display: table-cell;">{{s.valretard != null ? s.valretard : "Unavailable"}}</td>
                                                <td style="display: table-cell;">{{s.garantee != null ? s.garantee : "Unavailable"}}</td>
                                                <td style="display: table-cell;">{{(s.retard >= 360) ? s.montrest : 0}}</td>
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr class="footable-paging">
                                                <td colspan="5">
                                                    <ul class="pagination">
                                                        <!-- <li class="footable-page-nav disabled" data-page="first"><a class="footable-page-link" href="#">«</a></li>
                                                    <li class="footable-page-nav disabled" data-page="prev"><a class="footable-page-link" href="#">‹</a></li> -->
                                                        <!-- <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==page}" data-page="1"><a (click)="setPage(i, $event)" class="footable-page-link" href="#">{{i}}</a></li> -->
                                                        <!-- <li class="footable-page-nav" data-page="next"><a class="footable-page-link" href="#">›</a></li>
                                                    <li class="footable-page-nav" data-page="last"><a class="footable-page-link" href="#">»</a></li> -->
                                                    </ul>
                                                    <div class="divider"></div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>




                    <div class="col-sm-12" *ngIf="usr.roleId != 1">

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <input type="search" #srch name="searchRes" [(ngModel)]="searchRes" class="form-control" (keydown.enter)="onBeginSearch(srch.value)">
                                    <span>{{lsrs.length}} result(s) ({{seconds}} seconds)</span>
                                    <span class="float-right text-danger" *ngIf="isVisible">No results found matching your query.</span>



                                </div>
                            </div>
                            <div class="col-sm-4">
                                <button (click)="printClientSearchReport()" [disabled]="!canPrint" class="btn btn-mat waves-effect waves-light btn-primary m-l-30 "><i class="fa fa-print"></i>Print</button>
                            </div>
                        </div>



                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>result</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">

                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">N°</th>
                                                <th class="footable-sortable" style="display: table-cell; width: 10%">NAME</th>
                                                <th class="footable-sortable" style="display: table-cell; width: 10%">FILE</th>
                                                <th class="footable-sortable" style="display: table-cell; width: 10%">CREDIT UNION</th>
                                                <th class="footable-sortable" style="display: table-cell; width: 10%">BRANCH</th>
                                                <th class="footable-sortable" style="display: table-cell; width: 10%">NIC</th>
                                                <th class="footable-sortable" style="display: table-cell; width: 15%">STATUS</th>
                                                <th class="footable-sortable" style="display: table-cell; width: 15%">BLACKLIST STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let s of lsrs; let i = index;">
                                                <td class="footable-first-visible" style="display: table-cell">{{i+1}}</td>
                                                <td style="display: table-cell;">{{s.client.name}}</td>
                                                <td style="display: table-cell;">{{s.numdos != null ? s.numdos : "Unvailable"}}</td>
                                                <td style="display: table-cell;">{{s.client.bank}}</td>
                                                <td style="display: table-cell;">{{s.client.branch}}</td>
                                                <td style="display: table-cell;">{{s.client.cni}}</td>
                                                <td style="display: table-cell;"><span class="label" [ngStyle]="{'background-color': s.color != null ? s.color : ''}">{{s.color != null ? s.color : "Unvailable"}}</span></td>
                                                <!-- <td style="display: table-cell;">Unavailable</td> -->
                                                <td style="display: table-cell;">{{s.client.blackHis != null ? "Once Blacklisted" : "Clean Record"}}</td>
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr class="footable-paging">
                                                <td colspan="5">
                                                    <ul class="pagination">
                                                        <!-- <li class="footable-page-nav disabled" data-page="first"><a class="footable-page-link" href="#">«</a></li>
                                                        <li class="footable-page-nav disabled" data-page="prev"><a class="footable-page-link" href="#">‹</a></li> -->
                                                        <!-- <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==page}" data-page="1"><a (click)="setPage(i, $event)" class="footable-page-link" href="#">{{i}}</a></li> -->
                                                        <!-- <li class="footable-page-nav" data-page="next"><a class="footable-page-link" href="#">›</a></li>
                                                        <li class="footable-page-nav" data-page="last"><a class="footable-page-link" href="#">»</a></li> -->
                                                    </ul>
                                                    <div class="divider"></div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
