import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { DataService } from 'src/app/shared/DataService';
import { Location } from '@angular/common';
import { isNil, reverse } from 'lodash';
import { MenuServiceEn } from 'src/app/shared/menu.en.service';
import { UserRole, APIResponse } from 'src/app/shared/models';
import { TreeviewItem, DownlineTreeviewItem, TreeviewComponent, TreeviewEventParser, OrderDownlineTreeviewEventParser, TreeviewConfig } from 'ngx-treeview';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Injectable()
export class MenuTreeviewConfig extends TreeviewConfig {
  hasAllCheckBox = true;
  hasFilter = true;
  hasCollapseExpand = true;
  maxHeight = 450;
}

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css'],
  providers: [
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: TreeviewConfig, useClass: MenuTreeviewConfig }
  ]
})
export class UserRoleComponent implements OnInit {
  @ViewChild('TreeviewComponent', { static: false }) treeviewComponent: TreeviewComponent;

  items: TreeviewItem[];
  checkedItems: any;
  rows: string[];
  role: UserRole;
  roles: Array<string>;
  listRoles: Array<UserRole>;
  listRoles2: Array<UserRole>;
  canUpd: boolean;

  constructor(private ds: DataService, private loc: Location, private _men: MenuServiceEn, private alert: ToastrService) { }

  ngOnInit(): void {
    this.items = this._men.getMenuTreeItems();
    this.initView();
    this.getAllUserRoles();
  }

  //#region CRUD Operations

  /**
   * Add new User role in database
   */
  onCreateRole(): void {
    showLoader(true);
    this.role.menu = this.roles.join(',');
    this.ds.post(`/roles/new`, this.role)
      .subscribe((res: HttpResponse<APIResponse>) => {
        showLoader(false);
        this.initView();
        this.getAllUserRoles();
        this.alert.success('SUCCESS', 'USER ROLE SAVE SUCCESSFULLY');
      }, (err) => {
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
        //console.log(err);
      });
  }

  /**
   * Update a User role in database
   */
  onUpdate(): void {
    showLoader(true);
    this.role.menu = this.roles.join(',');
    this.ds.put(`/roles/update/${this.role.id}`, this.role)
      .subscribe((res: HttpResponse<APIResponse>) => {
        showLoader(false);
        if (res.status === 200) {
          this.initView();
          this.getAllUserRoles();
          this.alert.success('SUCCESS', 'USER ROLE UPDATED SUCCESSFULLY');
        }
      }, (err) => {
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
        //console.log(err);
      });
  }

  /**
   * Delete a User role from database.
   */
  onDelete(): void {
    showLoader(true);
    this.ds.delete(`/roles/delete/${this.role.id}`).subscribe((res) => {
      showLoader(false);
      this.initView();
      this.getAllUserRoles();
      this.alert.success('SUCCESS', 'USER ROLE DELETED SUCCESSFULLY');
    }, (err) => { 
      showLoader(false); 
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      //console.log(err); 
    });

  }

  /**
   * Get Single User role from database
   * @param id
   */
  getRoleById(id: number): void {
    try {
      const r = this.listRoles.find((role) => {
        return Number(role.id) === Number(id);
      });
      if (r !== undefined) {
        this.role = r;
        this.setCheckedItems(this.role.menu.split(','));
        this.roles = this.role.menu.split(',');
      }
    } catch (error) { }
  }

  getAllUserRoles(): void {
    showLoader(true);
    this.ds.get(`/roles/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      this.listRoles = res.body.data;
      this.listRoles2 = res.body.data;
    }, (err) => { 
      showLoader(false);  
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }
  //#endregion


  /**
 * ngx-treeview item selected listener
 * @param downlineItems
 */
  onSelectedChange(downlineItems: DownlineTreeviewItem[]) {
    this.rows = [];
    this.roles = [];
    let values = [];
    downlineItems.forEach(downlineItem => {
      const item = downlineItem.item;
      const value = item.value;
      values = [item.value];
      const texts = [item.text];
      let parent = downlineItem.parent;
      while (!isNil(parent)) {
        texts.push(parent.item.text);
        values.push(parent.item.value);
        parent = parent.parent;
      }
      const reverseTexts = reverse(texts);
      const row = `${reverseTexts.join(' > ')} : ${value}`;
      this.rows.push(row);
      values.forEach(v => {
        this.roles.push(v);
      });
    });

    this.roles = this.removeDuplicates(reverse(this.roles));
    this.role.menu = this.roles.join(',');
  }

  /**
 * Check items in tree view matching access rights from user role selected.
 * @param values
 */
  setCheckedItems(values: Array<string>): void {
    this.items.forEach(child1 => {
      child1.setCheckedRecursive(true);
      child1.setCollapsedRecursive(false);
      (values.indexOf(child1.value) === -1) ? (child1.checked = false, child1.correctChecked()) : (child1.checked = true);
      if (Object.keys(child1).indexOf('internalChildren') !== -1) {
        child1.children.forEach(child2 => {
          child2.setCheckedRecursive(true);
          child2.setCollapsedRecursive(false);
          (values.indexOf(child2.value) === -1) ? (child2.checked = false, child2.correctChecked()) : (child2.checked = true);
          if (Object.keys(child2).indexOf('internalChildren') !== -1) {
            child2.children.forEach(child3 => {
              child3.setCheckedRecursive(true);
              child3.setCollapsedRecursive(false);
              (values.indexOf(child3.value) === -1) ? (child3.checked = false, child3.correctChecked()) : (child3.checked = true);
              if (Object.keys(child3).indexOf('internalChildren') !== -1) {
                child3.children.forEach(child4 => {
                  child4.setCheckedRecursive(true);
                  child4.setCollapsedRecursive(false);
                  (values.indexOf(child4.value) === -1) ? (child4.checked = false, child4.correctChecked()) : (child4.checked = true);
                });
              }
            });
          }
        });
      }
    });
    this.treeviewComponent.raiseSelectedChange();
  }

  /**
   * Uncheck all items in treeview.
   */
  uncheckItems(): void {
    this.items.forEach(item => {
      item.setCheckedRecursive(false);
      item.setCollapsedRecursive(true);
    });
  }

  onRoleClick(id: number): void {
    //console.log(id);
    this.canUpd = true;
    this.getRoleById(id);
  }

  /**
   * Initialize form.
   */
  initView(): void {
    this.uncheckItems();
    this.role = {} as UserRole;
    //this.role.sts = true;
    this.canUpd = false;
    this.getAllUserRoles();
  }

  removeDuplicates(array: Array<string>): Array<string> {
    let filtered: Array<string> = [];
    filtered = array.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    return filtered;
  }

  onSearchKeyUp(query: string): void {
    try {
      this.listRoles2 = this.listRoles.filter((role) => {
        return role.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    } catch (error) { }
  }

  backClicked() {
    this.loc.back();
  }
}
