<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Credit Union Branches</h5>
                    <span>This page permit the user to create a Credit Union Branche</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="page-header-breadcrumb">
                <ul class=" breadcrumb breadcrumb-title">
                    <li class="breadcrumb-item">
                        <a href="index.html"><i class="feather icon-home"></i></a>
                    </li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Management</a> </li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Branche</a> </li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">New Credit Union Branche</a> </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">

                        <div class="card">
                            <div class="card-header">
                                <h5>New Credit Union Branche</h5>
                                <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span>
                            </div>
                            <div class="card-block">
                                <form #agcForm="ngForm">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Select a credit Union</label>
                                        <div class="col-sm-9">

                                            <select [(ngModel)]="agc.bqId" name="bqId" class="form-control" required>
                                                <option value="">Select a value</option>
                                                <option *ngFor="let b of banks" [value]="b.bank.id">{{b.bank.name}}</option>
                                                </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="agc.name" name="name" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Code</label>
                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="agc.code" name="code" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Phone 1</label>
                                                <div class="col-sm-3"></div>
                                                <div class="col-sm-6">
                                                    <input type="text" [(ngModel)]="agc.tel" name="tel" required class="form-control">
                                                    <span>Branch phone number</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group row">

                                                <div class="col-sm-2"></div>
                                                <label class="col-sm-3 col-form-label">Phone 2</label>
                                                <div class="col-sm-7">
                                                    <input type="text" [(ngModel)]="agc.tel2" name="tel2" class="form-control">
                                                    <span>Chief Loan officer's phone number</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Email</label>
                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="agc.email" name="email" class="form-control">
                                            <span>This page permit the user to update an existing chapter</span>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">P.O Box</label>
                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="agc.box" name="box" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Location</label>
                                        <div class="col-sm-9">
                                            <input type="text" [(ngModel)]="agc.loc" name="loc" required class="form-control">
                                        </div>
                                    </div>

                                    <button class="btn btn-mat waves-effect waves-light btn-inverse   float-right" [disabled]="!agcForm.form.valid" (click)="onCreate()">Validate</button>

                                </form>
                            </div>
                        </div>


                    </div>
                    <div class="col-sm-1"></div>
                </div>

            </div>
        </div>
    </div>
</div>