import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chapter, APIResponse } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Component({
  selector: 'app-chapter-edit',
  templateUrl: './chapter-edit.component.html',
  styleUrls: ['./chapter-edit.component.css']
})
export class ChapterEditComponent implements OnInit {

  chap: Chapter;
  cId: number;

  constructor(private ds: DataService, private route: ActivatedRoute, private loc: Location, private alert: ToastrService ) { 
    this.initView();
    
  }

  ngOnInit(): void {
  }

  getChapterById(): void {
    showLoader(true);
    this.ds.get(`/chapters/${this.cId}`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.chap = res.body.data;
       // console.log(this.chap);
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      //showLoader(false); 
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }

  onUpdate() {
    showLoader(true);
    this.ds.put('/chapters/update/'+this.cId, this.chap).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
       showLoader(false);
        this.alert.success('SUCCESS', 'CHAPTER SAVE SUCCESSFULLY');
        //this.initView();
      } else {
       // console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
     this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
     //console.log(err);
    });
  }

  initView() {
    this.chap = {} as Chapter;
    this.route.params.subscribe((param) => {
      this.cId = param.id;
      this.getChapterById();
    });
  }

  backClicked() {
    this.loc.back();
  }

}
