import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor';
import { DataService } from './DataService';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { TrameUtilService } from './trame-util.service';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Printer } from './printer.service';
import { UiSwitchModule } from 'ngx-ui-switch';


@NgModule({
  declarations: [NavComponent, SideNavComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UiSwitchModule,
  ],
  providers: [
    DataService,
    AuthService,
    AuthGuard,
    TrameUtilService,
    Printer,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  exports: [
    NavComponent, SideNavComponent
  ]
})
export class SharedModule { }
