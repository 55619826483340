import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MomoPayment, paymentObj } from './models';

const mobileUrl = 'https://momo.camcculapps.com/api/payment/';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private _httpClient: HttpClient;

  constructor(handler: HttpBackend) { 
    this._httpClient = new HttpClient(handler);
  }

  public payInitialDeposit(momo: MomoPayment): Observable<any> {
    return this._httpClient.post<paymentObj>(mobileUrl, JSON.stringify(momo), {
        headers: { 'Content-Type': 'application/json' }
    });
  }

}
