<section class="login-block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">

                <form #loginForm="ngForm" autocomplete="off">
                    <div class="text-center">
                        <img src="assets/images/log111.png" alt="logo.png">
                    </div>
                    <div class="auth-box card">
                        <div class="card-block">
                            <div class="row m-b-20">
                                <div class="col-md-12">
                                    <h3 class="text-center txt-primary">Log In</h3>
                                </div>
                            </div>
                            <p class="text-muted text-center p-b-5">Log in with your account</p>

                            <div class="alert alert-danger icons-alert" *ngIf="msg != null">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <i class="icofont icofont-close-line-circled"></i>
                                </button>
                                <p class="f-12"><strong>{{msg}}</strong></p>
                            </div>

                            <div class="form-group form-primary">
                                <label class="float-label">Username</label>
                                <input type="text" name="username" [(ngModel)]="login.username" class="form-control form-control-sm" required="">


                            </div>
                            <div class="form-group form-primary p-t-5">
                                <label class="float-label">Password</label>
                                <input type="password" name="pwd" [(ngModel)]="pwd" class="form-control form-control-sm" required="">


                            </div>

                            <div class="row m-t-30">
                                <div class="col-md-12">
                                    <button type="submit" [disabled]="!loginForm.form.valid" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20" (click)="onSignIn()">LOGIN</button>
                                </div>
                            </div>

                            <div class="row m-t-30">
                              <div class="col-md-12">
                                <p class="m-t-5 text-center">
                                  <small class="text-info text-decoration-underline">
                                    <a routerLink="/forgot-password" (click)="forgotPassword()">Forgot Password ?</a>
                                  </small>
                                </p>
                              </div>
                            </div>

                        </div>
                    </div>
                    <p class="m-t-5 text-center">
                        <small class="text-muted">Credit Risk Management <br>&copy; 2020 I-Tech Sarl.</small>
                    </p>
                </form>

            </div>

        </div>

    </div>
</section>
