import { Component, OnInit } from '@angular/core';
import { AccessCharge, PageChapter, APIResponse, UserRole } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/printer.service';
import { AuthService } from 'src/app/shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-access-charge',
  templateUrl: './access-charge.component.html',
  styleUrls: ['./access-charge.component.css']
})
export class AccessChargeComponent implements OnInit {

  acs: Array<AccessCharge>;
  page: number;
  pageChapter: PageChapter;
  pages: Array<number>;
  ac: AccessCharge;
  canUpd: boolean;
  base64File: string;
  ro: boolean;
  ur: UserRole;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService, private auth: AuthService) {
    this.initView();
   }

  ngOnInit(): void {
  }

  initView() {
    this.acs = new Array<AccessCharge>();
    this.page = 0;
    this.pageChapter = {} as PageChapter;
    this.ur = {} as UserRole;
    this.ac = {} as AccessCharge;
    this.canUpd = false;
    this.base64File = '';
    this.ro = false;
    this.getAccessCharges(this.page);
    this.getRoles(this.auth.getUser().roleId);
  }

  getAccessCharges(page: number): void {
    showLoader(true);
    this.ds.get(`/access-configs/list?page=${page}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.pageChapter = res.body.data;
        this.pages = new Array(this.pageChapter.totalPages);
        this.acs = this.pageChapter.content;
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }

  onCreate() {
    showLoader(true);
    //console.log(this.ac);
    this.ds.post('/access-configs/new', this.ac).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
       showLoader(false);
        this.alert.success('SUCCESS', 'ACCESS CHARGE SAVE SUCCESSFULLY');
        this.initView();
      } else {
        //console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
     this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
     //console.log(err);
    });
  }

  onUpdate() {
    showLoader(true);
    this.ds.put('/access-configs/update/'+this.ac.id, this.ac).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
       showLoader(false);
        this.alert.success('SUCCESS', 'ACCESS CHARGE UPDATED SUCCESSFULLY');
        this.initView();
      } else {
        //console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
     this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
     //console.log(err);
    });
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.getAccessCharges(i);
  }

  onDelete(id: number) {
    showLoader(true)
    this.ds.delete('/access-configs/delete/' + id)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.status === 200) {
          showLoader(false);
          this.initView();
          this.alert.success('SUCCESS', 'ACCESS CHARGE DELETED SUCCESSFULLY');
        } else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
          //console.log(res.body.message);
        }
      }, (err) => {
        //console.log(err);
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
      });

  }

  onBeforeUpdate(a: AccessCharge) {
    this.ac = a;
    this.canUpd = true;
  }

  printChargesReport(): void {
    //console.log('here');
    showLoader(true);
    this.ds.get(`/access-configs/reports/list`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        this.base64File = res.body.data;
        showLoader(false);
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => { 
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }

  getRoles(id: number): void {
    showLoader(true);
    this.ds.get(`/roles/${id}`).subscribe((res: HttpResponse<APIResponse>) => {

      if (res.body.status === 200) {
        this.ur = res.body.data;
        showLoader(false);
        //console.log(this.ur);
        if (this.ur.privilege.toLowerCase() == 'RW'.toLowerCase()) {
          this.ro = true;
        }
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  backClicked() {
    this.loc.back();
  }

}
