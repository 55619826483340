import { Component, OnInit } from '@angular/core';
import { PageChapter, Chapter, APIResponse, Bank, UserRole } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/printer.service';
import { AuthService } from 'src/app/shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-credit-list',
  templateUrl: './credit-list.component.html',
  styleUrls: ['./credit-list.component.css']
})
export class CreditListComponent implements OnInit {


  page: number;
  pageCredit: PageChapter;
  pages: Array<number>;
  credits: Array<Bank>;
  filterCredits: Array<Bank>;
  base64File: string;
  ro: boolean;
  ur: UserRole;
  currentSelectedPage: number = 0;
  pag: number;
  pagnum: number;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService, private auth: AuthService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.page = 0;
    this.pageCredit = {} as PageChapter;
    this.ur = {} as UserRole;
    this.credits = new Array<Bank>();
    this.filterCredits = new Array<Bank>();
    this.base64File = '';
    this.ro = false;
    this.pages =[];
    this.pagnum = 20;
    this.getBanksList(0);
    //this.getbanks();
    this.getRoles(this.auth.getUser().roleId);
  }

  getBanksList(page: number): void {
    showLoader(true);
    this.ds.get(`/banks/list/${this.pagnum}?page=${page}`).subscribe((res: HttpResponse<APIResponse>) => {

      if (res.body.status === 200) {
       // console.log(res.body.data);
        this.pageCredit = res.body.data;
        this.pages = Array(this.pageCredit.totalPages).fill(0).map((x,i)=>i);
        this.credits = this.pageCredit.content;
        this.filterCredits = this.pageCredit.content;
        showLoader(false);
        //console.log(res.body.data);
      } else {
        showLoader(false);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  // getbanks(): void {
  //   showLoader(true);
  //   this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {

  //     if (res.body.status === 200) {
  //       // this.pageChapter = res.body.data;
  //       // this.pages = new Array(this.pageChapter.totalPages);
  //       // this.chaps = this.pageChapter.content;
  //       // this.filterChaps = this.pageChapter.content;
  //       //console.log(res.body.data);
  //       showLoader(false);
  //     }
  //   }, (err) => {
  //     //console.log(err);
  //     showLoader(false);
  //     this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
  //   });
  // }

  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterCredits = this.credits.filter((cre) => {
        return cre.name.toLowerCase().includes(query.toLowerCase())||
          cre.code.toLowerCase().includes(query.toLowerCase()) ||
          cre.abrv.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.filterCredits = this.credits;
    }
  }

  setPage(i) {
    this.getBanksList(i);
  }

  getPaginationWithIndex(index: number, pag: any) {
    this.setPage(index);
  }

  active(index: number) {
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  onChangeNumber(pag: number){
    try {
      this.pagnum = pag;
      this.getBanksList(0);
    } catch (error) {
      
    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.pageCredit.totalPages - 1) {
      this.setPage(++this.currentSelectedPage);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.setPage(--this.currentSelectedPage);
    }
  }

  onDelete(id: number) {
    showLoader(true)
    this.ds.delete('/banks/delete/' + id)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.body.status === 200) {
          showLoader(false);
          this.initView();
          this.alert.success('SUCCESS', 'CREDIT UNION DELETED SUCCESSFULLY');
        } else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
          //console.log(res.body.message);
        }
      }, (err) => {
        //console.log(err);
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });

  }

  getRoles(id: number): void {
    showLoader(true);
    this.ds.get(`/roles/${id}`).subscribe((res: HttpResponse<APIResponse>) => {

      if (res.body.status === 200) {
        this.ur = res.body.data;
        showLoader(false);
        //console.log(this.ur);
        if (this.ur.privilege.toLowerCase() == 'RW'.toLowerCase()) {
          this.ro = true;
        }
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  printBankReport(): void {
    showLoader(true);
    this.ds.get(`/banks/reports/list`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  backClicked() {
    this.loc.back();
  }

}
