import { Injectable, Inject } from '@angular/core';
import { AES, enc, mode, pad } from 'crypto-js';
import * as CryptoJS from 'crypto-js';
import { InvokerService } from './invoker.service';
import { AuthService } from './auth.service';
import { ResponsePolicy, STATUS, APIResponse } from './models';

@Injectable({
  providedIn: 'root'
})
export class TrameUtilService {

  public SecuVal = 'ITABW';
  public separator = 'Þ';
  protected keykey = 'syjndkdhzo';
  private key: string;
  private decryptedString: string;
  private encryptedString: string;
  public InvokeKeyProvider: () => string;
  public version = '1';
  constructor(private authService: AuthService) {
    // this.authService.getUser();
    this.key = this.getKey();
    // console.log(this.key);
  }

  // Object Building
  public GetEntityFromTrame<T>(trame: string): T {
    //console.log(trame);
    // const strArray: string[] = trame.split(this.separator);
    // return JSON.parse(strArray[1]) as T;
    return JSON.parse(trame) as T;
  }

  public GetResponse<T>(trame: string): T {
    //console.log(trame);
    const resp = this.GetEntityFromTrame<T>(this.decrypt(trame));
    return resp;
  }

  public GetTrameForCnx(code: string, pwd: string, mac: string, module: string) {
    const builder = this.version;
    builder.concat(this.separator);
    builder.concat(code);
    builder.concat(this.separator);
    builder.concat(pwd);
    builder.concat(this.separator);
    builder.concat(module);
    builder.concat(this.separator);
    builder.concat(mac);
    builder.concat(this.separator);
    builder.concat(this.SecuVal);
    return builder.toString();
  }

  public GetTrameForDecnx(code: string, mac: string) {
    const builder = this.version;
    builder.concat(this.separator);
    builder.concat(code);
    builder.concat(this.separator);
    builder.concat(this.SecuVal);
    builder.concat(this.separator);
    builder.concat(mac);
    return builder.toString();
  }

  public GetTrameForUpdatePass(cdutilis: string, newpass: string, mac: string) {
    const builder = this.version;
    builder.concat(this.separator);
    builder.concat(cdutilis);
    builder.concat(this.separator);
    builder.concat(newpass);
    builder.concat(this.separator);
    builder.concat(this.SecuVal);
    builder.concat(this.separator);
    builder.concat(mac);
    return builder.toString();
  }

  public GetTrameForUtilisGuichet(codage: string, mac: string) {
    const builder = this.version;
    builder.concat(this.separator);
    builder.concat(codage);
    builder.concat(this.separator);
    builder.concat(this.SecuVal);
    builder.concat(this.separator);
    builder.concat(mac);
    return builder.toString();
  }

  public GetTrameForId(id: string) {
    return (this.version + this.separator + id + this.separator + this.SecuVal);
  }

  public GetTrameForEntity(param: any) {
    let clt = JSON.stringify(param);
    clt = this.version + this.separator + clt + this.separator + this.SecuVal;
    // console.log(clt);
    return clt;
  }

  public GetTrameWithSecurity() {
    return this.version + this.separator + this.SecuVal;
  }

  public GetTrameForTable(table: string[]) {
    return this.version + this.separator + table.join(this.separator) + this.separator + this.SecuVal;
  }

  public encrypt(strToEncrypt: string): string {
    const key = CryptoJS.enc.Hex.parse(this.base64ToHexString(this.key));
    const encrypted = CryptoJS.AES.encrypt(strToEncrypt, key, this.getCipherConfig());
    // const result = (cipherparams.ciphertext.toString());
    return this.base64ToHexString(CryptoJS.enc.Base64.stringify(encrypted.ciphertext));
  }

  public decrypt(strToDecrypt: string): string {
    try {
      const bytesToDecrypt = this.stringHexToBase64(strToDecrypt);
      const key = CryptoJS.enc.Hex.parse(this.base64ToHexString(this.key));
      const bytes = CryptoJS.AES.decrypt(bytesToDecrypt, key, this.getCipherConfig());
      const decryptedData = bytes.toString(enc.Utf8);
      return decryptedData;

    } catch (err) {
      throw new Error('Error while decrypting: ' + err);
    }
  }

  private getCipherConfig(): {} {
    const key = CryptoJS.enc.Hex.parse(this.base64ToHexString(this.key));
    return {
      keySize: 128 / 32,
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    };
  }
  private stringHexToBase64(str: string): string {
    return btoa(str.match(/\w{2}/g).map(function (a) {
      return String.fromCharCode(parseInt(a, 16));
    }).join(''));
  }

  private base64ToHexString(str: string): string {
    const raw = atob(str);
    let HEX = '';

    for (let i = 0; i < raw.length; i++) {
      const _hex = raw.charCodeAt(i).toString(16);
      HEX += (_hex.length === 2 ? _hex : '0' + _hex);
    }
    return HEX.toUpperCase();
  }

  _base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  //#region Key Management
  /**
   * Returns Key from local storage.
   * @return string | null
   */
  public getKey(): string {
    try {
      return this.authService.getUser().secuKey;
    } catch (error) {
      return null;
    }
  }
  //#endregion
}
