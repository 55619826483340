<nav class="pcoded-navbar">
    <div class="nav-list">
        <div class="pcoded-inner-navbar main-menu">
            <div class="pcoded-navigation-label">Navigation</div>
            <ul class="pcoded-item pcoded-left-item">
                <li class="pcoded-trigger" *ngIf="hasCode('A00')">
                    <a routerLink="/home" class="waves-effect waves-dark">
                        <span class="pcoded-micon">
                            <i class="feather icon-home"></i>
                        </span>
                        <span class="pcoded-mtext">Home</span>
                    </a>
                </li>

                <li class="pcoded-hasmenu" *ngFor="let m of items">
                    <ng-template [ngIf]="hasCode(m.value)">
                        <a href="javascript:void(0)" class="waves-effect waves-dark">
                            <span class="pcoded-micon"><i class="{{m.icon}}"></i></span>
                            <span class="pcoded-mtext">{{m.text}}</span>
                        </a>
                        <ul class="pcoded-submenu">
                            <li class="pcoded-hasmenu" *ngFor="let s of m.children">
                                <ng-template [ngIf]="hasCode(s.value)">
                                    <a *ngIf="s.children" href="javascript:void(0)" class="waves-effect waves-dark">
                                        <span class="pcoded-mtext">{{s.text}}</span>
                                    </a>

                                    <li *ngIf="!s.children" class=" ">
                                        <a routerLink="{{s.path}}" class="waves-effect waves-dark">
                                            <span class="pcoded-mtext">{{s.text}}</span>
                                        </a>
                                    </li>

                                    <ul *ngIf="s.children" class="pcoded-submenu">
                                        <li *ngFor="let i of s.children" class=" ">
                                            <a routerLink="{{i.path}}" class="waves-effect waves-dark">
                                                <span class="pcoded-mtext">{{i.text}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </ng-template>
                            </li>
                        </ul>
                    </ng-template>
                </li>

            </ul>

        </div>
    </div>
</nav>