import { Component, OnInit } from '@angular/core';
import { PageChapter, PermanentlyBlockedResponse, APIResponse, AlertMessage, User, PaymentResponse } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe } from '@angular/common';

declare var showLoader;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  page: number;
  usr: User;
  prs: Array<PaymentResponse>;
  alrt: AlertMessage;
  pageChapter: PageChapter;
  pages: Array<number>;
  pbs: Array<PermanentlyBlockedResponse>;
  filterPbs: Array<PermanentlyBlockedResponse>;
  query: string;
  currentSelectedPage: number = 0;
  pag: number;
  pagnum: number;
  curDate: string;

  constructor(private ds: DataService, private router: Router, private auth:AuthService, private alert: ToastrService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.page = 0;
    this.pageChapter = {} as PageChapter;
    this.alrt = {} as AlertMessage;
    this.usr = this.auth.getUser();
    this.pbs = new Array<PermanentlyBlockedResponse>();
    this.filterPbs = new Array<PermanentlyBlockedResponse>();
    this.pages =[];
    this.pagnum = 20;
    this.getAll(0);
    this.getAlertsList();
  }

  getAll(page: number): void {
    showLoader(true);
    this.ds.get(`/situations/permently-blocked/${this.pagnum}?page=${page}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.pageChapter = res.body.data;
        this.pages = Array(this.pageChapter.totalPages).fill(0).map((x,i)=>i);
        this.pbs = this.pageChapter.content;
        this.filterPbs = this.pageChapter.content;
        this.currentSelectedPage = this.pageChapter.number;
      }
    }, (err) => {
      showLoader(false);
    });
  }


  getAlertsList(): void {
    showLoader(true);
    this.ds.get(`/alerts/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.alrt = res.body.data;
        if(this.alrt != null && this.alrt != undefined){
          Swal.fire(this.alrt.title, this.alrt.msg, 'info')
        }
      }
    }, (err) => {
      showLoader(false);
    });

    setTimeout(() => {
      if (this.usr.roleId !== 1 && this.usr.roleId !== 2) {
        let today = new Date()
        let userSyncDate = new Date(this.usr.userAgencyLastSyncDate)
        let difference = Math.floor((Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(userSyncDate.getFullYear(), userSyncDate.getMonth(), userSyncDate.getDate())) / (1000 * 60 * 60 * 24))
        if(difference >= 1) {
          Swal.fire('Reminder', 'Your branch data has not been synchorized in ' + difference + ' days, kindly update your data.', 'info');
        }
      }
    }, 5000);

    setTimeout(() => {
      if (this.usr.roleId !== 1 && this.usr.roleId !== 2) {
        if (this.usr.isLicenceActive) {
          const expireDay = this.calculateDaysDifference(this.usr.expiryDate);
          if(expireDay <= 14){
            Swal.fire('Reminder', 'Your licence will expire in ' + expireDay + ' days, please renew it before then.', 'info');
          }else if(expireDay == 1){
            Swal.fire('Reminder', 'Your licence will expire in ' + expireDay + ' day, please renew it before then.', 'info');
          }
        } else {
          Swal.fire('Reminder', 'Access license expired, please renew it to have full access to the system again ', 'info');
        }
      }
    }, 3000);

  }

  /**
   * This function calculates how many days are left for license expiration.
   * @param date The date of license expiry.
   * @returns The number of days between the expiry date and the current date.
   */
  calculateDaysDifference(date: string): number {
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const currentDate: Date = new Date();
    const expiryDate: Date = new Date(date);

    const diff = Math.floor((expiryDate.getTime() - currentDate.getTime()) / millisecondsInADay);
    return diff;
  }

  calculateDiff(dateSent){
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
  }

  onBeginSearch() {
    //console.log(this.query);
    let str = this.query.replace(" ", "&");
    //console.log(str);
    if (this.query != null) {
      this.router.navigate(['/search/result'], { queryParams: { query:  this.query } });
    }
  }

  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterPbs = this.pbs.filter((chap) => {
        return chap.num_cli.toLowerCase().includes(query.toLowerCase())||
          chap.client_name.toLowerCase().includes(query.toLowerCase()) ||
          chap.bank_name.toLowerCase().includes(query.toLowerCase()) ||
          chap.branch_name.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.filterPbs = this.pbs;
    }
  }

  setPage(i) {
    this.getAll(i);
  }

  getPaginationWithIndex(index: number, pag: any) {
    this.setPage(index);
  }

  active(index: number) {
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  onChangeNumber(pag: number){
    try {
      this.pagnum = pag;
      this.getAll(0);
    } catch (error) {

    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.pageChapter.totalPages - 1) {
      this.setPage(++this.currentSelectedPage);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.setPage(--this.currentSelectedPage);
    }
  }

}
