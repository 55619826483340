import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth.service';
import { DataService } from 'src/app/shared/DataService';
import { User } from 'src/app/shared/models';

@Component({
  selector: 'app-provision',
  templateUrl: './provision.component.html',
  styleUrls: ['./provision.component.css']
})
export class ProvisionComponent implements OnInit {

  usr: User;

  constructor(private ds: DataService, private auth: AuthService, private loc: Location, private alert: ToastrService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.usr = this.auth.getUser();
  }


  backClicked() {
    this.loc.back();
  }

}
