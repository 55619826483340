import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessChargeComponent } from './access-charge/access-charge.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfigurationRoutingModule } from './configuration-routing-module';
import { GeneralSetupComponent } from './general-setup/general-setup.component';
import { LicenceComponent } from './licence/licence.component';
import { LicenceNewComponent } from './licence-new/licence-new.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { AlertMessageNewComponent } from './alert-message-new/alert-message-new.component';
import { AlertMessageEditComponent } from './alert-message-edit/alert-message-edit.component';
import { AlertMessageListComponent } from './alert-message-list/alert-message-list.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ImportContactComponent } from './import-contact/import-contact.component';
import { MerchandConfigurationComponent } from './merchand-configuration/merchand-configuration.component';



@NgModule({
  declarations: [AccessChargeComponent, GeneralSetupComponent, LicenceComponent, LicenceNewComponent, AlertMessageComponent, AlertMessageNewComponent, AlertMessageEditComponent, AlertMessageListComponent, ImportContactComponent, MerchandConfigurationComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    UiSwitchModule,
    ConfigurationRoutingModule
  ],
  exports: [
    AccessChargeComponent,
    GeneralSetupComponent,
    LicenceComponent, 
    AlertMessageComponent,
    AlertMessageNewComponent, 
    AlertMessageEditComponent, 
    AlertMessageListComponent,
    MerchandConfigurationComponent
  ],
})
export class ConfigurationModule { }
