<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Settings</h5>
                    <span>This page allows admin to enter default app settings </span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">

                    <div class="col-sm-12">
                        <div class="card m-t-10">
                            <div class="card-header">

                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>Settings</h5>
                                    </div>
                                    <div class="col-lg-5">
                                        <!-- <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i></button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">
                                <form #setForm="ngForm">
                                    <ul class="nav nav-tabs md-tabs" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active show" data-toggle="tab" href="#home3" role="tab" aria-selected="true">General Settings</a>
                                            <div class="slide"></div>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-toggle="tab" href="#profile3" role="tab" aria-selected="false">SMS Configuration</a>
                                            <div class="slide"></div>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-toggle="tab" href="#messages3" role="tab" aria-selected="false">Payment</a>
                                            <div class="slide"></div>
                                        </li>
                                    </ul>

                                    <div class="tab-content card-block">

                                        <div class="tab-pane active show" id="home3" role="tabpanel">
                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Region</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="sttg.region" name="region" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">City</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="sttg.town" name="town" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Quarter</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="sttg.quarter" name="quarter" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Phone</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="sttg.phone" name="phone" class="form-control" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Email</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="sttg.email" name="email" class="form-control" required>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="tab-pane" id="profile3" role="tabpanel">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Message 1</label>
                                                        <div class="col-sm-9">
                                                            <textarea [(ngModel)]="sttg.msg1" name="msg1" class="form-control" rows="3" required></textarea>

                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Message 2</label>
                                                        <div class="col-sm-9">
                                                            <textarea [(ngModel)]="sttg.msg2" name="msg2" class="form-control" rows="3" required></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Message 3</label>
                                                        <div class="col-sm-9">
                                                            <textarea [(ngModel)]="sttg.msg3" name="msg3" class="form-control" rows="3" required></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Message 4</label>
                                                        <div class="col-sm-9">
                                                            <textarea [(ngModel)]="sttg.msg4" name="msg4" class="form-control" rows="3" required></textarea>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6">
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Message 5</label>
                                                        <div class="col-sm-9">
                                                            <textarea [(ngModel)]="sttg.msg5" name="msg5" class="form-control" rows="3" required></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Message 6</label>
                                                        <div class="col-sm-9">
                                                            <textarea [(ngModel)]="sttg.msg6" name="msg6" class="form-control" rows="3" required></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Message 7</label>
                                                        <div class="col-sm-9">
                                                            <textarea [(ngModel)]="sttg.msg7" name="msg7" class="form-control" rows="3" required></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Message 8</label>
                                                        <div class="col-sm-9">
                                                            <textarea [(ngModel)]="sttg.msg8" name="msg8" class="form-control" rows="3" required></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="tab-pane" id="messages3" role="tabpanel">
                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Phone MTN</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="sttg.phonemtn" name="phonemtn" class="form-control" required>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Phone Orange</label>
                                                <div class="col-sm-9">
                                                    <input type="text" [(ngModel)]="sttg.phoneorang" name="phoneorang" class="form-control" required>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Grace Periode (Days)</label>
                                                <div class="col-sm-9">
                                                    <input type="number" [(ngModel)]="sttg.gperiod" name="gperiod" class="form-control" required>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3 col-form-label">Mifi Fees Rate</label>
                                                <div class="col-sm-9">
                                                    <input type="number" [(ngModel)]="sttg.mifiFeeRate" name="mifiFee" class="form-control" required max="100" min="0">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <button *ngIf="canUpd" class="btn btn-mat waves-effect waves-light btn-inverse   float-right" [disabled]="!setForm.form.valid" (click)="onCreate()">Validate</button>
                                    <button *ngIf="!canUpd" class="btn btn-mat waves-effect waves-light btn-primary   float-right" [disabled]="!setForm.form.valid" (click)="onUpdate()">Validate</button>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>