import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { APIResponse, BankResponseType, RetroCommissionRequest, RetroCommissionResponse, RetroCommissionToPrint, User } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth.service';
import { ExportHelper } from 'src/app/shared/export.service';

declare var showLoader;

@Component({
  selector: 'app-retro-commission-module',
  templateUrl: './retro-commission-module.component.html',
  styleUrls: ['./retro-commission-module.component.css']
})
export class RetroCommissionModuleComponent implements OnInit {

  usr: User;
  request: RetroCommissionRequest
  coms: Array<RetroCommissionResponse>
  totlBal: number;
  totFees: number;
  pageOfItems?: Array<any>;
  bkId: string;
  selectedPeriod : string
  maxPeriod : string
  pdfHelper : ExportHelper;

  constructor(private loc: Location, private ds: DataService, private alert: ToastrService, private datePipe: DatePipe, private auth: AuthService) { }

  ngOnInit(): void {
    this.initView()
  }

  initView() {
    this.usr = {} as User;
    this.request = {} as RetroCommissionRequest
    this.coms = []
    this.totlBal = 0;
    this.totFees = 0;
    this.bkId = '';
    this.selectedPeriod = this.datePipe.transform(new Date().setDate(0), 'yyyy-MM')
    this.maxPeriod = this.datePipe.transform(new Date().setDate(0), 'yyyy-MM')
    this.usr = this.auth.getUser();
    this.pdfHelper = new ExportHelper(new DatePipe('en'));
    if (this.usr.roleId == 3 || this.usr.roleId == 4) {
      this.bkId = this.usr.bqId;
      this.request.userId = this.usr.id
      this.request.period = this.datePipe.transform(this.selectedPeriod, 'MM-yyyy')
      this.getRetroCom();
    }
  }

  getRetroCom(): void {
    showLoader(true);
    this.ds.post(`/retro-commissions/list`, this.request).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        if (res.body.data != null) {
          this.coms = res.body.data;
        this.totlBal = 0;
        this.totFees = 0;
        this.coms.forEach((x) => {
          this.totlBal += x.camcculFeeShare;
          this.totFees += x.mifiFeeShare;
        });
        } else {
          this.coms = [];
        this.totlBal = 0;
        this.totFees = 0;
        }
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onPeriodChange(value: string) {
    if (value != null) {
      this.selectedPeriod = this.datePipe.transform(value, 'yyyy-MM')
      this.request.userId = this.usr.id
      this.request.period = this.datePipe.transform(this.selectedPeriod, 'MM-yyyy')
      this.getRetroCom();
    }
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}

  exportExcel() {
    var clientList = [];
    this.coms.forEach(element => {
      clientList.push(new RetroCommissionToPrint(element));
    })
    this.pdfHelper.exportexcel(clientList, 'retro-commission');
  }

  generatePdf() {
    var clientList = [];
    this.coms = this.coms.sort((a,b) => Number(a.bankCode) - Number(b.bankCode))
    this.coms.forEach(element => {
        clientList.push(new RetroCommissionToPrint(element));
    })
    this.pdfHelper.openPDF('retro-commission', clientList, this.totFees, this.totlBal, this.usr.username, this.selectedPeriod);
  }

  backClicked() {
    this.loc.back();
  }

}
