<ul *ngIf="pager && pager.pages?.length" class="pagination justify-content-center">
    <li [ngClass]="{ disabled: pager.currentPage === 1 }" class="page-item">
        <a (click)="setPage(1)" class="page-link">First</a>
    </li>
    <li [ngClass]="{ disabled: pager.currentPage === 1 }" class="page-item">
        <a (click)="setPage(pager.currentPage - 1)" class="page-link">Previous</a>
    </li>
    <li *ngFor="let page of pager.pages" [ngClass]="{ active: pager.currentPage === page }" class="page-item">
        <a (click)="setPage(page)" class="page-link">{{page}}</a>
    </li>
    <li [ngClass]="{ disabled: pager.currentPage === pager.totalPages }" class="page-item">
        <a (click)="setPage(pager.currentPage + 1)" class="page-link">Next</a>
    </li>
    <li [ngClass]="{ disabled: pager.currentPage === pager.totalPages }" class="page-item">
        <a (click)="setPage(pager.totalPages)" class="page-link">Last</a>
    </li>
</ul>
