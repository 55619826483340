<section class="login-block d-flex align-items-center">
  <div class="container-fluid">
      <div class="row d-flex justify-content-center">
        <img src="assets/images/log111.png" alt="logo.png">
      </div>
      <div class="row">
          <div class="col-sm-12">
            <div class="page-header card">
              <div class="row align-items-end">
                  <div class="col-lg-12">
                      <div class="page-header-title text-center">
                          <div class="d-inline">
                              <h5 class="text-center">Change Default Password</h5>
                              <span class="text-center text-danger">The password you're currently using is unsecure. Please change it here.</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="pcoded-inner-content">
              <div class="main-body">
                  <div class="page-wrapper">
                      <div class="page-body">

                          <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col-sm-6">

                                  <div class="card">
                                      <div class="card-header text-center">
                                          <h5>Change Weak Password</h5>
                                      </div>
                                      <div class="card-block">
                                          <form>
                                              <div class="form-group row">
                                                  <label class="col-sm-3 col-form-label">Old Password</label>
                                                  <div class="col-sm-9">
                                                      <input type="password" [(ngModel)]="oldPwd" name="oldPwd" required class="form-control">
                                                  </div>
                                              </div>
                                              <div class="form-group row">
                                                  <label class="col-sm-3 col-form-label">New Password</label>
                                                  <div class="col-sm-9">
                                                      <input type="password" [(ngModel)]="newPwd" name="newPwd" required class="form-control" />
                                                      <div *ngIf="newPwd && !validatePassword()" class="text-danger text-sm">
                                                        Password must have a minimum length of 8 characters, one or more uppercase letters, one or more lowercase letters, one or more numbers, and one or more symbols.
                                                      </div>

                                                  </div>
                                              </div>
                                              <div class="form-group row">
                                                  <label class="col-sm-3 col-form-label">Repeat New Password</label>
                                                  <div class="col-sm-9">
                                                      <input type="password" [(ngModel)]="confirmPwd" name="confirmPwd" required class="form-control">
                                                      <div *ngIf="confirmPwd && !confirmationMatchesNewPasswordCheck()" class="text-danger text-sm">
                                                        The confirmation must match the new password.
                                                      </div>
                                                  </div>
                                              </div>

                                              <button class="btn btn-mat waves-effect waves-light btn-inverse   float-right" [disabled]="!newPwd || !oldPwd || !confirmPwd || !validatePassword() || !confirmationMatchesNewPasswordCheck()" (click)="onChangeDefaultPassword()">Validate</button>

                                          </form>
                                      </div>
                                  </div>


                              </div>
                              <div class="col-sm-3"></div>
                          </div>

                      </div>
                  </div>
              </div>
          </div>
          </div>

      </div>

  </div>
</section>
