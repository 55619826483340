export enum STATUS {
  OK = 200,
  SERVER_ERROR = 500,
  UNAUTHORISED = 401,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  FORBIDEN = 403,
  CONFLICT = 409,
  CHANGE_PASSWORD = 4232
}

export interface APIResponse {
  status: number;
  message?: string;
  data?: any;
}

export interface paymentObj{
  responseMessage: string;
  responseCode : string;
}

export interface MomoPayment{
  accountIdentifier: string;
  amount: string;
  providerIdentifier: string;
  referenceOrder: string;
}


export interface MenuItem {
  value: string;
  path?: string;
  text: string;
  children?: Array<MenuItem>;
}
export interface MegaMenu {
  value: string;
  text: string;
  icon: string;
  children: Array<Menu>;
}
export interface Menu {
  value: string;
  text: string;
  path?: string;
  children?: Array<MenuItem>;
}

export interface ResponsePolicy {
  Status: number;
  Error?: string;
  Data?: any;
}

export interface AuthModel {
  username: string;
  password: string;
  uuid: string;
  module: string;
}

export interface UserRole {
  id?: number;
  name: string;
  menu: string;
  privilege: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ChangePass {
  uId: number;
  oldPwd: string;
  newPwd: string;
  confirmPwd?: string;
}

export interface User {
  id: number;
  name: string;
  username: string;
  email: string;
  phone?: string;
  password: string;
  codchap: string;
  bqId: string;
  agccpt: string;
  token: string;
  secuKey: string;
  active: boolean;
  roleId: number;
  roles?: string;
  priv: string;
  roleName?: string;
  isLicenceActive: boolean;
  expiryDate: string;
  userAgencyLastSyncDate: string
  uuid: string
}

export interface Chapter {
  id: number;
  name: string;
  code: string;
  location: string;
}

export interface PageChapter {
  content: any
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: any;
  size: number;
  sort: any;
  totalElements: number;
  totalPages: number;
}

export interface PageBank {
  content: Array<Bank>;
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: any;
  size: number;
  sort: any;
  totalElements: number;
  totalPages: number;
}

export interface Bank {
  id: number;
  name: string;
  code: string;
  codeName?: string;
  abrv: string;
  chapterId: number;
  box: string;
  tel: string;
  tel2: string;
  email1: string;
  email2: string;
  headOffice: string;
  grEndDate: Date;
  type: string;
  feeAmount: number;
  loanPortfolioAmount: number;
}

export interface MerchantConfig {
  id: number,
  uuid: string,
  label: string,
  color: string,
  logo: string,
  code: number,
  merchantCode: string,
  merchantAccount?: string,
  secureHashCode: string,
  devise: string,
  provider: string,
  depositAvailable: boolean,
  withdrawalAvailable: boolean
}

export interface FeesConfig {
  id: number,
  operatorMtn: number,
  operatorOrange: number,
  provider: number,
  service: number
}

export interface UpdateFeeRequest {
  paymentConfig: MerchantConfig,
  feeConfig: FeesConfig
}

export interface BankResponseType {
  bank: Bank;
  chapter: Chapter;
  selected?: boolean;
}

export interface RetroCommissionRequest {
  userId: number;
  period: string;
}

export interface RetroCommissionResponse {
  period: string;
  bankCode: string;
  bankName: string
  loanTotal: number;
  feePaid: number;
  mifiFeeShare: number;
  camcculFeeShare: number;
}

export class RetroCommissionToPrint {

  period: string;
  bankCode: string;
  bankName: string
  totalLoan: number;
  feePaid: number;
  creditUnionShare: number;
  camcculFeeShare: number;

  constructor(obj: RetroCommissionResponse) {
    this.period = obj.period;
    this.bankCode = obj.bankCode;
    this.bankName = obj.bankName;
    this.totalLoan = obj.loanTotal;
    this.feePaid = obj.feePaid;
    this.creditUnionShare = obj.mifiFeeShare
    this.camcculFeeShare = obj.camcculFeeShare
  }
}

// export interface AgencyResponseType {
//   bank: Bank;
//   agency: Agency;
// }

export interface AgencyResponseType {
  id: number;
  code: String;
  tel: String;
  tel2: String;
  email: String;
  box: String;
  bank_id: number;
  loc: String;
  name: String;
  bank_name: String;
  BQID: String;
  last_sync: Date;
  tag: boolean;
  deleted: boolean;
}

export interface Agency {
  id: number;
  name: string;
  code: string;
  bqId: number;
  tel: string;
  tel2: string;
  email: string;
  box: string;
  loc: string;
  lastSync: Date;
  dateReg?: Date;
  tag?: boolean;
  selected?: boolean;
}

export interface SituationRequestType {
  bank: string;
  branch?: string;
  isBilling?: boolean;
  username?: string; //numcli dans la fonction approuveUnblacklist
  name?: string; // numdos dans la fonction approuveUnblacklist
  reason?: string;
  id?: number;
  from?: Date;
  to?: Date;
  user?: number;
}

export interface MonthSituation {
  typ: string;
  dat: Date;
}

export interface PayDetailResponse {
  BANK: string;
  TOTAL_LOANS: number;
  CHARGE: number;
  UNIT_CHARGE: number;
  START_DATE: Date;
  END_DATE: Date;

}

export interface Situation {
  id: number;
  bqId: string;
  branchId: string;
  chapterId: string;
  isBlocked: boolean;
  isPermanentBlocked: number;
  numcli: string;
  nomcli: string;
  numdos: string;
  memo: string;
  reasonPerBlck: string;
  approuver: string;
}

export interface AccessCharge {
  id: number;
  act?: string;
  amntStrt: number;
  amntEnd: number;
  amount: number;
}

export interface AppSetting {
  id: number;
  ammntValLimit: number;
  region: string;
  town: string;
  quarter: string;
  phone: string;
  email: string;
  msg1: string;
  msg2: string;
  msg3: string;
  msg4: string;
  msg5: string;
  msg6: string;
  msg7: string;
  msg8: string;
  phonemtn: string;
  phoneorang: string;
  gperiod: number;
  mifiFeeRate: number
}

export interface Loan {
  id: number;
  bqId: string;
  bqName?: string;
  agccpt: string;
  agcName?: string;
  numcli: string;
  nomcli: string;
  numdos: string;
  datedem: Date;
  motifdem: string;
  mtpret: number;
  periodic: string;
  garantie: string;
  datacc: Date;
  nbreche: number;
  nbecht: number;
}

export interface Client {
  id: number;
  bqId: string;
  codage: string;
  numcli: string;
  name: string;
  datebirth: Date;
  placebirth: string;
  sex: string;
  adrescli: string;
  city: string;
  quata: string;
  box: string;
  phone: string;
  income: Date;
  datereg: Date;
  cniclient: string;
  lieucni: string;
  datedelivcni: Date;
  searchparams: string;
}

export interface LoanResponseType {
  loan: Loan;
  client: Client;
}

export interface LoanBalanceResponseType {
  branch: string;
  balance: number;
}

export interface BlacklistReponseType {
  id: number;
  sit_id: number;
  client_name: string;
  num_cli: string;
  memo: string;
  numdos: string;
  bank_id: string;
  branch_id: string;
  is_blocked: boolean;
  is_permanent_block: number;
  reason_per_blck: string;
  unblock_motif: string;
  is_approved: string;
  admin_id: number;
  chapter_admin_id: number;
  date_unblocked: Date;
  time_unblocked: Date;
  admin_name: string;
  chapter_admin_name: string;
}

export interface ThreatHis {
  id: number;
  bqId: string;
  agccpt: string;
  numcli: string;
  nomcli: string;
  cni: string;
  phone: string;
  mtpret: number;
  montrest: number;
  retard: number;
  deliquency: string;
  numdos: string;
  unblackMotif: string;
  isAprrouved: boolean;
  adminId: number;
  chapterAdminId: number;
  dateUnblocked: Date;
  timeUnblocked: Date;
}

export interface Relation {
  id: number;
  bqId: string;
  codage: string;
  numcli: string;
  nombenef: string;
  relbenef: string;
  cnibenef: string;
}

export interface SitLoan {
  id: number;
  bqId: string;
  agccpt: string;
  numcli: string;
  nomcli: string;
  numdos: string;
  datacc: Date;
  mtpret: number;
  montrest: number;
  retard: number;
  valretard: number;
}

export interface SubSituationRequestType {
  numcli: string;
  BQID: string;
  AGCCPT: string;
  CNI: string;
  PHONE: string;
}

export interface SubSituationResponseType {
  bank: Bank;
  branch: Agency;
  client: Client;
  loan: Loan;
}

export interface SubSituation {
  id: number;
  BQID: string;
  AGCCPT: string;
  numcli: string;
  nomcli: string;
  NUMDOS: string;
  DATEDEM: Date;
  MOTIFDEM: string;
  MTPRET: number;
  garantie: string;
  periodic: string;
  Datacc: Date;
  nbecht: number;
  income: number;
  cniclient: string;
  phone: string;
  name: string;
  bank_name: string;
  branch_name: string;
}

export interface SituationDetailsResponse {
  situation: Situation;
  loan: Loan;
  relation: Array<Relation>;
  bank: Bank;
  branch: Agency;
  sitLoan: SitLoan;
  client: Client;
}

export interface Garantie {
  id: number;
  bqId: string;
  codage: string;
  codgar: string;
  nomaval: string;
  placebirth: string;
  datebirth: Date;
  phone: string;
  catpro: string;
  valeur: number;
  adraval: string;
  cidentite: string;
  datereg: Date;
  numcli: string;
  numdos: string;
  garanty: string;
  descript: string;
  etat: string;
}

export interface LoanAgeingResponseType {
  sitLoan: SitLoan;
  loan: Loan;
  client: Client;
  garantie: Garantie;
}

export interface Payment {
  id: number;
  bqId: string;
  payMethod: string;
  amntDue: number;
  amntPaid: number;
  loanBal: number;
  payPhone: string;
  memName: string;
  memPhone: string;
  uId: number;
  periodStrt: Date;
  periodEnd: Date;
  datereg: Date;
  timereg: Date;
  sts: string;
  bankName?: string;
  amntf?: number;
  discount?: number;
}

export interface MobileFees {
  amount: number
  operator: string
}

export interface BankPaymentRequest {
    id: number;
    bank_code : string;
    amount_paid: number;
    period_loan_balance: number;
    payment_period: string;
    payment_date: Date;
    payer_name: string;
    payer_phone: string;
    pay_method: string;
    user_id: number;
    sts: string;
    mobile_payment_number: string
    amount_with_fees: number
}

export interface PaymentReq {
  pay: Payment;
  prs: Array<PaymentResponse>;
}

export interface groupeBySite {
  payment: Array<PaymentResponse>
  site: string
  total: number
}

export interface AgencyBilling {
  branch: string;
  freq: number;
  TOTAL_AMOUNT: number;
  AGCCPT: string;
  BQID: string;
}

export interface BillingResponseType {
  bank: string;
  agc: Array<AgencyBilling>;

}

export interface BillingDetail {
  branch: string;
  FREQ: number;
  TOTAL_AMOUNT: number;
  act: string;
  username: string;
  name: string;
  bank: string;
}

export interface Operations {
  name: string;
  value: string;
}

export interface PermanentlyBlockedResponse {
  id: number;
  client_name: string;
  num_cli: string;
  memo: string;
  numdos: string;
  bank_id: string;
  branch_id: string;
  is_blocked: boolean;
  is_permanent_block: number;
  reason_per_blck: string;
  approver: string;
  bank_name: string;
  branch_name: string;
}

export interface SearchRequest {
  query: Array<string>;
  bank: string;
  branch: string;
  isBilling?: boolean;
  username?: string;
  name?: string;

}

export interface SearchClientResponse {
  clId: number;
  name: string;
  numcli: string;
  cni: string;
  bank: string;
  b_code: string;
  branch: string;
  branch_code: string;
  blackHis: string;
}

export interface LoanSearchResponse {

  numdos: string;
  datacc: Date;
  mtpret: number;
  montrest: number;
  retard: number;
  valretard: number;
  motifdem: string;
  garantee: string;
  client: SearchClientResponse;
  color: string;
}

export interface FinalSearchResult {

  loans: Array<LoanSearchResponse>;
  exec_time: number;
}

export interface PaymentResponse {
  chargeAmount: number;
  loan: LoanResponse
}

export interface PaymentResponseWithFees {
  paymentListResponse: PaymentResponse[]
  totalFees: number
  payableAmount: number
}

export interface LoanResponse {
  agccpt: string
bqId: string
dateacc: Date
datedem: string
garantie: string
id: number
motifdem: string
mtpret: number
nbecht: number
nbreche: number
nomcli: string
numcli: string
numdos: string
periodic: string
}

export class PaymentResponseToPrint {
  chargeAmount: number;
  agency: string
loanDate: string
garantie: string
loanMotif: string
loanAmount: number
clientName: string
clientNumber: string
periodicity: String

  constructor(obj: PaymentResponse) {
    this.agency = obj.loan.agccpt
    this.loanDate = obj.loan.datedem
    this.clientName = obj.loan.nomcli
    this.clientNumber = obj.loan.numcli
    this.loanAmount = obj.loan.mtpret
    this.periodicity = obj.loan.periodic
    this.garantie = obj.loan.garantie
    this.loanMotif = obj.loan.motifdem
    this.chargeAmount = obj.chargeAmount
  }

}

export class PaymentResponseToExcel {
  chargeAmount: number;
  agency: string
bankId: string
accountingDate: Date
loanDate: string
garantie: string
id: number
loanMotif: string
loanAmount: number
clientName: string
clientNumber: string
fileNumber: string
periodicity: string

  constructor(obj: PaymentResponse) {
    this.chargeAmount = obj.chargeAmount
    this.agency = obj.loan.agccpt
    this.bankId = obj.loan.bqId
    this.accountingDate = obj.loan.dateacc
    this.loanDate = obj.loan.datedem
    this.garantie = obj.loan.garantie
    this.id = obj.loan.id
    this.loanMotif = obj.loan.motifdem
    this.loanAmount = obj.loan.mtpret
    this.clientName = obj.loan.nomcli
    this.clientNumber = obj.loan.numcli
    this.fileNumber = obj.loan.numdos
    this.periodicity = obj.loan.periodic
  }

}

export interface BillingSummary {
  BANK: string;
  TOTAL_LOANS: number;
  CHARGE: number;
  START_DATE: Date;
  END_DATE: Date;
}

export interface ChapterBillingSummary {
  chapter: string;
  bills: Array<BillingSummary>;
  totLoan: number;
  totFees: number;
}

export interface logRequest {
  from: Date;
  to: Date;
}

export interface logRequestUser {
  from: Date;
  to: Date;
  name : string;
}

export interface AlertMessage {
  id: number;
  title: string;
  msg: string;
  state: boolean;
  createdAt: Date;
}

export interface SubSituation {

  id: number;
  BQID: string;
  AGCCPT: string;
  numcli: string;
  nomcli: string;
  NUMDOS: string;
  DATEDEM: Date;
  MOTIFDEM: string;
  MTPRET: number;
  garantie: string;
  periodic: string;
  Datacc: Date;
  nbecht: number;
  income: number;
  cniclient: string;
  phone: string;
  name: string;
  bank_name: string;
  branch_name: string;
}

export interface SearchReportRequest {
  query: Array<String>;
  lsrs: Array<LoanSearchResponse>;
}

export interface Numero {
  id: number;
  num: string;
  code: string;
  msg: string;
}

export interface NumeroReq {
  bNum: Array<Numero>;
  cNum: Array<Numero>;
}

export interface PaymentMonthResponse {
  bqId: string;
  bankName: string;
  payMethod: string;
  amntDue: number;
  amntPaid: number;
  loanBal: string;
  payPhone: string;
  memName: string;
  memPhone: string;
  uId: number;
  periodStrt: Date;
  periodEnd: Date;
  datereg: Date;
  timereg: Date;
}

export interface ChargesRequest {
  bqId: string
  monthYear: string
}