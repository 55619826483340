<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Chapters</h5>
                    <span>This page permit the user to update an existing chapter</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">

                        <div class="card">
                            <div class="card-header">
                                <h5>Edit Chapter</h5>
                                <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span>
                            </div>
                            <div class="card-block">
                                <form #chapForm="ngForm">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="name" [(ngModel)]="chap.name" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Code</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="code" [(ngModel)]="chap.code" required class="form-control" readonly>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Location</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="location" [(ngModel)]="chap.location" class="form-control">
                                        </div>
                                    </div>

                                    <button class="btn btn-mat waves-effect waves-light btn-inverse   float-right" [disabled]="!chapForm.form.valid" (click)="onUpdate()">Validate</button>

                                </form>
                            </div>
                        </div>


                    </div>
                    <div class="col-sm-1"></div>
                </div>

            </div>
        </div>
    </div>
</div>