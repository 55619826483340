import { Component, OnInit } from '@angular/core';
import { SituationDetailsResponse, SubSituationRequestType, SubSituationResponseType, SituationRequestType, Agency, Bank, Client, Loan, Relation, SitLoan, Situation, APIResponse, User } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-blacklist-detail',
  templateUrl: './blacklist-detail.component.html',
  styleUrls: ['./blacklist-detail.component.css']
})
export class BlacklistDetailComponent implements OnInit {

  sitId: number;
  sdrt: SituationDetailsResponse;
  menos: Array<String>;
  ssrt: SubSituationRequestType;
  ssrts: Array<SubSituationResponseType>;
  srt: SituationRequestType;
  usr: User;

  constructor(private ds: DataService, private route: ActivatedRoute, private loc: Location, private alert: ToastrService, private router: Router, private auth: AuthService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.sdrt = {} as SituationDetailsResponse;
    this.sdrt.branch = {} as Agency;
    this.sdrt.bank = {} as Bank;
    this.sdrt.client = {} as Client;
    this.sdrt.loan = {} as Loan;
    this.sdrt.relation = new Array<Relation>();
    this.sdrt.sitLoan = {} as SitLoan;
    this.sdrt.situation = {} as Situation;
    this.menos = new Array<String>();
    this.ssrt = {} as SubSituationRequestType;
    this.ssrts = new Array<SubSituationResponseType>();
    this.srt = {} as SituationRequestType;
    this.usr = this.auth.getUser();
    this.route.params.subscribe((param) => {
      this.sitId = param.id;
      this.getSituationById();
    });
  }

  backClicked() {
    this.loc.back();
  }

  getSituationById() {
    showLoader(true);
    this.ds.get(`/situations/${this.sitId}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.sdrt = res.body.data;
        this.menos = this.sdrt.situation.memo.split(',').filter(function (v) { return v !== '' });
        //console.log(this.sdrt);
        this.getBorrowerSubSituation();

      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getBorrowerSubSituation() {
    this.ssrt.numcli = this.sdrt.client.numcli;
    this.ssrt.PHONE = this.sdrt.client.phone;
    this.ssrt.CNI = this.sdrt.client.cniclient;
    this.ssrt.BQID = this.sdrt.situation.bqId;
    this.ssrt.AGCCPT = this.sdrt.situation.branchId;

    //console.log(this.ssrt);
    showLoader(true);
    this.ds.post(`/situations/sub-situation`, this.ssrt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.ssrts = res.body.data;
        //console.log(res.body.data);

      } else {
        //console.log(res.body.message);
        this.alert.error('SERVER ERROR', res.body.message);
        showLoader(false);
      }
    }, (err) => {
      showLoader(false);
      // console.log(err);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });

  }

  approuveUnBlocked() {
    this.srt.id = this.sitId;
    this.srt.user = this.auth.getUser().id;
    showLoader(true);
    this.ds.post(`/situations/approuveUnblacklist`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.alert.success('SUCCESS', 'YOUR REQUEST TO UNBLACKLIST MENBER WAS SUCCESSFUL');
        this.router.navigateByUrl('management/blacklist-history');
      } else {
        this.alert.error('SERVER ERROR', res.body.message);
        showLoader(false);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  approuveUnBlockedAdmin() {
    this.srt.id = this.sitId;
    this.srt.user = this.auth.getUser().id;
    showLoader(true);
    this.ds.post(`/situations/approuveUnblacklistAdmin`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.alert.success('SUCCESS', 'YOUR REQUEST TO UNBLACKLIST MENBER WAS SUCCESSFUL');
        this.router.navigateByUrl('management/blacklist-history');
      } else {
        this.alert.error('SERVER ERROR', res.body.message);
        showLoader(false);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

}
