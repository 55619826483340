import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { APIResponse, PageChapter, BankResponseType, Agency, SituationRequestType, Situation, BlacklistReponseType, User } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/printer.service';
import { AuthService } from 'src/app/shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-blacklist-hist',
  templateUrl: './blacklist-hist.component.html',
  styleUrls: ['./blacklist-hist.component.css']
})
export class BlacklistHistComponent implements OnInit {

  page: number;
  pageCredit: PageChapter;
  pages: Array<number>;
  srt: SituationRequestType;
  bls: Array<BlacklistReponseType>;
  filterBls: Array<BlacklistReponseType>;
  base64File: string;
  currentSelectedPage: number = 0;
  pag: number;
  pagnum: number;
  usr: User;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService, private auth: AuthService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.page = 0;
    this.pageCredit = {} as PageChapter;
    this.bls = new Array<BlacklistReponseType>();
    this.filterBls = new Array<BlacklistReponseType>();
    this.srt = {} as SituationRequestType;
    this.usr = this.auth.getUser();
    this.base64File = '';
    this.pages =[];
    this.pagnum = 20;
    this.onLoadData(0);
  }





  onLoadData(page: number) {
    showLoader(true);
    this.ds.get(`/situations/blacklist/${this.pagnum}?page=${page}`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.pageCredit = res.body.data;
        //console.log(this.pageCredit);
        this.pages = Array(this.pageCredit.totalPages).fill(0).map((x,i)=>i);
        this.bls = this.pageCredit.content;
        this.filterBls = this.pageCredit.content;
      } else {
        // console.log(res.body.message);
        this.alert.error('SERVER ERROR', res.body.message);
        showLoader(false);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      //console.log(err);
    });
  }

  setPage(i) {
    this.onLoadData(i);
  }

  getPaginationWithIndex(index: number, pag: any) {
    this.setPage(index);
  }

  active(index: number) {
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  onChangeNumber(pag: number){
    try {
      this.pagnum = pag;
      this.onLoadData(0);
    } catch (error) {

    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.pageCredit.totalPages - 1) {
      this.setPage(++this.currentSelectedPage);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.setPage(--this.currentSelectedPage);
    }
  }

  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterBls = this.bls.filter((x) => {
        return x.num_cli.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          x.client_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          x.admin_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          x.chapter_admin_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    } else {
      this.filterBls = this.bls;
    }
  }

  onUnblock(id: number) {
    showLoader(true)
    this.ds.delete('/situations/unblock/' + id)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.status === 200) {
          showLoader(false);
          this.initView();
        } else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
        }
      }, (err) => {
        // console.log(err);
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });

  }

  printBlacklistReport(): void {
    showLoader(true);
    this.ds.get(`/situations/reports/blacklist/list`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  backClicked() {
    this.loc.back();
  }

}
