<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Chapters</h5>
                    <span>This page list all existing chapter</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-12">
                        <button class="btn btn-mat waves-effect waves-light btn-primary" (click)="printChapterReport()"><i class="fa fa-print"></i>Print</button>
                        <button routerLink="/management/chapters/new" class="btn btn-mat waves-effect waves-light btn-info m-l-10" *ngIf="ro"><i class="fa fa-plus"></i>New Chapter</button>
                        <div class="card m-t-10">
                            <div class="card-header">
                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>List of Chapters </h5>
                                        &nbsp;&nbsp;&nbsp;
                                        <h5>Total: {{pageChapter.totalElements}}</h5>
                                    </div>
                                    <div class="col-lg-5">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <select #pag name="pag" class="form-control searchForm" (change)="onChangeNumber(pag.value)">
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="500">500</option>
                                                    <option value="0">ALL</option>
                                                </select>
                                            </div>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">
                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Code<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Name<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Location<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;" *ngIf="ro">Action<span class="fooicon fooicon-sort"></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let ch of filterChaps">
                                                <td class="footable-first-visible" style="display: table-cell;">{{ch.code}}</td>
                                                <td style="display: table-cell;">{{ch.name}}</td>
                                                <td style="display: table-cell;">{{ch.location}}</td>
                                                <td style="display: table-cell;" *ngIf="ro">
                                                    <a routerLink="/management/chapters/edit/{{ch.id}}" class="tabledit-edit-button btn btn-primary waves-effect waves-light"><span class="icofont icofont-ui-edit"></span></a>
                                                    <button type="button" (click)="onDelete(ch.id)" class="tabledit-delete-button btn btn-danger waves-effect waves-light m-l-10"><span class="icofont icofont-ui-delete"></span></button>
                                                </td>

                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr class="footable-paging">
                                                <td colspan="5">
                                                    <ul class="pagination ">
                                                        <li class="footable-page-nav" data-page="prev"><a class="footable-page-link" href="javascript:void(0)" (click)="previousClick()">Previous</a></li>
                                                        <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==currentSelectedPage}" data-page="1"><a (click)="getPaginationWithIndex(i, pagnum)" class="footable-page-link" href="javascript:void(0)">{{i}}</a></li>
                                                        <li class="footable-page-nav" data-page="next"><a class="footable-page-link" href="javascript:void(0)" (click)="nextClick()">Next</a></li>

                                                    </ul>
                                                    <div class="divider"></div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>






                                <!-- <div class="table-responsive">
                                    <table class="table table-xs">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Code</th>
                                                <th>Location</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let ch of filterChaps">
                                                <td>{{ch.name}}</td>
                                                <td>{{ch.code}}</td>
                                                <td>{{ch.location}}</td>
                                                <td>
                                                    <div class="tabledit-toolbar btn-toolbar" style="text-align: left;">
                                                        <div class="btn-group btn-group-sm" style="float: none;">
                                                            <button type="button" class="tabledit-edit-button btn btn-primary waves-effect waves-light" style="float: none;margin: 5px;"><span class="icofont icofont-ui-edit"></span></button>
                                                            <button type="button" class="tabledit-delete-button btn btn-danger waves-effect waves-light" style="float: none;margin: 5px;"><span class="icofont icofont-ui-delete"></span></button>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>