import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { ChangePassComponent } from "./change-pass/change-pass.component";
import { AccountRoutingModule } from "./account-routing-module";
import { FormsModule } from "@angular/forms";
import { PaymentComponent } from "./payment/payment.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { ChangeDefaultPasswordComponent } from "./change-default-password/change-default-password.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { OtpVerificationComponent } from "./otp-verification/otp-verification.component";
import { SetNewPasswordComponent } from "./set-new-password/set-new-password.component";
import { PaginationComponent } from "src/app/features/account/pagination/pagination.component";
import { RetroCommissionModuleComponent } from "./retro-commission-module/retro-commission-module.component";

@NgModule({
  declarations: [
    LoginComponent,
    ChangePassComponent,
    PaymentComponent,
    InvoiceComponent,
    ChangeDefaultPasswordComponent,
    ForgotPasswordComponent,
    OtpVerificationComponent,
    SetNewPasswordComponent,
    PaginationComponent,
    RetroCommissionModuleComponent
  ],
  imports: [CommonModule, AccountRoutingModule, FormsModule],
  exports: [
    LoginComponent,
    ChangePassComponent,
    PaymentComponent,
    InvoiceComponent,
    PaginationComponent
  ],
})
export class AccountModule {}
