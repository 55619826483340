import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/shared/DataService';
import { APIResponse } from 'src/app/shared/models';

declare var showLoader;

interface NewPasswordDTO {
  newPwd: string;
  confirmPwd: string;
}

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.css']
})
export class SetNewPasswordComponent implements OnInit {
  newPassword: string;
  passwordConfirmation: string;
  userUUID: string;
  newPasswordDTO : NewPasswordDTO
  passwordPattern: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+=-]).{8,}$/;


  constructor(
    private ds: DataService,
    private alert: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.onInit();

    //cleanup
    //localStorage.removeItem("setNewPassword");
    //localStorage.removeItem("UUID");
  }

  onInit() {
    this.newPassword = '';
    this.passwordConfirmation = '';
    this.newPasswordDTO = {} as NewPasswordDTO;
    this.userUUID = localStorage.getItem("userUUID");
  }

  onSubmitNewPassword() {
    if (this.newPassword != this.passwordConfirmation) {
      this.alert.error(
        "ERROR",
        "New password is different form password confirmation"
      );
      return;
    }

    this.newPasswordDTO.newPwd = btoa(this.newPassword);
    this.newPasswordDTO.confirmPwd = btoa(this.passwordConfirmation);

    showLoader(true);
    this.ds
      .put("/users/set-new-password/" + this.userUUID, this.newPasswordDTO)
      .subscribe({
        next: (res: HttpResponse<APIResponse>) => {
          if (res.body.status === 200) {
            showLoader(false);
            this.alert.success("SUCCESS", "NEW PASSWORD SET SUCCESSFULLY");
            this.router.navigateByUrl("/login");
          } else {
            showLoader(false);
            this.onInit();
            this.alert.error("WARNING", res.body.message);
          }
        },
        error: (err) => {
          showLoader(false);
          this.alert.error(
            "SERVER ERROR",
            "THERE WAS AN INTERNAL SERVER ERROR"
          );
        },
      });
  }

  validatePassword(): boolean {
    if (this.newPassword && this.passwordConfirmation.match(this.passwordPattern)) {
      return true;
    }
    return false;
  }

  confirmationMatchesNewPasswordCheck() {
    if (this.newPassword && this.passwordConfirmation && this.newPassword == this.passwordConfirmation) {
      return true;
    }
    return false;
  }

}
