import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { UsersComponent } from './users/users.component';
import { ChapterNewComponent } from './chapter-new/chapter-new.component';
import { ChapterEditComponent } from './chapter-edit/chapter-edit.component';
import { ChapterListComponent } from './chapter-list/chapter-list.component';
import { BrancheListComponent } from './branche-list/branche-list.component';
import { BrancheNewComponent } from './branche-new/branche-new.component';
import { BrancheEditComponent } from './branche-edit/branche-edit.component';
import { CreditListComponent } from './credit-list/credit-list.component';
import { CreditNewComponent } from './credit-new/credit-new.component';
import { CreditEditComponent } from './credit-edit/credit-edit.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserNewComponent } from './user-new/user-new.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { BlacklistHistComponent } from './blacklist-hist/blacklist-hist.component';
import { LoanAnalysisComponent } from './loan-analysis/loan-analysis.component';
import { UserRoleComponent } from './user-role/user-role.component';
import { HomeComponent } from './home/home.component';
import { LoanAnalysisDetailComponent } from './loan-analysis-detail/loan-analysis-detail.component';
import { SearchBorrowersComponent } from './search-borrowers/search-borrowers.component';
import { UnblockComponent } from './unblock/unblock.component';
import { PermanentBlockComponent } from './permanent-block/permanent-block.component';
import { BlacklistDetailComponent } from './blacklist-detail/blacklist-detail.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'search/result', component: SearchBorrowersComponent },
  { path: 'management/chapters', component: ChapterListComponent },
  { path: 'management/chapters/new', component: ChapterNewComponent },
  { path: 'management/chapters/edit/:id', component: ChapterEditComponent },
  { path: 'management/branches', component: BrancheListComponent },
  { path: 'management/branches/new', component: BrancheNewComponent },
  { path: 'management/branches/edit/:id', component: BrancheEditComponent },
  { path: 'management/credit-union', component: CreditListComponent },
  { path: 'management/credit-union/new', component: CreditNewComponent },
  { path: 'management/credit-union/edit/:id', component: CreditEditComponent },
  { path: 'management/users', component: UserListComponent },
  { path: 'management/users/new', component: UserNewComponent },
  { path: 'management/users/role', component: UserRoleComponent },
  { path: 'management/users/edit/:id', component: UserEditComponent },
  { path: 'management/blacklist-history', component: BlacklistHistComponent },
  { path: 'management/blacklist-history/detail/:id', component: BlacklistDetailComponent },
  { path: 'management/borrowers/unblock/:id', component: UnblockComponent },
  { path: 'management/borrowers/permanent-block/:id', component: PermanentBlockComponent },
  { path: 'management/loan-analysis', component: LoanAnalysisComponent },
  { path: 'management/loan-analysis/detail/:id', component: LoanAnalysisDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule,
    SharedModule
  ]
})
export class ManagementRoutingModule { }
