import { HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/shared/DataService";
import { APIResponse } from "src/app/shared/models";

declare var showLoader;

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  username: string;

  constructor(
    private ds: DataService,
    private alert: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.onInit();
    //cleanup
    localStorage.removeItem("forgotPassword");
  }

  onInit() {
    this.username = "";
  }

  onSubmitUsername() {
    showLoader(true);
    this.ds.post("/users/forgot/password", {"username": this.username}).subscribe({
      next: (res: HttpResponse<APIResponse>) => {
        showLoader(false);
        if (res.body.status === 200) {
          localStorage.setItem("otpVerification", "true");
          localStorage.setItem("username", this.username);
          this.router.navigateByUrl("/otp-verification");
        } else {
          this.onInit();
          this.alert.error("WARNING", res.body.message);
        }
      },
      error: (err) => {
        showLoader(false);
        this.alert.error("SERVER ERROR", "THERE WAS AN INTERNAL SERVER ERROR");
      },
    });
  }
}
