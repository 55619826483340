import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { AuthService } from 'src/app/shared/auth.service';
import { Payment, User, APIResponse, BankResponseType, PaymentResponse, PaymentReq, MomoPayment, ChargesRequest, BankPaymentRequest, MobileFees, Bank, PaymentResponseWithFees } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { Printer } from 'src/app/shared/printer.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/app/shared/payment.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material';

declare var showLoader;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  pay: Payment;
  momo: MomoPayment;
  usr: User;
  banks: Array<BankResponseType>;
  fbank: BankResponseType;
  prs: PaymentResponseWithFees;
  payreq: PaymentReq;
  chargesRequest: ChargesRequest
  uchrg: number = 0;
  showDate = false;
  showList = false;
  base64File: string;
  periodicity: Array<string> = []
  currentYear = (new Date()).getFullYear()
  selectedPeriod = this.datePipe.transform(new Date(), 'MM-yyyy')
  paymentRequest: BankPaymentRequest
  mobileFeesRequest: MobileFees
  selectedBank: Bank

  constructor(private ds: DataService, private auth: AuthService, private loc: Location, private alert: ToastrService, private payementService : PaymentService, private datePipe: DatePipe, public matDialog: MatDialog) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.pay = {} as Payment;
    this.momo = {} as MomoPayment;
    this.usr = {} as User;
    this.banks = new Array<BankResponseType>();
    this.fbank = {} as BankResponseType;
    this.prs = {} as PaymentResponseWithFees;
    this.payreq = {} as PaymentReq;
    this.payreq.pay = {} as Payment;
    this.chargesRequest = {} as ChargesRequest;
    this.payreq.prs = new Array<PaymentResponse>();
    this.paymentRequest = {} as BankPaymentRequest
    this.mobileFeesRequest = {} as MobileFees
    this.usr = this.auth.getUser();
    this.showDate = false;
    this.showList = false;
    this.base64File = '';
    this.selectedBank = {} as Bank
    this.getbanks();
    this.getMonthList('en').forEach(element => {
      this.periodicity.push(element + ' ' + this.currentYear)
    })
    if (this.usr.roleId != 2 && this.usr.roleId == 3) {
      this.chargesRequest.bqId = this.usr.bqId
      this.chargesRequest.monthYear = this.selectedPeriod
      console.log(this.chargesRequest)
      this.getCharges(this.chargesRequest);
      this.showList = true;
    }
  }

  getMonthList(
    locales?: string | string[],
    format: "long" | "short" = "long"
  ): string[] {
    const year = new Date().getFullYear(); // 2020
    const monthList = [...Array(12).keys()]; // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    const formatter = new Intl.DateTimeFormat(locales, {
      month: format
    });
  
    const getMonthName = (monthIndex: number) =>
      formatter.format(new Date(year, monthIndex));
  
    return monthList.map(getMonthName);
  }

  convertStringToDate(dateString: string): Date | null {
    const [month, year] = dateString.split('-');
    const parsedMonth = parseInt(month, 10) - 1; // Adjust month for zero-based indexing
    return new Date(Number(year), parsedMonth, 1); // Set day to 1 to avoid issues with different month lengths
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.banks = res.body.data;
        if (this.usr.roleId !== 1 && this.usr.roleId !== 2 && this.usr.roleId !== 5) {
          this.banks = this.banks.filter(bank => bank.bank?.code === this.usr.bqId);
        }
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getCharges(request: ChargesRequest): void {
    showLoader(true);
    this.ds.post(`/loans/payment-loans/list`, request).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        console.log(res.body.data);
        this.prs = res.body.data;
        this.pay.amntDue = 0;
        this.pay.loanBal = 0;
        if (this.prs.paymentListResponse.length > 0) {
          this.pay.amntDue = this.prs.payableAmount;
          this.uchrg = this.prs.totalFees;
          this.pay.amntPaid = 0;
          this.pay.loanBal = 0;
          this.prs.paymentListResponse.forEach(pr => {
            //this.pay.amntDue += Number(pr.chargeAmount);
            // this.uchrg += Number(pr.UNIT_CHARGE);
            this.pay.loanBal += Number(pr.loan.mtpret);
          });
        }
        this.pay.amntf = this.pay.amntDue;
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      this.showList = false;
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getMobilePaymentFees(): void {
    showLoader(true);
    this.mobileFeesRequest.amount = this.pay.amntf
    this.mobileFeesRequest.operator = this.pay.payMethod
    this.ds.post(`/payments/bankPayments/calculateMobilePaymentAmount`, this.mobileFeesRequest).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        console.log(res.body.data);
        this.paymentRequest.amount_with_fees = this.pay.amntf + res.body.data
        var provider = this.pay.payMethod == 'O' ? 'om' : 'momo'
      Swal.fire({
        title: `<h4>You are about to perform an online payment for your bill of ${this.datePipe.transform(this.convertStringToDate(this.selectedPeriod), 'MMMM yyyy', '', 'en')}. With services fees, the total amount deducted from your account will be ${this.paymentRequest.amount_with_fees} FCFA.</h4>`,
        html: `<br> <label class="form-check-label" for="inlineRadio1"> Enter your mobile payment number: <img style="object-fit: scale-down; width: 7%; margin-left: 10px;" src="assets/images/${provider}.jpg" alt=""></label> <br>`,
        input: 'text',
        inputPlaceholder: 'eg: 690000000',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Pay",
        preConfirm: async (number) => {
          try {
            this.paymentRequest.amount_paid = this.pay.amntDue
            this.paymentRequest.mobile_payment_number = number
            console.log(this.paymentRequest)
            showLoader(true)
            this.onPaymentCash()
            //return response.json();
          } catch (error) {
            Swal.showValidationMessage(`
              Request failed: ${error}
            `);
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      this.showList = false;
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onCreate() {
    if (this.pay.payMethod === 'C') {
      this.pay.payPhone = null;
      this.pay.sts = 'V';
    } else {
      this.pay.sts = 'P';
    }
    this.pay.uId = this.auth.getUser().id;

    // this.prs.forEach((x) => {
    //   // x.START_DATE = this.pay.periodStrt
    //   // x.END_DATE = this.pay.periodEnd;
    // })

    this.payreq.pay = this.pay;
    this.payreq.prs = this.prs.paymentListResponse;

    showLoader(true);
    //console.log(this.payreq);
    if(this.pay.payMethod !== 'C'){
      showLoader(false);
      this.getMobilePaymentFees()

      // this.momo.accountIdentifier = ('237' + this.pay.payPhone).toString();
      // this.momo.amount = this.pay.amntPaid.toString();
      // this.momo.referenceOrder =  "12236543874";

      // if(this.pay.payMethod === 'M'){
      //   this.momo.providerIdentifier = '62401';
      // }else if(this.pay.payMethod === 'O'){
      //   this.momo.providerIdentifier = '62402';
      // }

      // //console.log(this.momo);
      // this.payementService.payInitialDeposit(this.momo).subscribe((res: paymentObj) => {
      //   if (res.responseCode === "1") {
      //     showLoader(false);
      //     this.licenceCreate(this.payreq);
      //     this.alert.success('SUCCESS', res.responseMessage);
      //   } else {
      //     //console.log(res.body.message);
      //     showLoader(false);
      //     this.alert.error('ERROR', res.responseMessage);
      //   }
      // }, (err) => {
      //   showLoader(false);
      //    this.alert.error('SERVER ERROR', err.message);
      // });
    }else{
      this.onPaymentCash()
      // this.licenceCreate(this.payreq);
    }
  }

  licenceCreate(payment : PaymentReq){
    this.ds.post('/licences/new', payment).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //this.alert.success('SUCCESS', 'PROCEDURE\'S DETAILS SAVE SUCCESSFULLY');
        this.initView();
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
      } else {
        //console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
       this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
    });
  }

  onPaymentCash() {
    this.paymentRequest.id = null
    this.paymentRequest.bank_code = this.pay.bqId
    this.paymentRequest.amount_paid = this.pay.amntDue
    if (this.selectedBank.type == 'INTERNAL') {
      this.paymentRequest.period_loan_balance = this.pay.loanBal
    }
    this.paymentRequest.payment_period = this.selectedPeriod
    this.paymentRequest.payment_date = new Date()
    this.paymentRequest.payer_name = this.pay.memName
    this.paymentRequest.payer_phone = this.pay.memPhone
    this.paymentRequest.pay_method = this.pay.payMethod,
    this.paymentRequest.user_id = this.auth.getUser().id
    this.paymentRequest.sts = 'V'
    console.log(this.paymentRequest)
    this.ds.post('/payments/new-bank-payment', this.paymentRequest).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //this.alert.success('SUCCESS', 'PROCEDURE\'S DETAILS SAVE SUCCESSFULLY');
        this.initView();
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
      } else if (res.body.status === 202) {
        showLoader(false);
        this.alert.warning('WARNING', 'A PAYMENT HAS ALREADY BEEN MADE FOR THIS AGENCY AND THIS PERIOD');
      } else {
        //console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
       this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
    }); 
  }

  onPayMethodChange(method: string) {
    if (method === 'M' || method === 'O') {
      this.chargesRequest.bqId = this.auth.getUser().bqId
      this.getCharges(this.chargesRequest);
    } else {
      this.prs = undefined;
    }
  }

  onCreditUnionChange(value: string) {
    if (value != null) {
      this.pay.amntPaid = null;
      this.pay.amntf = null;
      this.pay.discount = null;
      this.showDate = false;
      this.showList = true;
      this.chargesRequest.bqId = value
      this.chargesRequest.monthYear = this.selectedPeriod
      var selected = this.banks.filter(item => {
        return item.bank.code == value
      })
      this.selectedBank = selected[0].bank
      if (this.selectedBank.type == 'INTERNAL') {
        this.getCharges(this.chargesRequest);
      }
    }

  }

  onPeriodChange(value: string) {
    if (value != null) {
      this.pay.amntPaid = null;
      this.pay.amntf = null;
      this.pay.discount = null;
      this.showDate = false;
      this.showList = true;
      this.selectedPeriod = this.datePipe.transform(new Date(value), 'MM-yyyy')
      this.chargesRequest.bqId = this.pay.bqId
      this.chargesRequest.monthYear = this.selectedPeriod
      if (this.selectedBank.type == 'INTERNAL') {
        this.getCharges(this.chargesRequest);
      }
    }
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  calcDaysOnAmnt(unit_amnt, amnt_pay) {
    return (amnt_pay * 30) / unit_amnt;
  }

  getDuration() {
    if (this.pay.amntPaid != null) {
      this.showDate = true;
      var date = new Date();
      // this.pay.periodStrt = this.prs[0].START_DATE == null ? date : this.prs[0].START_DATE;
      // let days = this.getDateDiff(this.pay.periodStrt, new Date(this.prs[0].END_DATE));
      // let ndays = (this.pay.amntPaid * Number(days)) / this.pay.amntDue;
      // //console.log(ndays);
      // this.pay.periodEnd = this.addDays(this.pay.periodStrt, Math.round(ndays));
    } else {
      this.showDate = false;
    }

    if (this.usr.roleId != 1) {
      this.pay.amntf = this.pay.amntPaid;
    }
  }

  onTotalLoanChange() {
    if (this.paymentRequest.period_loan_balance != null && this.paymentRequest.period_loan_balance >= 0) {
      this.pay.amntDue = Math.round(this.paymentRequest.period_loan_balance * this.selectedBank.feeAmount)
      this.pay.amntf = this.pay.amntDue
    }
  }

  onDiscount() {
    if (this.pay.amntPaid != null && this.pay.discount != null) {
      this.pay.amntf = Math.round(this.pay.amntDue - ((this.pay.discount / 100) * this.pay.amntDue))
      if(this.pay.discount != 0){
        this.pay.amntPaid = this.pay.amntf;
        this.getDuration();
      }
    } else {
      this.pay.amntf = null;
    }
  }

  getDateDiff(date1: Date, date2: Date): number {
    let diff = Math.abs(new Date(date1).getTime() - new Date(date2).getTime());
    //console.log(Math.ceil(diff / (60 * 60 * 24 * 1000)));
    return Math.ceil(diff / (60 * 60 * 24 * 1000));
  }

  backClicked() {
    this.loc.back();
  }

}
