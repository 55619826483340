import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SharedModule } from "src/app/shared/shared.module";
import { LoginComponent } from "./login/login.component";
import { ChangePassComponent } from "./change-pass/change-pass.component";
import { PaymentComponent } from "./payment/payment.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { ChangeDefaultPasswordComponent } from "./change-default-password/change-default-password.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { OtpVerificationComponent } from "./otp-verification/otp-verification.component";
import { SetNewPasswordComponent } from "./set-new-password/set-new-password.component";
import { RetroCommissionModuleComponent } from "./retro-commission-module/retro-commission-module.component";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  {
    path: "change-default-password",
    component: ChangeDefaultPasswordComponent,
  },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "otp-verification", component: OtpVerificationComponent },
  { path: "set-new-password", component: SetNewPasswordComponent },
  { path: "account/change-pass", component: ChangePassComponent },
  { path: "account/payment", component: PaymentComponent },
  { path: "account/invoice", component: InvoiceComponent },
  // { path: "account/retro-commission", component: RetroCommissionModuleComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, SharedModule],
})
export class AccountRoutingModule {}
