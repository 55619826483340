import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { User, BankResponseType, APIResponse, Payment, BillingSummary, Chapter, ChapterBillingSummary } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { AuthService } from 'src/app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import { Printer } from 'src/app/shared/printer.service';

declare var showLoader;

@Component({
  selector: 'app-billing-simul',
  templateUrl: './billing-simul.component.html',
  styleUrls: ['./billing-simul.component.css']
})
export class BillingSimulComponent implements OnInit {

  pay: Payment;
  usr: User;
  banks: Array<BankResponseType>;
  fbanks: Array<BankResponseType>;
  fbank: BankResponseType;
  prs: Array<BillingSummary>;
  cprs: ChapterBillingSummary;
  cprss: Array<ChapterBillingSummary>;
  uchrg: number = 0;
  base64File: string;
  totlBal: number;
  totFees: number;
  bkId: string;
  chId: number;
  chIdv: string;
  isAll: boolean;
  isAllC: boolean;
  typ: boolean;
  chaps: Array<Chapter>;

  constructor(private ds: DataService, private auth: AuthService, private loc: Location, private alert: ToastrService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.pay = {} as Payment;
    this.usr = {} as User;
    this.banks = new Array<BankResponseType>();
    this.fbanks = new Array<BankResponseType>();
    this.fbank = {} as BankResponseType;
    this.prs = new Array<BillingSummary>();
    this.cprs = {} as ChapterBillingSummary;
    this.cprss = new Array<ChapterBillingSummary>();
    this.chaps = new Array<Chapter>();
    this.isAll = false;
    this.isAllC = false;
    this.typ = true;
    this.totlBal = 0;
    this.totFees = 0;
    this.usr = this.auth.getUser();
    this.base64File = '';
    this.bkId = '';
    this.chIdv = '';
    this.getbanks();
    this.getChapters();
  }

  emptyvar() {
    this.prs = new Array<BillingSummary>();
    this.cprs = {} as ChapterBillingSummary;
    this.cprss = new Array<ChapterBillingSummary>();
    this.totlBal = 0;
    this.totFees = 0;
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.banks = res.body.data;
        this.fbanks = this.banks.filter((x) => {
          return x.chapter?.code.toLowerCase() === this.usr.codchap.toLowerCase();
        });


        if(this.usr.bqId != null) {
          this.fbank = this.banks.find((x) => {
            return x.bank?.code.toLowerCase() === this.usr.bqId.toLowerCase();
          });
        }

       // this.getBranches();
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getChapters(): void {
    showLoader(true);
    this.ds.get(`/chapters/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.chaps = res.body.data;
        if (this.usr.roleId === 2) {
          this.chaps = this.chaps.filter(chap => chap.code === this.usr.codchap);
        }
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  billingSummary(bqId: string): void {
    showLoader(true);
    this.ds.get(`/licences/biling-summary/${bqId}`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.prs = res.body.data;
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  chapterBillingSummary(chId: number): void {
    showLoader(true);
    this.ds.get(`/licences/chapter-biling-summary/${chId}`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.cprs = res.body.data;
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  billingSummaries(): void {
    showLoader(true);
    this.ds.get(`/licences/biling-summaries`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.prs = res.body.data;
        this.totlBal = 0;
        this.totFees = 0;
        this.prs.forEach((x) => {
          this.totlBal += Number(x.TOTAL_LOANS);
          this.totFees += Number(x.CHARGE);
        });
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  chapterBillingSummaries(): void {
    showLoader(true);
    this.ds.get(`/licences/chapter-biling-summaries`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.cprss = res.body.data;
        this.totlBal = 0;
        this.totFees = 0;
        this.cprss.forEach((x) => {
          this.totlBal += Number(x.totLoan);
          this.totFees += Number(x.totFees);
        });
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onCreditUnionChange(value: string) {
    if (value == 'ALL') {
      this.isAll = true;
      this.billingSummaries();
      this.bkId = value;
    } else if (value != null) {
      this.isAll = false;
      this.bkId = value;
      this.billingSummary(value);
    } else {
    }

  }

  onChapterChange(value: string) {
    let chap: number;
    chap = Number(value);
    if (chap == 0) {
      this.isAllC = true;
      this.chapterBillingSummaries();
      this.chId = chap;
      this.chIdv = 'ALL';
    } else if (chap != null) {
      this.isAllC = false;
      this.chId = chap;
      this.chIdv = 'ONE';
      this.chapterBillingSummary(chap);
    } else {
    }
  }

  chapterBillingSummaryReport() {
    showLoader(true);
    this.ds.get(`/licences/chapter-biling-summary/reports/${this.chId}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        // console.log(res.body.data);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
    });
  }

  chapterBillingSummariesReport() {
    showLoader(true);
    this.ds.get(`/licences/chapter-biling-summaries/reports`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        // console.log(res.body.data);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
    });
  }


  billingSummaryReport(): void {
    //console.log('here');
    showLoader(true);
    this.ds.get(`/licences/biling-summary/reports/${this.bkId}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        // console.log(res.body.data);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
    });
  }

  billingSummariesReport(): void {
    //console.log('here');
    showLoader(true);
    this.ds.get(`/licences/biling-summaries/reports`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        // console.log(res.body.data);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
    });
  }


  backClicked() {
    this.loc.back();
  }

}
