<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Payement Situation</h5>
                    <span>This page allows specific user to view Month or Annual situation</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-12">
                        <form #queryForm="ngForm">
                            <div class="row" *ngIf="usr.roleId == 1 || usr.roleId==6">
                                <div class="col-sm-3">
                                    <div class="form-group">
                                        <label class="col-form-label">Report Type</label>
                                        <select class="form-control" [(ngModel)]="srt.typ" name="typ">
                                            <option value="M">Monthly</option>
                                            <option value="A">Year</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <div class="form-group">
                                        <label class="col-form-label">Date</label>
                                        <input type="month" [(ngModel)]="dt" name="dt" class="form-control">
                                    </div>
                                </div>


                                <div class="col-sm-3">
                                    <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 m-t-35" (click)="onLoadData(0)" [disabled]="!queryForm.form.valid">Load Data</button>

                                    <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="printPaymentReportList()" [disabled]="!queryForm.form.valid"><i class="fa fa-print"></i>Print</button>
                                </div>

                            </div>
                        </form>


                        <div class="card m-t-10">
                            <div class="card-header">
                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>Payment Situation</h5>
                                    </div>
                                    <div class="col-lg-5">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <select #pag name="pag" class="form-control searchForm" (change)="onChangeNumber(pag.value)" [disabled]="!srt.typ">
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="500">500</option>
                                                    <option value="0">ALL</option>
                                                </select>
                                            </div>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">

                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Credit Union<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Paid Amount<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Start Period<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">End Period<span class="fooicon fooicon-sort"></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let p of filterLrts">
                                                <td class="footable-first-visible" style="display: table-cell;">{{p.BANK}}</td>
                                                <td style="display: table-cell;">{{p.UNIT_CHARGE | number}}</td>
                                                <td style="display: table-cell;">{{p.START_DATE | date}}</td>
                                                <td style="display: table-cell;">{{p.END_DATE | date}}</td>
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr class="footable-paging">
                                                <td colspan="5">
                                                    <ul class="pagination ">
                                                        <li class="footable-page-nav" data-page="prev"><a class="footable-page-link" href="javascript:void(0)" (click)="previousClick()">Previous</a></li>
                                                        <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==currentSelectedPage}" data-page="1"><a (click)="getPaginationWithIndex(i, pagnum)" class="footable-page-link" href="javascript:void(0)">{{i}}</a></li>
                                                        <li class="footable-page-nav" data-page="next"><a class="footable-page-link" href="javascript:void(0)" (click)="nextClick()">Next</a></li>

                                                    </ul>
                                                    <div class="divider"></div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>