<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Alert Message</h5>
                    <span>This page permit the user to create new alert message</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">

                        <div class="card">
                            <div class="card-header">
                                <h5>New Alert Message</h5>
                                <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span>
                            </div>
                            <div class="card-block">
                                <form #chapForm="ngForm">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Title</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="title" [(ngModel)]="alrt.title" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Message</label>
                                        <div class="col-sm-9">
                                            <textarea name="msg" class="form-control" required [(ngModel)]="alrt.msg" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <button class="btn btn-mat waves-effect waves-light btn-inverse   float-right" [disabled]="!chapForm.form.valid" (click)="onCreate()">Validate</button>

                                </form>
                            </div>
                        </div>


                    </div>
                    <div class="col-sm-1"></div>
                </div>

            </div>
        </div>
    </div>
</div>