<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Loan Portfolio</h5>
                    <span>This page list all existing Loan Portfolio by Credit Union and branche</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-12">

                        <div class="card m-t-40">
                            <div class="card-block">
                                <div class="row" *ngIf="usr.roleId == 1 || usr.roleId==6">
                                    <div class="col-sm-1"></div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Credit Union</label>

                                            <select name="bank" #bank class="form-control" (change)="onSelectBank(bank.value)">
                
                                                        <option value="0">&nbsp;</option>
                                                        <option *ngFor="let b of banks" value="{{b.bank.id}}">{{b.bank.name}}</option>
                                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Branch</label>
                                            <select [(ngModel)]="srt.branch" name="branch" class="form-control" [disabled]="!showBranch">
                                                        <option value="">&nbsp;</option>
                                                        <option *ngFor="let s of sbranchs" value="{{s.code}}">{{s.name}}</option>
                                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <!-- <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 m-t-35" (click)="onLoadData(0); getLoansBalances()" [disabled]=" !srt.bank">Load Data</button> -->

                                        <button (click)="printLoanPortofolioReport()" class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" [disabled]="!srt.branch || !srt.bank"><i class="fa fa-print"></i>Print</button>
                                    </div>
                                </div>

                                <div class="row" *ngIf="usr.roleId == 2">
                                    <div class="col-sm-1"></div>
                                    <div class=" col-sm-4">
                                        <div class="form-group ">
                                            <label class="col-form-label ">Credit Union</label>

                                            <select name="bank " #bank class="form-control" (change)="onSelectBank(bank.value)">
                
                                                        <option value="0">&nbsp;</option>
                                                        <option *ngFor="let b of fbanks" value="{{b.bank.id}} ">{{b.bank.name}}</option>
                                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-4 ">
                                        <div class="form-group ">
                                            <label class="col-form-label ">Branch</label>
                                            <select [(ngModel)]="srt.branch" name="branch" class="form-control " [disabled]="!showBranch">
                                                        <option value=" ">&nbsp;</option>
                                                        <option *ngFor="let s of sbranchs" value="{{s.code}} ">{{s.name}}</option>
                                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-2 ">
                                        <!-- <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 mt " (click)="onLoadData(0); getLoansBalances() " [disabled]="!srt.branch || !srt.bank ">Load Data</button> -->

                                        <button (click)="printLoanPortofolioReport()" class="btn btn-mat waves-effect waves-light btn-primary m-l-30 mt " [disabled]="!srt.branch || !srt.bank "><i class="fa fa-print "></i>Print</button>
                                    </div>
                                </div>

                                <div class="row " *ngIf="usr.roleId !=2 && usr.roleId==3 ">
                                    <div class="col-sm-3"></div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Branch</label>
                                            <select [(ngModel)]="srt.branch" name="branch" class="form-control ">
                                                        <option value=" ">&nbsp;</option>
                                                        <option *ngFor="let s of sebranchs" value="{{s.code}} ">{{s.name}}</option>
                                                        </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-2 ">
                                        <!-- <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 mt " (click)="onLoadData(0); getLoansBalances() " [disabled]="!srt.branch">Load Data</button> -->

                                        <button (click)="printLoanPortofolioReport()" class="btn btn-mat waves-effect waves-light btn-primary m-l-30 mt" [disabled]="!srt.branch"><i class="fa fa-print "></i>Print</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- <div class="card-block col-sm-6">
                            <div class="card bg-light">
                                <div class="card-body">
                                    <p class="card-text f-14 f-w-900"> Balance For Query Parameters: <span class="text-danger">XAF</span> {{balance | number}}</p>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="card">
                            <div class="card-header">
                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>List of Loans</h5>
                                        &nbsp;&nbsp;&nbsp;
                                        <h5>Total: {{pageCredit.totalElements}}</h5>
                                    </div>
                                    <div class="col-lg-5">
                                        <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)">
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">

                                <table id="demo-foo-filtering" class="table table-striped table-responsive footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                    <thead>
                                        <tr class="footable-header table-inverse">
                                            <th class="footable-sortable footable-first-visible" style="display: table-cell;">Credit Union<span class="fooicon fooicon-sort"></span></th>
                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Branch<span class="fooicon fooicon-sort"></span></th>
                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Menber<span class="fooicon fooicon-sort"></span></th>
                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Name<span class="fooicon fooicon-sort"></span></th>
                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">File N°<span class="fooicon fooicon-sort"></span></th>
                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Loan Date<span class="fooicon fooicon-sort"></span></th>
                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Date Issued<span class="fooicon fooicon-sort"></span></th>
                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Motif<span class="fooicon fooicon-sort"></span></th>
                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Loan Amnt<span class="fooicon fooicon-sort"></span></th>
                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Installments<span class="fooicon fooicon-sort"></span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lr of filterLrts">
                                            <td class="footable-first-visible" style="display: table-cell;">{{lr.loan.bqId}}</td>
                                            <td style="display: table-cell;">{{lr.loan.agccpt}}</td>
                                            <td style="display: table-cell;">{{lr.loan.numcli}}</td>
                                            <td style="display: table-cell;">{{lr.loan.nomcli}}</td>
                                            <td style="display: table-cell;">{{lr.loan.numdos}}</td>
                                            <td style="display: table-cell;">{{lr.loan.datedem | date}}</td>
                                            <td style="display: table-cell;">{{lr.loan.dateacc | date}}</td>
                                            <td style="display: table-cell;">{{lr.loan.motifdem}}</td>
                                            <td style="display: table-cell;">{{lr.loan.mtpret | number}}</td>
                                            <td style="display: table-cell;">{{lr.loan.nbreche | number}}</td>
                                        </tr>

                                    </tbody>
                                    <tfoot>
                                        <tr class="footable-paging">
                                            <td colspan="5">
                                                <ul class="pagination">
                                                    
                                                    <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==page}" data-page="1"><a (click)="setPage(i, $event)" class="footable-page-link" href="#">{{i}}</a></li>
                                                  
                                                </ul>
                                                <div class="divider"></div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>

                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>