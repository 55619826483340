<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Billing Summary</h5>
                    <span>This page allows specific user to preview bills summary</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-8">
                        <div class="row">
                            <!-- this block is for Credit Union End -->
                            <div class=" col-sm-4" *ngIf="usr.roleId==1 && !typ">
                                <div class="form-group">
                                    <label class="col-form-label">Credit Union</label>

                                    <select #bank class="form-control" (change)="onCreditUnionChange(bank.value)">
                                        <option value="">&nbsp;</option>
                                        <option value="ALL">ALL</option>
                                        <option *ngFor="let b of banks" value="{{b.bank.code}}">{{b.bank.name}}</option>
                                    </select>

                                </div>
                            </div>

                            <div class=" col-sm-4" *ngIf="usr.roleId==6 && !typ">
                                <div class="form-group">
                                    <label class="col-form-label">Credit Union</label>

                                    <select #bank class="form-control" (change)="onCreditUnionChange(bank.value)">
                                        <option value="">&nbsp;</option>
                                        <option value="ALL">ALL</option>
                                        <option *ngFor="let b of banks" value="{{b.bank.code}}">{{b.bank.name}}</option>
                                    </select>

                                </div>
                            </div>

                            <div class=" col-sm-4" *ngIf="usr.roleId==2 && !typ">
                                <div class="form-group">
                                    <label class="col-form-label">Credit Union</label>

                                    <select #bank class="form-control" (change)="onCreditUnionChange(bank.value)">
                                        <option value="ALL">SELECT ALL</option>
                                        <option *ngFor="let b of fbanks" value="{{b.bank.code}}">{{b.bank.name}}</option>
                                    </select>

                                </div>
                            </div>

                            <div class="col-sm-3" *ngIf="!typ">
                                <!-- <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 m-t-35" (click)="onLoadData(0)">Fetch Balances</button> -->

                                <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="billingSummaryReport()" *ngIf="!isAll" [disabled]="!bkId"><i class="fa fa-print"></i>Print</button>

                                <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="billingSummariesReport()" *ngIf="isAll" [disabled]="!bkId"><i class="fa fa-print"></i>Print</button>
                            </div>

                            <!-- this block is for Credit Union End -->

                            <!-- this block is for chapters Start -->
                            <div class=" col-sm-4" *ngIf="usr.roleId==1  && typ">
                                <div class="form-group">
                                    <label class="col-form-label">Chapter</label>

                                    <select #bank class="form-control" (change)="onChapterChange(bank.value)">
                                        <option value="">&nbsp;</option>
                                        <option value="0">ALL</option>
                                        <option *ngFor="let c of chaps" value="{{c.id}}">{{c.name}}</option>
                                    </select>

                                </div>
                            </div>

                            <div class=" col-sm-4" *ngIf="usr.roleId==6  && typ">
                                <div class="form-group">
                                    <label class="col-form-label">Chapter</label>

                                    <select #bank class="form-control" (change)="onChapterChange(bank.value)">
                                        <option value="">&nbsp;</option>
                                        <option value="0">ALL</option>
                                        <option *ngFor="let c of chaps" value="{{c.id}}">{{c.name}}</option>
                                    </select>

                                </div>
                            </div>

                            <div class=" col-sm-4" *ngIf="usr.roleId==2 && typ">
                                <div class="form-group">
                                    <label class="col-form-label">Chapter</label>

                                    <select #bank class="form-control" (change)="onChapterChange(bank.value)">
                                        <option value="0">SELECT YOUR CHAPTER</option>
                                        <option *ngFor="let c of chaps" value="{{c.id}}">{{c.name}}</option>
                                    </select>

                                </div>
                            </div>

                            <div class="col-sm-3" *ngIf="typ">
                                <!-- <button class="btn btn-mat waves-effect waves-light btn-info m-l-20 m-t-35" (click)="onLoadData(0)">Fetch Balances</button> -->

                                <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="chapterBillingSummaryReport()" *ngIf="!isAllC" [disabled]="!chIdv"><i class="fa fa-print"></i>Print</button>

                                <button class="btn btn-mat waves-effect waves-light btn-primary m-l-30 m-t-35" (click)="chapterBillingSummariesReport()" *ngIf="isAllC" [disabled]="!chIdv"><i class="fa fa-print"></i>Print</button>
                            </div>


                            <!-- this block is for chapters End -->


                            <div class="col-sm-5" style="text-align: center;display: flex;align-items: center;">
                                <div class="form-radio m-t-20" *ngIf="usr.roleId==1">
                                    <div class="radio radio-inline" (click)="emptyvar()">
                                        <label>
                                                <input type="radio" name="typ" checked [(ngModel)]="typ" [value]="true">
                                                <i class="helper"></i>By Chapter
                                            </label>
                                    </div>
                                    <div class="radio radio-inline" (click)="emptyvar()">
                                        <label>
                                                <input type="radio" name="typ" [(ngModel)]="typ" [value]="false">
                                                <i class="helper"></i>By Credit Union
                                            </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card m-t-10">
                            <div class="card-block">
                                <div class="table-responsive" *ngIf="!typ">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Name<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Periode<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable text-right" style="display: table-cell;">Loan Balance<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable text-right" style="display: table-cell;">Fee<span class="fooicon fooicon-sort"></span></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let pr of prs">
                                                <td class="footable-first-visible" style="display: table-cell;">{{pr.BANK}}</td>
                                                <td style="display: table-cell;">{{pr.START_DATE | date}} - {{pr.END_DATE | date}}</td>
                                                <td style="display: table-cell;" class="text-right">{{pr.TOTAL_LOANS | number}}</td>
                                                <td style="display: table-cell;" class="text-right">{{pr.CHARGE | number}}</td>
                                            </tr>

                                            <tr *ngIf="isAll">
                                                <td colspan="2" class="footable-first-visible" style="display: table-cell;" class="f-w-700">TOTAL</td>
                                                <td style="display: table-cell;" class="text-right f-w-700">{{totlBal | number}}</td>
                                                <td style="display: table-cell;" class="text-right f-w-700">{{totFees | number}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div class="table-responsive" *ngIf="typ && !isAllC">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Name<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Periode<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable text-right" style="display: table-cell;">Loan Balance<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable text-right" style="display: table-cell;">Fee<span class="fooicon fooicon-sort"></span></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="table-active text-center">
                                                <td colspan="4" class="footable-first-visible" style="display: table-cell;" class="f-w-700">{{cprs.chapter}}</td>

                                            </tr>
                                            <tr *ngFor="let bi of cprs.bills">
                                                <td class="footable-first-visible" style="display: table-cell;">{{bi.BANK}}</td>
                                                <td style="display: table-cell;">{{bi.START_DATE | date}} - {{bi.END_DATE | date}}</td>
                                                <td style="display: table-cell;" class="text-right">{{bi.TOTAL_LOANS | number}}</td>
                                                <td style="display: table-cell;" class="text-right">{{bi.CHARGE | number}}</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="footable-first-visible" style="display: table-cell;" class="f-w-700">TOTAL</td>
                                                <td style="display: table-cell;" class="text-right f-w-700">{{cprs.totLoan | number}}</td>
                                                <td style="display: table-cell;" class="text-right f-w-700">{{cprs.totFees | number}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div class="table-responsive" *ngIf="typ && isAllC">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Name<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Periode<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable text-right" style="display: table-cell;">Loan Balance<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable text-right" style="display: table-cell;">Fee<span class="fooicon fooicon-sort"></span></th>

                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let cpr of cprss">
                                            <tr class="table-active text-center">
                                                <td colspan="4" class="footable-first-visible" style="display: table-cell;" class="f-w-700">{{cpr.chapter}}</td>

                                            </tr>
                                            <tr *ngFor="let bi of cpr.bills">
                                                <td class="footable-first-visible" style="display: table-cell;">{{bi.BANK}}</td>
                                                <td style="display: table-cell;">{{bi.START_DATE | date}} - {{bi.END_DATE | date}}</td>
                                                <td style="display: table-cell;" class="text-right">{{bi.TOTAL_LOANS | number}}</td>
                                                <td style="display: table-cell;" class="text-right">{{bi.CHARGE | number}}</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="footable-first-visible" style="display: table-cell;" class="f-w-700">TOTAL</td>
                                                <td style="display: table-cell;" class="text-right f-w-700">{{cpr.totLoan | number}}</td>
                                                <td style="display: table-cell;" class="text-right f-w-700">{{cpr.totFees | number}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr class="table-info" style="color: black;">
                                                <td colspan="2" class="footable-first-visible" style="display: table-cell;" class="f-w-700">GRAND TOTAL</td>
                                                <td style="display: table-cell;" class="text-right f-w-700">{{totlBal | number}}</td>
                                                <td style="display: table-cell;" class="text-right f-w-700">{{totFees | number}}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>
