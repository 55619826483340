<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Branches</h5>
                    <span>This page list all existing Credit Union Branches</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-12">
                        <button (click)="printBranchReport()" class="btn btn-mat waves-effect waves-light btn-primary"><i class="fa fa-print"></i>Print</button>
                        <button routerLink="/management/branches/new" class="btn btn-mat waves-effect waves-light btn-info m-l-10" *ngIf="ro"><i class="fa fa-plus"></i>New Branch</button>
                        <div class="card m-t-10">
                            <div class="card-header">

                                <div class="row align-items-end">
                                    <div class="col-lg-8">
                                        <h5>List of CU Branches</h5>
                                        &nbsp;&nbsp;
                                        <h5>Total: {{pageCredit.totalElements}}</h5>
                                        &nbsp;&nbsp;
                                        <h5>Synced: {{numberOfSyncedAgencies}}</h5>
                                        &nbsp;&nbsp;
                                        <h5>UnSynced: {{numberOfUnsyncedAgencies}}</h5>
                                        &nbsp;&nbsp;
                                        <h5>Never Synced: {{numberOfNeverSyncedAgencies}}</h5>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <select #pag name="pag" class="form-control searchForm" (change)="onChangeNumber(pag.value)">
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="500">500</option>
                                                    <option value="0">ALL</option>
                                                </select>
                                            </div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">

                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Credit Union<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Branch<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Code<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Last Sync Date<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Phone<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Location<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Sync Status<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;" *ngIf="ro">Action<span class="fooicon fooicon-sort" *ngIf="ro"></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let cr of filterBranches">
                                                <td class="footable-first-visible" style="display: table-cell;">{{cr.bank_name}}</td>
                                                <td style="display: table-cell;">{{cr.name}}</td>
                                                <td style="display: table-cell;">{{cr.code}}</td>
                                                <td style="display: table-cell;" *ngIf="cr.last_sync != null">{{cr.last_sync | date}}</td>
                                                <td style="display: table-cell;" *ngIf="cr.last_sync == null">No Data</td>
                                                <td style="display: table-cell;">{{cr.tel ? cr.tel : "Unavailable"}}</td>
                                                <td style="display: table-cell;">{{cr.loc ? cr.loc : "Unavailable"}}</td>

                                                <td style="display: table-cell;" *ngIf="calculateDaysDifference(cr.last_sync) <= 7 && cr.last_sync != null"><span class="label bg-success">
                                                <i class="fa fa-circle text-success"></i></span>
                                                </td>
                                                <td style="display: table-cell;" *ngIf="calculateDaysDifference(cr.last_sync) > 7 && cr.last_sync != null">
                                                    <span class="label bg-warning"><i class="fa fa-circle text-warning"></i></span>
                                                </td>
                                                <td style="display: table-cell;" *ngIf="cr.last_sync == null">
                                                    <span class="label bg-danger">
                                                  <i class="fa fa-circle text-danger"></i>
                                                </span>
                                                </td>

                                                <td style="display: table-cell;" *ngIf="ro">
                                                    <a routerLink="/management/branches/edit/{{cr.id}}" class="tabledit-edit-button btn btn-primary waves-effect waves-light"><span class="icofont icofont-ui-edit"></span></a>

                                                    <button *ngIf="!cr.deleted" type="button" (click)="onLock(cr.id)" class="tabledit-lock-button btn btn-warning waves-effect waves-light m-l-10"><span class="icofont icofont-ui-unlock"></span></button>

                                                    <button *ngIf="cr.deleted" type="button" (click)="onUnlock(cr.id, cr)" class="tabledit-unlock-button btn btn-secondary waves-effect waves-light m-l-10"><span class="icofont icofont-ui-lock"></span></button>

                                                    <button type="button" (click)="onDelete(cr.id)" class="tabledit-delete-button btn btn-danger waves-effect waves-light m-l-10"><span class="icofont icofont-ui-delete"></span></button>
                                                </td>

                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr class="footable-paging">
                                                <td colspan="5">
                                                    <ul class="pagination ">
                                                        <li class="footable-page-nav" data-page="prev"><a class="footable-page-link" href="javascript:void(0)" (click)="previousClick()">Previous</a></li>
                                                        <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==currentSelectedPage}" data-page="1"><a (click)="getPaginationWithIndex(i, pagnum)" class="footable-page-link" href="javascript:void(0)">{{i}}</a></li>
                                                        <li class="footable-page-nav" data-page="next"><a class="footable-page-link" href="javascript:void(0)" (click)="nextClick()">Next</a></li>

                                                    </ul>
                                                    <div class="divider"></div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
