<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Payment</h5>
                    <span>This page allows specific user to pays bills</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-2"></div>
                    <div class="card m-t-10 col-8">
                        <div class="card-header">
                            <h5>New Payment</h5>
                            <!-- <div style="align-items: flex-end; float: right; height: auto; width: inherit;">
                                <img *ngIf="pay.payMethod === 'M'" style="object-fit: scale-down; width: 15%; float: right;" src="assets/images/momo.jpg" alt="">
                                <img *ngIf="pay.payMethod === 'O'" style="object-fit: scale-down; width: 15%; float: right;" src="assets/images/om.jpg" alt="">
                                <img *ngIf="pay.payMethod === 'C'" style="object-fit: scale-down; width: 15%; float: right;" src="assets/images/cash.jpg" alt="">
                            </div> -->
                        </div>
                        <div class="card-block">
                            <form #payForm="ngForm">

                                <div class="row">
                                    <div class="col-8"></div>
                                    <div class="form-group col-4" *ngIf="pay.payMethod === 'C' || 'O' || 'M' ">
                                        <label class="form-control-label">Credit Union</label>
                                        <select name="bank " [(ngModel)]="pay.bqId" #bank class="form-control " (change)="onCreditUnionChange(bank.value) ">
                                            <!-- <option value="NoValue"></option> -->
                                            <option *ngFor="let b of banks " [value]="b.bank.code">{{b.bank.name}}</option>
                                            </select>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-form-label">Period</label>
                                    <select class="form-control" [(ngModel)]="selectedPeriod" #period (change)="onPeriodChange(period.value)">
                                            <option *ngFor="let period of periodicity" [value]="period"> {{period}}</option>
                                    </select>
                                </div>

                                <!-- <div class="form-group" *ngIf="pay.payMethod === 'M' || pay.payMethod === 'O'">
                                    <label class="form-control-label">C.U. Phone number for payment (without the +237)</label>
                                    <input type="tel" class="form-control" name="payPhone" [(ngModel)]="pay.payPhone" [required]="pay.payMethod === 'M' || pay.payMethod === 'O'" />
                                </div> -->

                                <!-- <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-form-label">Total Amount Due</label>
                                            <input type="number" [(ngModel)]="pay.amntDue" name="amntDue" readonly class="form-control">

                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-form-label">Amount to Deposit</label>
                                            <input type="number" [(ngModel)]="pay.amntPaid" name="amntPaid" (keyup)="getDuration()" required class="form-control">
                                            <span class="help-block" *ngIf="showDate">{{pay.periodStrt | date}} <b
                                                class="text-primary">to</b> {{pay.periodEnd | date}}</span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row" *ngIf="usr.roleId == 1">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-form-label">Discount (%)</label>
                                            <input [disabled]="!pay.amntPaid" type="number" [(ngModel)]="pay.discount" name="discount" class="form-control" (keyup)="onDiscount()">

                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-form-label">Final Amount</label>
                                            <input readonly type="number" [(ngModel)]="pay.amntf" name="amntf" required class="form-control">

                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="usr.roleId != 1">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-form-label">Final Amount</label>
                                            <input readonly type="number" [(ngModel)]="pay.amntf" name="amntf" required class="form-control">

                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group ">
                                            <label class="col-form-label">Representative</label>
                                            <input type="text" name="memName" [(ngModel)]="pay.memName" required class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group ">
                                            <label class="col-form-label">Phone Number</label>
                                            <input type="tel" name="memPhone" [(ngModel)]="pay.memPhone" required class="form-control">
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="selectedBank.type != 'INTERNAL'">
                                    <div class="col-sm-6">
                                        <div class="form-group ">
                                            <label class="col-form-label">Total Loan Portfolio </label>
                                            <input type="number" name="loanBalance" [(ngModel)]="paymentRequest.period_loan_balance" required class="form-control" (keyup)="onTotalLoanChange()">
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="selectedBank.type == 'INTERNAL'">
                                    <div class="card m-t-10 col-12">
                                        <div class="card-header">
                                            <div class="row align-items-end">
                                                <div class="col-lg-7">
                                                    <h5>Total: {{uchrg}} FCFA</h5>
                                                </div>
                                                <div class="col-lg-5">
                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-block">
                                            <div class="table-responsive">
                                                <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                                    <thead>
                                                        <tr class="footable-header table-inverse">
                                                            <th class="footable-sortable footable-first-visible" style="display: table-cell;">Credit Union<span class="fooicon fooicon-sort"></span></th>
                                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Branch<span class="fooicon fooicon-sort"></span></th>
                                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Member<span class="fooicon fooicon-sort"></span></th>
                                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Name<span class="fooicon fooicon-sort"></span></th>
                                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">File N°<span class="fooicon fooicon-sort"></span></th>
                                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Loan Date<span class="fooicon fooicon-sort"></span></th>
                                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Motif<span class="fooicon fooicon-sort"></span></th>
                                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Loan Amount<span class="fooicon fooicon-sort"></span></th>
                                                            <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Charge Amount<span class="fooicon fooicon-sort"></span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let pr of prs.paymentListResponse">
                                                            <td class="footable-first-visible" style="display: table-cell;">{{pr.loan.bqId}}</td>
                                                            <td style="display: table-cell;">{{pr.loan.agccpt}}</td>
                                                            <td style="display: table-cell;">{{pr.loan.numcli}}</td>
                                                            <td style="display: table-cell;">{{pr.loan.nomcli}}</td>
                                                            <td class="footable-first-visible" style="display: table-cell;">{{pr.loan.numdos}}</td>
                                                            <td style="display: table-cell;">{{pr.loan.datedem}}</td>
                                                            <td style="display: table-cell;">{{pr.loan.motifdem}}</td>
                                                            <td style="display: table-cell;">{{pr.loan.mtpret}}</td>
                                                            <td style="display: table-cell;">{{pr.chargeAmount}}</td>
                                                        </tr>
                    
                                                    </tbody>
                    
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                    <div class="form-group">
                                        <label class="col-form-label"> Payment Method</label> <br>
                                            <div class="form-check form-check-inline col-sm-4">
                                                <input
                                                *ngIf="usr.roleId == 1 || usr.roleId == 3 || usr.roleId == 4"
                                                  mdbRadio
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio1"
                                                  value="M"
                                                  [(ngModel)]="pay.payMethod"
                                                />
                                                <label class="form-check-label" for="inlineRadio1">MTN MOBILE MONEY <img style="object-fit: scale-down; width: 15%; margin-left: 10px;" src="assets/images/momo.jpg" alt=""></label>
                                              </div>
                                              <div class="form-check form-check-inline col-sm-4">
                                                <input
                                                *ngIf="usr.roleId == 1 || usr.roleId == 3 || usr.roleId == 4"
                                                  mdbRadio
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio2"
                                                  value="O"
                                                  [(ngModel)]="pay.payMethod"
                                                />
                                                <label class="form-check-label" for="inlineRadio2">ORANGE MONEY <img style="object-fit: scale-down; width: 15%; margin-left: 10px;" src="assets/images/om.jpg" alt=""></label>
                                              </div>
                                              <div class="form-check form-check-inline col-sm-4">
                                                <input
                                                *ngIf="usr.roleId == 1 || usr.roleId == 5"
                                                  mdbRadio
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio3"
                                                  value="C"
                                                  [(ngModel)]="pay.payMethod"
                                                />
                                                <label class="form-check-label" for="inlineRadio3">CASH <img style="object-fit: scale-down; width: 15%; margin-left: 10px;" src="assets/images/cash.jpg" alt=""></label>
                                              </div>
                                    </div>

                                <button [disabled]="!payForm.form.valid || pay.amntf <= 0" class="btn btn-mat waves-effect waves-light btn-inverse   float-right" (click)="onCreate()">Validate</button>
                            </form>
                        </div>
                    </div>
                    <div class="col-2"></div>
                </div>

            </div>
        </div>
    </div>
</div>
