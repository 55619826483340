import { Injectable } from '@angular/core';
import { CanLoad, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {

  constructor(private auth: AuthService,
    private router: Router) {
  }

  canLoad(
    route: import('@angular/router').Route,
    segments: import('@angular/router').UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {

      if (this.auth.getUser() != null && this.auth.getUser() !== undefined) {
        return true;
      } else if (localStorage.getItem("defaultPassword") == "true") {
        this.router.navigate(['/change-default-password']);
      } else {
        this.router.navigate(['/login']);
        return false;
      }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.getUser() != null && this.auth.getUser() !== undefined) {
      return true;
    } else if (localStorage.getItem("defaultPassword") == "true") {
      this.router.navigate(['/change-default-password']);
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url} });
      return false;
    }
  }
}
