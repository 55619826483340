import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/DataService';
import { Chapter, APIResponse, PageChapter, UserRole } from 'src/app/shared/models';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/printer.service';
import { AuthService } from 'src/app/shared/auth.service';
import { TrameUtilService } from 'src/app/shared/trame-util.service';

declare var showLoader;

@Component({
  selector: 'app-chapter-list',
  templateUrl: './chapter-list.component.html',
  styleUrls: ['./chapter-list.component.css']
})
export class ChapterListComponent implements OnInit {

  page: number;
  ur: UserRole;
  pageChapter: PageChapter;
  pages: Array<number>;
  chaps: Array<Chapter>;
  filterChaps: Array<Chapter>;
  base64File: string;
  ro: boolean;
  currentSelectedPage: number = 0;
  pag: number;
  pagnum: number;
 

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService, private auth: AuthService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.page = 0;
    this.pageChapter = {} as PageChapter;
    this.ur = {} as UserRole;
    this.chaps = new Array<Chapter>();
    this.filterChaps = new Array<Chapter>();
    this.base64File = '';
    this.pages =[];
    this.pagnum = 20;
    this.ro = false;
    this.getChapters(0);
    this.getRoles(this.auth.getUser().roleId);
  }

  getChapters(page: number): void {
    showLoader(true);
    this.ds.get(`/chapters/list/${this.pagnum}?page=${page}`).subscribe((res: HttpResponse<APIResponse>) => {

      if (res.body.status === 200) {
        this.pageChapter = res.body.data;
        //console.log(res.body.data);
        this.pages = Array(this.pageChapter.totalPages).fill(0).map((x,i)=>i);
        this.chaps = this.pageChapter.content;
        this.filterChaps = this.pageChapter.content;
        showLoader(false);
      }
      else {
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getRoles(id: number): void {
    showLoader(true);
    this.ds.get(`/roles/${id}`).subscribe((res: HttpResponse<APIResponse>) => {

      if (res.body.status === 200) {
        this.ur = res.body.data;
        showLoader(false);
        //console.log(this.ur);
        if (this.ur.privilege.toLowerCase() == 'RW'.toLowerCase()) {
          this.ro = true;
        }
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterChaps = this.chaps.filter((chap) => {
        return chap.name.toLowerCase().includes(query.toLowerCase())||
          chap.code.toLowerCase().includes(query.toLowerCase()) ||
          chap.location.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.filterChaps = this.chaps;
    }
  }

  setPage(i) {
    this.getChapters(i);
  }

  getPaginationWithIndex(index: number, pag: any) {
    this.setPage(index);
  }

  active(index: number) {
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  onChangeNumber(pag: number){
    try {
      this.pagnum = pag;
      this.getChapters(0);
    } catch (error) {
      
    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.pageChapter.totalPages - 1) {
      this.setPage(++this.currentSelectedPage);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.setPage(--this.currentSelectedPage);
    }
  }
  onDelete(id: number) {
    showLoader(true)
    this.ds.delete('/chapters/delete/' + id)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.body.status === 200) {
          showLoader(false);
          this.alert.success('SUCCESS', 'CHAPTER DELETED SUCCESSFULLY');
          this.initView();
        } else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
          //console.log(res.body.message);
        }
      }, (err) => {
        //console.log(err);
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });

  }

  printChapterReport(): void {
    //console.log('here');
    showLoader(true);
    this.ds.get(`/chapters/reports/list`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  backClicked() {
    this.loc.back();
  }

}
