import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DataService } from 'src/app/shared/DataService';
import { ActivatedRoute } from '@angular/router';
import { SituationDetailsResponse, SituationRequestType, Agency, Bank, Client, Loan, Relation, SitLoan, Situation, APIResponse, SubSituationResponseType, SubSituationRequestType, SubSituation } from 'src/app/shared/models';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Component({
  selector: 'app-loan-analysis-detail',
  templateUrl: './loan-analysis-detail.component.html',
  styleUrls: ['./loan-analysis-detail.component.css']
})
export class LoanAnalysisDetailComponent implements OnInit {

  sitId: number;
  sdrt: SituationDetailsResponse;
  menos: Array<String>;
  ssrt: SubSituationRequestType;
  ssrts: Array<SubSituation>;

  constructor(private ds: DataService, private route: ActivatedRoute, private loc: Location, private alert: ToastrService) {
    this.initView();

  }

  ngOnInit(): void {
  }

  initView() {
    this.sdrt = {} as SituationDetailsResponse;
    this.sdrt.branch = {} as Agency;
    this.sdrt.bank = {} as Bank;
    this.sdrt.client = {} as Client;
    this.sdrt.loan = {} as Loan;
    this.sdrt.relation = new Array<Relation>();
    this.sdrt.sitLoan = {} as SitLoan;
    this.sdrt.situation = {} as Situation;
    this.menos = new Array<String>();
    this.ssrt = {} as SubSituationRequestType;
    this.ssrts = new Array<SubSituation>();
    this.route.params.subscribe((param) => {
      this.sitId = param.id;
      this.getSituationById();
    });

  }

  backClicked() {
    this.loc.back();
  }

  backClick() {
    this.loc.back();
  }

  getSituationById() {
    showLoader(true);
    this.ds.get(`/situations/${this.sitId}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.sdrt = res.body.data;
        this.menos = this.sdrt.situation.memo.split(',').filter(function (v) { return v !== '' });
        this.getBorrowerSubSituation();
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getBorrowerSubSituation() {
    this.ssrt.numcli = this.sdrt.client.numcli;
    this.ssrt.PHONE = this.sdrt.client.phone;
    this.ssrt.CNI = this.sdrt.client.cniclient;
    this.ssrt.BQID = this.sdrt.situation.bqId;
    this.ssrt.AGCCPT = this.sdrt.situation.branchId;

   // console.log(this.ssrt);
    showLoader(true);
    this.ds.post(`/situations/sub-situation`, this.ssrt).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
     // console.log(res.body);
      if (res.body.status === 200) {
        this.ssrts = res.body.data;
      } else {
        //console.log(res.body.message);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
     // console.log(err);
    });

  }

}
