import { HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/shared/DataService";
import { APIResponse } from "src/app/shared/models";

declare var showLoader;

@Component({
  selector: "app-otp-verification",
  templateUrl: "./otp-verification.component.html",
  styleUrls: ["./otp-verification.component.css"],
})
export class OtpVerificationComponent implements OnInit {
  otpCode: string;
  username: string;

  constructor(
    private ds: DataService,
    private alert: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.onInit();

    //cleaup
    localStorage.removeItem("otpVerification");
  }

  onInit() {
    this.otpCode = "";
    this.username = localStorage.getItem("username");
  }

  onSubmitOtpCode() {
    showLoader(true);
    this.ds.post("/users/verify/otp", { "username": this.username, "otp": this.otpCode, "context": "FORGOT_PASSWORD" }).subscribe({
      next: (res: HttpResponse<APIResponse>) => {
        showLoader(false);
        if (res.body.status === 200) {
          let data = res?.body?.data;
          localStorage.setItem("setNewPassword", "true");
          localStorage.setItem("userUUID", data.uuid);
          this.router.navigateByUrl("/set-new-password");
        } else {
          this.onInit();
          this.alert.error("WARNING", res.body.message);
        }
      },
      error: (err) => {
        showLoader(false);
        this.alert.error("SERVER ERROR", "THERE WAS AN INTERNAL SERVER ERROR");
      },
    });
  }
}
