import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PageChapter, User, UserRole, APIResponse, BankResponseType, Agency, Bank } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/printer.service';
import { AuthService } from 'src/app/shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  page: number;
  pageUser: PageChapter;
  pages: Array<number>;
  users: Array<User>;
  allUsers: Array<User>;
  banks: Array<BankResponseType>;
  selectbanks: Array<BankResponseType>;
  selectbranchs: Array<Agency>;
  usr: User;
  branch: Agency;
  bank: Bank;
  branchs: Array<Agency>;
  filterUsers: Array<User>;
  roles: Array<UserRole>;
  base64File: string;
  ro: boolean;
  ur: UserRole;
  currentSelectedPage: number = 0;
  pag: number;
  pagnum: number;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService, private auth: AuthService) {
    this.initView();
  }

  ngOnInit(): void {
    this.initView();
  }

  initView() {
    this.page = 0;
    this.pageUser = {} as PageChapter;
    this.users = new Array<User>();
    this.allUsers = new Array<User>();
    this.ur = {} as UserRole;
    this.branchs = new Array<Agency>();
    this.filterUsers = new Array<User>();
    this.roles = new Array<UserRole>();
    this.selectbranchs = new Array<Agency>();
    this.branch = {} as Agency;
    this.bank = {} as Bank;
    this.usr = {} as User;
    this.banks = new Array<BankResponseType>();
    this.selectbanks = new Array<BankResponseType>();
    this.base64File = '';
    this.pages =[];
    this.pagnum = 20;
    this.ro = false;
    this.getbanks();
    this.getRoles();
    this.getUsersList(0);
    this.getAllUsersList();
    this.getRoleById(this.auth.getUser().roleId);
  }

  getUsersList(page: number): void {
    showLoader(true);
    this.ds.get(`/users/list/${this.pagnum}?page=${page}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);

      if (res.body.status === 200) {
        this.pageUser = res.body.data;
        this.pages = Array(this.pageUser.totalPages).fill(0).map((x,i)=>i);
        this.users = this.pageUser.content;
        this.filterUsers = this.pageUser.content;
        if (this.auth.getUser().roleId === 2) {
          this.filterUsers = this.filterUsers.filter(user =>{
            return user.roleId === 3 || user.roleId === 4;
          });
          this.filterUsers = this.filterUsers.filter(user => {
            return user.codchap === this.auth.getUser().codchap;
          });
        }
        //this.filterUsers.sort((a,b)=>a.username.localeCompare(b.username));
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getAllUsersList(): void {
    showLoader(true);
    this.ds.get(`/users/list/0?0`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);

      if (res.body.status === 200) {
        this.pageUser = res.body.data;
        this.allUsers = this.pageUser.content;
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }


  getRoles(): void {
    showLoader(true);
    this.ds.get(`/roles/`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.roles = res.body.data;
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURRED');
    });
  }

  getbankName(user: User): BankResponseType {
    // Show the loader to indicate that a search is in progress
    showLoader(true);

    // Check that `user.bqId` is not null or undefined before calling `trim()`
    const foundBank = this.selectbanks.find((bank) => bank.bank.code.trim() === (user.bqId ?? '').trim());

    // Hide the loader
    showLoader(false);

    // Return the found bank, or null if not found
    return foundBank ?? null;
  }

  getbranchName(user: User): Agency {
    if (!user || !user.bqId || !user.agccpt) {
      return null; // return null if user or required properties are null
    }

    showLoader(true);
    let ag: Agency;
    let bk: BankResponseType;
    bk = this.selectbanks.find(element => element && element.bank && element.bank.code && element.bank.code.trim() === user.bqId.trim());
    if (bk) {
      ag = this.branchs.find(element => element && element.code && element.code.trim() === user.agccpt.trim() && bk.bank.id == element.bqId);
    }
    showLoader(false);
    return ag;
  }


  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.banks = res.body.data;
        this.selectbanks = res.body.data;
        this.getBranches();
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getBranches(): void {
    // Show the loader to indicate that a search is in progress
    showLoader(true);

    // Use the HTTP GET method to retrieve the list of branches
    this.ds.get(`/branches/`).subscribe((res: HttpResponse<APIResponse>) => {
      // Check the status of the HTTP response
      if (res.body.status === 200) {
        // Hide the loader
        showLoader(false);

        // Store the list of branches in the component property
        this.branchs = res.body.data;

        // Filter the branches to include only those that belong to the user's bank
        this.selectbranchs = this.branchs.filter((branch) => {
          // Find the bank associated with the branch
          const bank = this.banks.find((bk) => Number(bk.bank.id) === Number(branch.bqId));

          // Return true if the user's bank ID matches the bank's code, or false otherwise
          return this.usr.bqId === (bank?.bank.code ?? null);
        });
      } else {
        // Hide the loader
        showLoader(false);

        // Display an error message
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      // Hide the loader
      showLoader(false);

      // Display an error message
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURRED');
    });
  }


  getRoleById(id: number): void {
    showLoader(true);
    this.ds.get(`/roles/${id}`).subscribe((res: HttpResponse<APIResponse>) => {

      if (res.body.status === 200) {
        this.ur = res.body.data;
        showLoader(false);
        if (this.ur.privilege.toLowerCase() == 'RW'.toLowerCase()) {
          this.ro = true;
        }
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterUsers = this.allUsers.filter((cre) => {
        return cre.name.toLowerCase().includes(query.toLowerCase()) ||
          cre.username.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.filterUsers = this.users;
    }
  }

  setPage(i) {
    this.getUsersList(i);
  }

  getPaginationWithIndex(index: number, pag: any) {
    this.setPage(index);
  }

  active(index: number) {
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  onChangeNumber(pag: number){
    try {
      this.pagnum = pag;
      this.getUsersList(0);
    } catch (error) {

    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.pageUser.totalPages - 1) {
      this.setPage(++this.currentSelectedPage);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.setPage(--this.currentSelectedPage);
    }
  }

  onDelete(id: number) {
    showLoader(true)
    this.ds.delete('/users/delete/' + id)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.status === 200) {
          showLoader(false);
          this.initView();
          this.alert.success('SUCCESS', 'USER DELETED SUCCESSFULLY');
        } else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
        }
      }, (err) => {
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });

  }

  onUpdate(usr: User) {
    usr.password = "0000";
    showLoader(true);
    this.ds.put('/users/reset/pass/' + usr.id, usr).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.alert.success('SUCCESS', 'USER UPDATED SUCCESSFULLY');
        this.initView();
      } else {
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
    });
  }


  changeStatus(usr: User) {
    showLoader(true);
    usr.active = !usr.active;
    this.ds.put('/users/update/' + usr.id, usr).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.alert.success('SUCCESS', 'USER UPDATED SUCCESSFULLY');
        this.initView();
      } else {
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
    });
  }

  backClicked() {
    this.loc.back();
  }

  printUserReport(): void {
    showLoader(true);
    this.ds.get(`/users/reports/list`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
    });
  }


}
