import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InvokerService {

  constructor(private authService: AuthService) {
    // this.authService.getUser();
  }


  public getKey(): string {
    if (this.authService.getUser() == null && this.authService.getUser() === undefined) {
      return null;
    } else {
      const key = this.authService.getUser().secuKey;
      return key;
    }

  }
}
