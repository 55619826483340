import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/DataService';
import { APIResponse, FeesConfig, MerchantConfig, UpdateFeeRequest } from 'src/app/shared/models';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
declare var showLoader;

@Component({
  selector: 'app-merchand-configuration',
  templateUrl: './merchand-configuration.component.html',
  styleUrls: ['./merchand-configuration.component.css']
})
export class MerchandConfigurationComponent implements OnInit {

  merchantConfig: MerchantConfig
  configs: MerchantConfig[] = []
  feesConfig: FeesConfig
  feesConfigs: FeesConfig[] = []
  request: UpdateFeeRequest

  constructor(private ds: DataService, private alert: ToastrService, private loc: Location) { 
    this.initView()
  }

  ngOnInit(): void {
  }

  initView() {
    this.merchantConfig = {} as MerchantConfig
    this.feesConfig = {} as FeesConfig
    this.request = {} as UpdateFeeRequest
    this.getMerchandConfiguration();
    this.getPaymentFeed()
  }

  getMerchandConfiguration(): void {
    showLoader(true);
    this.ds.get(`/mobilePaymentConfig/payments`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.configs = res.body.data;
        if (this.configs.length > 0) {
          this.merchantConfig = this.configs[0]
        }
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getPaymentFeed(): void {
    showLoader(true);
    this.ds.get(`/mobilePaymentConfig/fees`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.feesConfigs = res.body.data;
        if (this.feesConfigs.length > 0) {
          this.feesConfig = this.feesConfigs[0]
        }
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onUpdate(): void {
    showLoader(true);
    this.request.feeConfig = this.feesConfig
    this.request.paymentConfig = this.merchantConfig
    this.ds.put(`/mobilePaymentConfig/update`, this.request).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.alert.success('SUCCESS', 'YOUR MERCHANT CONFIGURATION HAS BEEN UPDATED SUCCESSFULLY');
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  backClicked() {
    this.loc.back();
  }

}
