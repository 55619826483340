import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { enc } from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {

  protected key = 'ptNsPdPA8ivWw6RWka/yUA==';

  constructor() { }

  public encrypt(strToEncrypt: string): string {
    const key = CryptoJS.enc.Hex.parse(this.base64ToHexString(this.key));
    const encrypted = CryptoJS.AES.encrypt(strToEncrypt, key, this.getCipherConfig());
    // const result = (cipherparams.ciphertext.toString());
    return this.base64ToHexString(CryptoJS.enc.Base64.stringify(encrypted.ciphertext));
  }

  public decrypt(strToDecrypt: string): string {
    try {
      const bytesToDecrypt = this.stringHexToBase64(strToDecrypt);
      const key = CryptoJS.enc.Hex.parse(this.base64ToHexString(this.key));
      const bytes = CryptoJS.AES.decrypt(bytesToDecrypt, key, this.getCipherConfig());
      const decryptedData = bytes.toString(enc.Utf8);
      return decryptedData;

    } catch (err) {
      throw new Error('Error while decrypting: ' + err);
    }
  }
  private getCipherConfig(): {} {
    const key = CryptoJS.enc.Hex.parse(this.base64ToHexString(this.key));
    return {
      keySize: 128 / 32,
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    };
  }
  private stringHexToBase64(str: string): string {
    return btoa(str.match(/\w{2}/g).map(function (a) {
      return String.fromCharCode(parseInt(a, 16));
    }).join(''));
  }

  private base64ToHexString(str: string): string {
    const raw = atob(str);
    let HEX = '';

    for (let i = 0; i < raw.length; i++) {
      const _hex = raw.charCodeAt(i).toString(16);
      HEX += (_hex.length === 2 ? _hex : '0' + _hex);
    }
    return HEX.toUpperCase();
  }

  _base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public hashPwd(raw) {
  }

}
