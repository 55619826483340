import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DataService } from 'src/app/shared/DataService';
import { AuthService } from 'src/app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { logRequest, APIResponse, User, PageChapter, logRequestUser } from 'src/app/shared/models';
import { HttpResponse } from '@angular/common/http';
import { Printer } from 'src/app/shared/printer.service';

declare var showLoader;

@Component({
  selector: 'app-users-log',
  templateUrl: './users-log.component.html',
  styleUrls: ['./users-log.component.css']
})
export class UsersLogComponent implements OnInit {

  month: string;
  usr: User;
  year: string;
  name: string;
  base64File;
  log: logRequest;
  logUser: logRequestUser;
  typ: boolean;
  pagnum: number;
  filterUsers: Array<User>;
  pageUser: PageChapter;

  constructor(private ds: DataService, private loc: Location, private auth: AuthService, private alert: ToastrService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.base64File = '';
    this.log = {} as logRequest;
    this.logUser = {} as logRequestUser;
    this.name = '';
    this.usr = {} as User;
    this.usr = this.auth.getUser();
    this.typ = true;
    this.pagnum = 0;
    this.filterUsers = new Array<User>();
    this.pageUser = {} as PageChapter;
    this.getUsersList(0);
  }

  getUsersList(page: number): void {
    showLoader(true);
    this.ds.get(`/users/list/${this.pagnum}?page=${page}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.pageUser = res.body.data;
        this.filterUsers = this.pageUser.content;
        this.filterUsers.sort((a,b)=>a.username.localeCompare(b.username));
        this.filterUsers.sort((a,b) => a.name > b.name ? 1 : -1);

      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  printLogsReport() {
    showLoader(true);
    this.logUser.from = this.log.from;
    this.logUser.to = this.log.to;
    if(this.name != ''){
      this.logUser.name = this.name;
      this.printReport(this.logUser);
    }else {
      this.logUser.name = '';
      this.printReport(this.logUser);
    }
  }

  printReport(logObject : any){
    this.ds.post(`/logs/reports/list`, logObject).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      } else {
        showLoader(false);
        this.alert.warning('WARNING', 'LOG TO HEAVY TO BE PRINTED. IT IS RECOMMENDED TO CHOOSE A SHORT PERIOD');
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      //this.alert.warning('WARNING', 'LOG TO HEAVY TO BE PRINTED. IT IS RECOMMENDED TO CHOOSE A SHORT PERIOD');
    });
  }

  backClicked() {
    this.loc.back();
  }

  onUserChange(value : string){
    this.name = value.toString();
  }


}
