import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ChangePass, User, APIResponse } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { AuthService } from 'src/app/shared/auth.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Md5 } from 'md5-typescript';
import { Router } from '@angular/router';
import { log } from 'console';

declare var showLoader;

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.css']
})
export class ChangePassComponent implements OnInit {

  chp: ChangePass;
  oldPwd: string;
  newPwd: string;
  confirmPwd: string;
  usr: User;
  visible: boolean = true;

  constructor(private ds: DataService, private loc: Location, private auth: AuthService, private alert:ToastrService, private router: Router) { 
    this.initView();
    // console.log(this.usr);
  }

  ngOnInit(): void {
  }

    /**
   * this is the function used to view the password
   */
    viewPassword(): void {
      this.visible = !this.visible;
    }

  onChangePass() {
    if(this.newPwd != this.confirmPwd) {
      this.alert.error('ERROR', 'New Password is different from Repeat New Password');
      return;
    }
    this.chp.oldPwd = btoa(this.oldPwd);
    this.chp.newPwd = btoa(this.newPwd);
    this.chp.confirmPwd =btoa(this.confirmPwd);
    showLoader(true);
    this.ds.put('/users/change-password/'+ this.usr.uuid, this.chp).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
       showLoader(false);
        this.alert.success('SUCCESS', 'PASSWORD SAVE SUCCESSFULLY');
        this.auth.clearTokens();
        this.router.navigateByUrl('/login');
      } else {
       // console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
     //console.log(err);
    });
  }


  initView() {
    this.chp = {} as ChangePass;
    this.newPwd = '';
    this.oldPwd = '';
    this.confirmPwd = '';
    this.usr = this.auth.getUser();
  }

  backClicked() {
    this.loc.back();
  }

}
