import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/DataService';
import { Bank, APIResponse, Agency, PageChapter, BankResponseType, AgencyResponseType, UserRole } from 'src/app/shared/models';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { zip } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/printer.service';
import { AuthService } from 'src/app/shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-branche-list',
  templateUrl: './branche-list.component.html',
  styleUrls: ['./branche-list.component.css']
})
export class BrancheListComponent implements OnInit {

  page: number;
  pageCredit: PageChapter;
  pages: Array<number>;
  credits: Array<AgencyResponseType>;
  branchs: Array<AgencyResponseType>;
  allBranches: Array<AgencyResponseType>;
  filterBranches: Array<AgencyResponseType>;
  banks: Array<BankResponseType>;
  ro: boolean;
  ur: UserRole;
  base64File: string;
  currentSelectedPage: number = 0;
  pag: number;
  pagnum: number;
  numberOfSyncedAgencies: number;
  numberOfUnsyncedAgencies: number;
  numberOfNeverSyncedAgencies: number;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService, private auth: AuthService) {
    //this.initView();
  }

  ngOnInit(): void {
    this.initView();
  }

  initView() {
    this.page = 0;
    this.pageCredit = {} as PageChapter;
    this.ur = {} as UserRole;
    this.credits = new Array<AgencyResponseType>();
    this.branchs = new Array<AgencyResponseType>();
    this.allBranches = new Array<AgencyResponseType>();
    this.filterBranches = new Array<AgencyResponseType>();
    this.banks = new Array<BankResponseType>();
    this.base64File = '';
    this.pages =[];
    this.pagnum = 20;
    this.ro = false;
    this.numberOfSyncedAgencies = 0;
    this.numberOfUnsyncedAgencies = 0;
    this.numberOfNeverSyncedAgencies = 0;
    this.getbanks();
    this.getBranchesList(0);
    this.getAllBranches();
    this.getRoles(this.auth.getUser().roleId);
  }

  getBranchesList(page: number): void {
    showLoader(true);
    this.ds.get(`/branches/list/${this.pagnum}?page=${page}`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        this.pageCredit = res.body.data;
        this.pages =  Array(this.pageCredit.totalPages).fill(0).map((x,i)=>i);
        this.credits = this.pageCredit.content;
        this.filterBranches = this.pageCredit.content;

        showLoader(false);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  /**
   * This function sets the synchronization statistics.
   */
  setSyncStatistics(): void {
    this.setNumberOfSyncedAgencies(this.allBranches);
    this.setNumberOfUnSyncedAgencies(this.allBranches);
    this.setNumberOfNeverSyncedAgencies(this.allBranches);
  }

  /**
   * This function gets data from the API and sets the list of all agency branches.
   * @returns void
   */
  getAllBranches(): void {
    showLoader(true);
    this.ds.get(`/branches/list/0?0`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.pageCredit = res.body.data;
        this.allBranches = this.pageCredit.content;
        this.setSyncStatistics();
      } else {
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  /**
   * This function calculates and sets the number of recently synced agencies (synced in the last 7 days).
   */
  setNumberOfSyncedAgencies(branches: AgencyResponseType[]): void {
    this.numberOfSyncedAgencies = branches
      .filter(branch => branch.last_sync)
      .filter(branch => this.calculateDaysDifference(branch.last_sync.toString()) <= 7)
      .length;
  }

  /**
   * This function calculates and sets the number of unsynced agencies (synced more than 7 days ago).
   */
  setNumberOfUnSyncedAgencies(branches: AgencyResponseType[]): void {
    this.numberOfUnsyncedAgencies = branches
      .filter(branch => branch.last_sync)
      .filter(branch => this.calculateDaysDifference(branch.last_sync.toString()) > 7)
      .length;
  }

  /**
   * This function calculates and sets the number of never synced agencies.
   */
  setNumberOfNeverSyncedAgencies(branches: AgencyResponseType[]): void {
    this.numberOfNeverSyncedAgencies = branches.filter(branch => !branch.last_sync).length;
  }

  /**
   * This function calculates how many days have passed since the last synchronization.
   * @param date The date of last synchronization.
   * @returns The number of days between the date of last synchronization and the current date.
   */
  calculateDaysDifference(date: string): number {
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const currentDate: Date = new Date();
    const lastSyncDate: Date = new Date(date);
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDay()) - Date.UTC(lastSyncDate.getFullYear(), lastSyncDate.getMonth(), lastSyncDate.getDay())) / millisecondsInADay);
  }

  getRoles(id: number): void {
    showLoader(true);
    this.ds.get(`/roles/${id}`).subscribe((res: HttpResponse<APIResponse>) => {

      if (res.body.status === 200) {
        this.ur = res.body.data;
        showLoader(false);
        //console.log(this.ur);
        if (this.ur.privilege.toLowerCase() == 'RW'.toLowerCase()) {
          this.ro = true;
        }
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        // console.log(res.body.data);
        this.banks = res.body.data;
        showLoader(false);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  /**
   * This function filters the displayed agencies by the input search query.
   * @param query User input search query.
   */
  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterBranches = this.allBranches.filter((branch) => {
        return branch.name.toLowerCase().includes(query.toLowerCase()) ||
          branch.code.toLowerCase().includes(query.toLowerCase())||
          branch.bank_name.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.filterBranches = this.credits;
    }
  }

  setPage(i) {
    this.getBranchesList(i);
  }

  getPaginationWithIndex(index: number, pag: any) {
    this.setPage(index);
  }

  active(index: number) {
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  onChangeNumber(pag: number){
    try {
      this.pagnum = pag;
      this.getBranchesList(0);
    } catch (error) {

    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.pageCredit.totalPages - 1) {
      this.setPage(++this.currentSelectedPage);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.setPage(--this.currentSelectedPage);
    }
  }

  onLock(id: number) {
    showLoader(true);
    this.ds.delete('/branches/lock/' + id)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.body.status === 200) {
          showLoader(false);
          this.initView();
          this.alert.success('SUCCESS', 'BRANCH LOCKED SUCCESSFULLY');
        } else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
        }
      }, (err) => {
        //console.log(err);
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });
  }

  onUnlock(id: number, cr) {
    showLoader(true);
    this.ds.post('/branches/unlock/' + id, cr)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.body.status === 200) {
          showLoader(false);
          this.initView();
          this.alert.success('SUCCESS', 'BRANCH UNLOCKED SUCCESSFULLY');
        } else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
        }
      }, (err) => {
        //console.log(err);
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });
  }

  onDelete(id: number) {
    showLoader(true);
    this.ds.delete('/branches/delete/' + id)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.body.status === 200) {
          showLoader(false);
          this.initView();
          this.alert.success('SUCCESS', 'BRANCHE DELETED SUCCESSFULLY');
        } else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
        }
      }, (err) => {
        //console.log(err);
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });

  }

  printBranchReport(): void {
    showLoader(true);
    this.ds.get(`/branches/reports/list`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', err.message);
    });
  }

  backClicked() {
    this.loc.back();
  }
}
