import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccessChargeComponent } from './access-charge/access-charge.component';
import { GeneralSetupComponent } from './general-setup/general-setup.component';
import { LicenceComponent } from './licence/licence.component';
import { AlertMessageListComponent } from './alert-message-list/alert-message-list.component';
import { AlertMessageNewComponent } from './alert-message-new/alert-message-new.component';
import { AlertMessageEditComponent } from './alert-message-edit/alert-message-edit.component';
import { ImportContactComponent } from './import-contact/import-contact.component';
import { MerchandConfigurationComponent } from './merchand-configuration/merchand-configuration.component';
import { UploadSynchronisationComponent } from './upload-synchronisation/upload-synchronisation.component';


const routes: Routes = [
  { path: 'configuration/access-charges', component: AccessChargeComponent },
  { path: 'configuration/general-setup', component: GeneralSetupComponent },
  { path: 'configuration/merchant-config', component: MerchandConfigurationComponent },
  { path: 'configuration/licences', component: LicenceComponent },
  { path: 'configuration/alerts', component: AlertMessageListComponent },
  { path: 'configuration/alerts/edit/:id', component: AlertMessageEditComponent },
  { path: 'configuration/alerts/new', component: AlertMessageNewComponent },
  { path: 'configuration/upload', component: ImportContactComponent },
  { path: 'configuration/upload-sync', component: UploadSynchronisationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule,
    SharedModule
  ]
})
export class ConfigurationRoutingModule { }
