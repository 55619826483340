import { Component, OnInit } from '@angular/core';
import { PageChapter, BankResponseType, Agency, SituationRequestType, LoanResponseType, APIResponse, User, Payment } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { Printer } from 'src/app/shared/printer.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth.service';

declare var showLoader;
@Component({
  selector: 'app-payment-hist',
  templateUrl: './payment-hist.component.html',
  styleUrls: ['./payment-hist.component.css']
})
export class PaymentHistComponent implements OnInit {

  page: number;
  pageCredit: PageChapter;
  pages: Array<number>;
  banks: Array<BankResponseType>;
  fbanks: Array<BankResponseType>;
  branchs: Array<Agency>;
  sbranchs: Array<Agency>;
  showBranch: boolean;
  srt: SituationRequestType;
  lrts: Array<Payment>;
  filterLrts: Array<Payment>;
  base64File: string;
  usr: User;
  currentSelectedPage: number = 0;
  pag: number;
  pagnum: number;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService, private auth: AuthService) {
    this.initView();
    this.usr = this.auth.getUser();
    if (this.usr != null && this.usr != undefined && this.usr.bqId != null) {
      if(this.usr.roleId != 1){
        this.srt.bank = this.usr.bqId;
        this.onLoadData(0);
      }

    }
  }

  ngOnInit(): void {
  }

  initView() {
    this.page = 0;
    this.pageCredit = {} as PageChapter;
    this.lrts = new Array<Payment>();
    this.filterLrts = new Array<Payment>();
    this.banks = new Array<BankResponseType>();
    this.fbanks = new Array<BankResponseType>();
    this.branchs = new Array<Agency>();
    this.sbranchs = new Array<Agency>();
    this.showBranch = false;
    this.srt = {} as SituationRequestType;
    this.base64File = '';
    this.pages =[];
    this.pagnum = 20;
    this.getbanks();
  }



  onLoadData(page: number) {
    this.filterLrts = new Array<Payment>();
    //console.log(this.srt);
    showLoader(true);
    this.ds.post(`/payments/bankPayments/list/${this.pagnum}?page=${page}`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.pageCredit = res.body.data;
        this.pages = Array(this.pageCredit.totalPages).fill(0).map((x,i)=>i);
        this.lrts = this.pageCredit.content;
        if(this.srt.bank === "all") {
          this.lrts.sort((a,b)=>a.bankName.localeCompare(b.bankName));
        } else {
          this.lrts.sort((a,b)=>new Date(b.datereg).getTime() - new Date(a.datereg).getTime());
        }
        this.filterLrts = this.lrts;
        //console.log(this.filterLrts);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      //console.log(err);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  setPage(i) {
    this.onLoadData(i);
  }

  getPaginationWithIndex(index: number, pag: any) {
    this.setPage(index);
  }

  active(index: number) {
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  onChangeNumber(pag: number){
    try {
      this.pagnum = pag;
      this.onLoadData(0);
    } catch (error) {

    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.pageCredit.totalPages - 1) {
      this.setPage(++this.currentSelectedPage);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.setPage(--this.currentSelectedPage);
    }
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.banks = res.body.data;
        this.fbanks = this.banks.filter(x => x.chapter?.code.toLowerCase() === this.usr.codchap.toLowerCase());
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }


  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterLrts = this.lrts.filter((cre) => {
        return cre.memName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        cre.bankName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        cre.loanBal.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        cre.amntPaid.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        cre.datereg.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1

      });
    } else {
      this.filterLrts = this.lrts;
    }
  }

  printPaymentReportList() {
    showLoader(true);
    this.ds.post(`/payments/reports/list`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  printPaymentReport(pId: number) {
    showLoader(true);
    this.ds.get(`/licences/payment/${pId}`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      } else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  backClicked() {
    this.loc.back();
  }

}
