import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/DataService';
import { Location } from '@angular/common';
import { LoanBalanceResponseType, BankResponseType, Agency, SituationRequestType, APIResponse, User } from 'src/app/shared/models';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/printer.service';
import { AuthService } from 'src/app/shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-loan-portfolio-balance',
  templateUrl: './loan-portfolio-balance.component.html',
  styleUrls: ['./loan-portfolio-balance.component.css']
})
export class LoanPortfolioBalanceComponent implements OnInit {

  banks: Array<BankResponseType>;
  fbanks: Array<BankResponseType>;
  branchs: Array<Agency>;
  sbranchs: Array<Agency>;
  showBranch: boolean;
  srt: SituationRequestType;
  lbts: Array<LoanBalanceResponseType>;
  amntTot: number;
  base64File: string;
  usr: User;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService, private auth: AuthService) {
    this.initView();
  }

  ngOnInit(): void {
  }

  initView() {
    this.lbts = new Array<LoanBalanceResponseType>();
    this.banks = new Array<BankResponseType>();
    this.fbanks = new Array<BankResponseType>();
    this.branchs = new Array<Agency>();
    this.sbranchs = new Array<Agency>();
    this.showBranch = false;
    this.srt = {} as SituationRequestType;
    this.amntTot = 0;
    this.base64File = '';
    // this.sits = new Array<Situation>();
    this.usr = this.auth.getUser();
    this.getbanks();
    if(this.usr.roleId !=2 && this.usr.roleId==3){
      this.srt.bank = this.usr.bqId;
      this.onLoadData(0);
    }
    // this.getBranches();
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {

      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.banks = res.body.data;

        this.fbanks = this.banks.filter((x) => {
          return x.chapter?.code.toLowerCase() === this.usr.codchap.toLowerCase();
        });
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getBranches(): void {
    showLoader(true);
    this.ds.get(`/branches/`).subscribe((res: HttpResponse<APIResponse>) => {

      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.branchs = res.body.data;
      }
    }, (err) => {
     // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onSelectBank(value: string) {
    //console.log(value);
    if (value == null && value === undefined && value === '') {
      this.srt.branch = "";
      this.showBranch = false;
    } else {
      this.sbranchs = this.branchs.filter((x) => {
        let bank = this.banks.find(bk => bk.bank.id === x.bqId);
        return value.toLowerCase() === bank.bank.code.toLowerCase();
      });
      this.showBranch = true;
    }

  }

  onLoadData(page: number) {
    //console.log(this.srt);
    showLoader(true);
    this.ds.post(`/loans/agency-balance`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.lbts = res.body.data;
        //console.log(this.lbts);
        this.amntTot = 0;
        this.lbts.forEach((x) => {
          this.amntTot += Number(x.balance);
        });
      } else {
        // console.log(res.body.message);
        this.alert.error('SERVER ERROR', res.body.message);
        showLoader(false);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      //console.log(err);
    });
  }

  printLoanBalanceReport() {
    showLoader(true);
    this.ds.post(`/loans/reports/agency-balance/list`, this.srt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.base64File = res.body.data;
        setTimeout(() => {
          Printer.print2(encodeURI(this.base64File), 'application/pdf');
        }, 300);
        //console.log(res.body.data);
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }


  backClicked() {
    this.loc.back();
  }

}
