import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/DataService';
import { Chapter, APIResponse, BankResponseType, Agency } from 'src/app/shared/models';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Component({
  selector: 'app-branche-new',
  templateUrl: './branche-new.component.html',
  styleUrls: ['./branche-new.component.css']
})
export class BrancheNewComponent implements OnInit {

agc: Agency;
banks: Array<BankResponseType>;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService) { 
    this.initView();
  }

  ngOnInit(): void {
  }

  onCreate() {
    showLoader(true);
    this.ds.post('/branches/new', this.agc).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
       showLoader(false);
        this.alert.success('SUCCESS', 'BRANCHE SAVE SUCCESSFULLY');
        this.initView();
      } else {
        //console.log(res.body.message);
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
     this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
     //console.log(err);
    });
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.banks = res.body.data;
        this.banks.sort((a, b) => (a.bank.name > b.bank.name) ? 1 : ((b.bank.name > a.bank.name) ? -1 : 0));
        //console.log(this.banks );
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
     // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }

  initView() {
    this.agc = {} as Agency;
    this.banks = new Array<BankResponseType>();
    this.getbanks();
  }

  backClicked() {
    this.loc.back();
  }

}
