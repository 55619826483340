<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-8">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Change Password</h5>
                    <span>This page permit the user to update his password</span>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">

                        <div class="card">
                            <div class="card-header">
                                <h5>Change Password</h5>
                                <span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span>
                            </div>
                            <div class="card-block">
                                <form>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Old Password</label>
                                        <div class="col-sm-9">
                                            <input [type]="visible ? 'password' : 'text'" [(ngModel)]="oldPwd" name="oldPwd" required class="form-control">
                                            <i [ngClass]="visible ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="viewPassword()"></i>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">New Password</label>
                                        <div class="col-sm-9">
                                            <input [type]="visible ? 'password' : 'text'" [(ngModel)]="newPwd" name="newPwd" required class="form-control">
                                            <i [ngClass]="visible ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="viewPassword()"></i>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Repeat New Password</label>
                                        <div class="col-sm-9">
                                            <input [type]="visible ? 'password' : 'text'" [(ngModel)]="confirmPwd" name="confirmPwd" required class="form-control">
                                            <i [ngClass]="visible ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="viewPassword()"></i>
                                        </div>
                                    </div>

                                    <button class="btn btn-mat waves-effect waves-light btn-inverse   float-right" [disabled]="!newPwd || !oldPwd || !confirmPwd" (click)="onChangePass()">Validate</button>

                                </form>
                            </div>
                        </div>


                    </div>
                    <div class="col-sm-1"></div>
                </div>

            </div>
        </div>
    </div>
</div>