<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Remove From Blacklist</h5>
                    <span>This page is made to remove a borrower from blacklist</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">

                        <div class="row">
                            <div class="col-sm-8">
                                <div class="card m-t-10 ">
                                    <div class="card-header ">
                                        <div class="row align-items-end ">
                                            <div class="col-lg-7 ">
                                                <h5>Borrower's Details</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-block ">
                                        <div class="table-responsive">
                                            <table id="demo-foo-filtering " class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling ">
                                                <thead>

                                                </thead>
                                                <tbody>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">Member №</th>
                                                        <td style="display: table-cell; ">{{sdrt.situation.numcli}}</td>
                                                    </tr>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">Member Name</th>
                                                        <td style="display: table-cell; ">{{sdrt.situation.nomcli}}</td>
                                                    </tr>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">ID Card №</th>
                                                        <td style="display: table-cell; ">{{sdrt.client != null ? sdrt.client.cniclient: ""}}</td>
                                                    </tr>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">Phone №</th>
                                                        <td style="display: table-cell; ">{{sdrt.client != null ? sdrt.client.phone: ""}}</td>
                                                    </tr>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">Credit Union</th>
                                                        <td style="display: table-cell; ">{{sdrt.bank != null ? sdrt.bank.name : ""}}</td>
                                                    </tr>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">Credit Union Branch</th>
                                                        <td style="display: table-cell; ">{{sdrt.branch != null ? sdrt.branch.name: ""}}</td>
                                                    </tr>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">Loan motif</th>
                                                        <td style="display: table-cell; ">{{sdrt.loan != null ? sdrt.loan.motifdem: ""}}</td>
                                                    </tr>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">Loan Amount</th>
                                                        <td style="display: table-cell; ">XAF {{sdrt.sitLoan.mtpret? sdrt.sitLoan.mtpret : 'Unavailable'}}</td>
                                                    </tr>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">Loan balance</th>
                                                        <td style="display: table-cell; ">{{sdrt.sitLoan.montrest? sdrt.sitLoan.montrest : 'Unavailable'}}</td>
                                                    </tr>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">Payment Delay</th>
                                                        <td style="display: table-cell; ">{{sdrt.sitLoan.retard}} Days</td>
                                                    </tr>
                                                    <tr class="footable-header">
                                                        <th style="display: table-cell; width: 50%;">Status</th>
                                                        <td style="display: table-cell; ">
                                                            <span *ngIf="sdrt.situation.isPermanentBlocked==0 " class="pcoded-badge label label-warning ">Deliquency Threat</span>
                                                            <span *ngIf="sdrt.situation.isPermanentBlocked==1 " class="pcoded-badge label label-danger ">Blacklisted</span>
                                                            <span *ngIf="sdrt.situation.isPermanentBlocked==2 " class="pcoded-badge label label-info ">Pendding Approval</span>
                                                        </td>
                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <form #blockForm="ngForm">
                                    <div class="form-group">
                                        <label>Justify why you want to block this borrower</label>
                                        <textarea rows="4" name="reason" [(ngModel)]="srt.reason" class="form-control"></textarea>
                                    </div>
                                    <button type="submit" [disabled]="!blockForm.form.valid" class="btn btn-danger btn-md btn-block waves-effect text-center m-b-20" (click)="unBlocked()">Validate</button>
                                </form>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="card m-t-10 ">
                                    <div class="card-header ">
                                        <div class="row align-items-end ">
                                            <div class="col-lg-7 ">
                                                <h5>Member Relations</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-block ">
                                        <div class="table-responsive">
                                            <table id="demo-foo-filtering " class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg ">
                                                <thead>
                                                    <tr class="footable-header table-inverse ">
                                                        <th class="footable-sortable footable-first-visible " style="display: table-cell; ">Name<span class="fooicon fooicon-sort "></span></th>
                                                        <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Relationship<span class="fooicon fooicon-sort "></span></th>
                                                        <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">NIC Number<span class="fooicon fooicon-sort "></span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let sit of sdrt.relation ">
                                                        <td class="footable-first-visible " style="display: table-cell; ">{{sit.nombenef}}</td>
                                                        <td style="display: table-cell; ">{{sit.relbenef ? sit.relbenef : 'Unavailable'}}</td>
                                                        <td style="display: table-cell; ">{{sit.cnibenef ? sit.cnibenef : 'Unavailable'}}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-5">
                                <div class="card m-t-10 ">
                                    <div class="card-header ">
                                        <div class="row align-items-end ">
                                            <div class="col-lg-7 ">
                                                <h5>Threat Justification</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-block ">
                                        <div class="card bg-light" *ngFor="let m of menos">
                                            <div class="card-body">

                                                <div class="row">
                                                    <div class="col-sm-1">
                                                        <label class="badge badge-danger">&nbsp;</label>
                                                    </div>
                                                    <div class="col-sm-10">
                                                        <p class="card-text f-12"> {{m}}</p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card m-t-10 ">
                            <div class="card-header ">
                                <div class="row align-items-end ">
                                    <div class="col-lg-7 ">
                                        <h5>All Loans Aquired by Member</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block ">
                                <div class="table-responsive">
                                    <table id="demo-foo-filtering " class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg ">
                                        <thead>
                                            <tr class="footable-header table-inverse ">
                                                <th class="footable-sortable footable-first-visible " style="display: table-cell; ">File N°<span class="fooicon fooicon-sort "></span></th>
                                                <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Credit Union<span class="fooicon fooicon-sort "></span></th>
                                                <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Branch<span class="fooicon fooicon-sort "></span></th>
                                                <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Menber<span class="fooicon fooicon-sort "></span></th>
                                                <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Menber N°<span class="fooicon fooicon-sort "></span></th>
                                                <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Loan Amount<span class="fooicon fooicon-sort "></span></th>
                                                <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Motif<span class="fooicon fooicon-sort "></span></th>
                                                <th data-breakpoints="xs " class="footable-sortable " style="display: table-cell; ">Loan Date<span class="fooicon fooicon-sort "></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let ss of ssrts">
                                                <td class="footable-first-visible " style="display: table-cell; ">{{ss.NUMDOS}}</td>
                                                <td style="display: table-cell; ">{{ss.bank_name}}</td>
                                                <td style="display: table-cell; ">{{ss.branch_name}}</td>
                                                <td style="display: table-cell; ">{{ss.nomcli}}</td>
                                                <td style="display: table-cell; ">{{ss.numcli}}</td>
                                                <td style="display: table-cell; ">{{ss.MTPRET}}</td>
                                                <td style="display: table-cell; ">{{ss.MOTIFDEM}}</td>
                                                <td style="display: table-cell; ">{{ss.Datacc | date}}</td>
                                            </tr>

                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>