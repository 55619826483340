<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Access Charges Configuration</h5>
                    <span>This page allows admin to configure access charges</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-9">
                        <button class="btn btn-mat waves-effect waves-light btn-primary" (click)="printChargesReport()"><i class="fa fa-print"></i>Print</button>
                        <div class="card m-t-10">
                            <div class="card-header">

                                <div class="row align-items-end">
                                    <div class="col-lg-7">
                                        <h5>Configured Access Charges</h5>
                                        &nbsp;&nbsp;&nbsp;
                                        <h5>Total: {{acs.length}}</h5>
                                    </div>
                                    <div class="col-lg-5">
                                        <!-- <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i></button> -->
                                        <!-- <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">
                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">S/N</th>
                                                <th class="footable-sortable" style="display: table-cell;">Totals Loans Ranges (XAF)</th>
                                                <th class="footable-sortable" style="display: table-cell;">Charge / Month (XAF)</th>
                                                <th class="footable-sortable" style="display: table-cell;" *ngIf="ro">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let a of acs; let i = index">
                                                <td class="footable-first-visible" style="display: table-cell;">{{i+1}}</td>
                                                <td style="display: table-cell;">
                                                    <div class="row">
                                                        <div class="col-sm-1 text-center f-w-700">From: </div>
                                                        <div class="col-sm-4">
                                                            &nbsp;&nbsp;&nbsp;{{a.amntStrt | number}}
                                                        </div>
                                                        <div class="col-sm-2 text-center f-w-700">-</div>
                                                        <div class="col-sm-1 text-center f-w-700">to:</div>
                                                        <div class="col-sm-4">
                                                            {{a.amntEnd | number}}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="display: table-cell;">{{a.amount | number}}</td>

                                                <td style="display: table-cell;" *ngIf="ro">
                                                    <a (click)="onBeforeUpdate(a)" class="tabledit-edit-button btn btn-primary waves-effect waves-light"><i class="fa fa-pencil"></i></a>
                                                    <button type="button" (click)="onDelete(a.id)" class="tabledit-edit-button btn btn-danger waves-effect waves-light m-l-10"><i class="fa fa-trash"></i></button>
                                                </td>

                                            </tr>

                                            <tr *ngIf="ro">
                                                <td class="footable-first-visible" style="display: table-cell;">#</td>
                                                <td style="display: table-cell;">
                                                    <div class="row">
                                                        <div class="col-sm-1 m-t-10 text-center f-w-700">From:</div>
                                                        <div class="col-sm-4">
                                                            <input type="text" [(ngModel)]="ac.amntStrt" name="amntStrt" required class="form-control">
                                                        </div>
                                                        <div class="col-sm-2 m-t-10 text-center f-14 f-w-700">-</div>
                                                        <div class="col-sm-1 m-t-10 text-center f-w-700">to:</div>
                                                        <div class="col-sm-4">
                                                            <input type="text" [(ngModel)]="ac.amntEnd" name="amntEnd" required class="form-control">
                                                        </div>
                                                    </div>

                                                </td>
                                                <td style="display: table-cell;">
                                                    <input type="text" [(ngModel)]="ac.amount" name="amount" required class="form-control">
                                                </td>

                                                <td style="display: table-cell;">
                                                    <button style="padding: 12px 8px !important;
                                                    font-size: 10px !important;" *ngIf="!canUpd" class="btn btn-mat waves-effect waves-light btn-inverse" (click)="onCreate()">Validate</button>
                                                    <button style="padding: 12px 8px !important;
                                                    font-size: 10px !important;" *ngIf="canUpd" class="btn btn-mat waves-effect waves-light btn-primary m-l-10" (click)="onUpdate()">Validate</button>
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>