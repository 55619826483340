<div class="page-header card">
  <div class="row align-items-end">
      <div class="col-lg-8">
          <div class="page-header-title">
              <i class="feather icon-upload-cloud bg-c-blue"></i>
              <div class="d-inline">
                  <h5>Upload Sync</h5>
                  <span>This page permits the user to upload a file for external users synchronization</span>
              </div>
          </div>
      </div>
      <div class="col-lg-4">
          <div class="page-header-breadcrumb">
              <ul class="breadcrumb breadcrumb-title">
                  <li class="breadcrumb-item">
                      <a href="index.html"><i class="feather icon-home"></i></a>
                  </li>
                  <li class="breadcrumb-item">
                      <a href="#!">Upload Sync</a>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</div>

<div class="pcoded-inner-content">
  <div class="main-body">
      <div class="page-wrapper">

          <div class="page-body">

              <div class="card">
                  <div class="card-header">
                      <h5>File Upload</h5>
                  </div>
                  <div class="card-block">
                    <form action="https://colorlib.com//polygon/admindek/files/assets/pages/file-upload/file-upload.php" class="dropzone">
                      <input #takeInput type="file" (change)="onFileChange($event)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    </form>
                    <div class="text-center m-t-20">
                      <button [disabled]="!selectedFile" class="btn btn-primary" (click)="uploadFile()">Upload Now</button>
                    </div>
                  </div>
              </div>
          </div>

      </div>

  </div>
</div>