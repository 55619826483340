import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { User } from '../models';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  usr: User;

  constructor(private auth: AuthService, private router: Router) {
    this.initView();
  }


  ngOnInit(): void {

  }

  initView() {
    this.usr = {} as User;
    this.usr = this.auth.getUser();
  }

  onLogout() {
    this.auth.clearTokens();
    this.router.navigateByUrl('/login');
  }

}
