import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class Printer {

  constructor(private auth: AuthService) {
  }

  /**
   * Open Base64 PDF File in new Tab
   * @param base64URL
   */
  public static print2(_base64Str: string, _contentType: string): void {
    const byte = Printer.base64ToArrayBuffer(_base64Str);
    const blob = new Blob([byte], { type: _contentType });
    window.open(URL.createObjectURL(blob), '_blank');
  }

  private static base64ToArrayBuffer(_base64Str) {
    const binaryString = window.atob(_base64Str);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
}
