<div id="overlay"></div>
<div id="loader" hidden>
  <div class="app-spinner rotating"></div>
</div>

<div
  id="pcoded"
  class="pcoded"
  *ngIf="
    !isLogin &&
    !isChangeDefaultPassword &&
    !isForgotPassword &&
    !isOtpVerification &&
    !isSetNewPassword
  "
>
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <app-nav></app-nav>

    <div class="pcoded-main-container">
      <div class="pcoded-wrapper">
        <app-side-nav></app-side-nav>

        <div class="pcoded-content">
          <router-outlet></router-outlet>
        </div>

        <div id="styleSelector"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isLogin">
  <router-outlet></router-outlet>
</div>
