<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Users</h5>
                    <span>This page list all existing users of crm</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <button (click)="initView()" class="btn waves-effect waves-light btn-info float-right"><i class="ti-reload"></i>Reload</button>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">

                <div class="row">
                    <div class="col-sm-12">
                        <button class="btn btn-mat waves-effect waves-light btn-primary" (click)="printUserReport()"><i class="fa fa-print"></i>Print</button>
                        <button routerLink="/management/users/new" class="btn btn-mat waves-effect waves-light btn-info m-l-10" *ngIf="ro"><i class="fa fa-plus"></i>New User</button>
                        <div class="card m-t-10">
                            <div class="card-header">

                                <div class="row align-items-end">
                                    <div class="col-lg-5">
                                        <h5>List of Users</h5>
                                        &nbsp;&nbsp;&nbsp;
                                        <h5>Total: {{pageUser.totalElements}}</h5>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <select #pag name="pag" class="form-control searchForm" (change)="onChangeNumber(pag.value)">
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="500">500</option>
                                                    <option value="0">ALL</option>
                                                </select>
                                            </div>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control searchForm" placeholder="Search" #qElem (keyup)="onSearch(qElem.value)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block">


                                <div class="table-responsive">
                                    <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                                        <thead>
                                            <tr class="footable-header table-inverse">
                                                <th class="footable-sortable footable-first-visible" style="display: table-cell;">Username<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Name<span class="fooicon fooicon-sort"></span></th>
                                                <!-- <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Email<span class="fooicon fooicon-sort"></span></th> -->
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Credit Union<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Branch<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Role<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Status<span class="fooicon fooicon-sort"></span></th>
                                                <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;" *ngIf="ro">Action<span class="fooicon fooicon-sort"></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let usr of filterUsers">
                                                <td class="footable-first-visible" style="display: table-cell;">{{usr.username}}</td>
                                                <td style="display: table-cell;">{{usr.name}}</td>
                                                <td style="display: table-cell;" *ngIf="getbankName(usr)">
                                                    {{getbankName(usr).bank.name}}
                                                </td>
                                                <td style="display: table-cell;" *ngIf="!getbankName(usr)">

                                                </td>
                                                <td style="display: table-cell;" *ngIf="getbranchName(usr)">
                                                    {{getbranchName(usr).name}}
                                                </td>
                                                <td style="display: table-cell;" *ngIf="!getbranchName(usr)">

                                                </td>
                                                <td style="display: table-cell;">{{usr.roles}}</td>
                                                <td style="display: table-cell;">
                                                    <span *ngIf="usr.active" class="pcoded-badge label label-success">Active</span>
                                                    <span *ngIf="!usr.active" class="pcoded-badge label label-danger">Blocked</span>
                                                </td>

                                                <td style="display: table-cell;" *ngIf="ro">
                                                    <a routerLink="/management/users/edit/{{usr.id}}" class="tabledit-edit-button btn btn-primary waves-effect waves-light"><span class="icofont icofont-ui-edit"></span></a>
                                                    <button type="button" (click)="onDelete(usr.id)" class="tabledit-delete-button btn btn-danger waves-effect waves-light m-l-10"><span class="icofont icofont-ui-delete"></span></button>

                                                    <button type="button" (click)="onUpdate(usr)" class="tabledit-reload-button btn btn-default waves-effect waves-light m-l-10"><span class="ti-reload"></span></button>

                                                    <ui-switch *ngIf="usr.roleId != 1" class="switch m-l-10" size="small" [(ngModel)]="usr.active" (change)="changeStatus(usr)"></ui-switch>

                                                    <!-- <label class="switch m-l-10" *ngIf="usr.roleId != 1">
                                                        <input type="checkbox" checked (change)="changeStatus(usr)">
                                                        <span class="slider round"></span>
                                                    </label> -->
                                                </td>

                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr class="footable-paging">
                                                <td colspan="5">
                                                    <ul class="pagination ">
                                                        <li class="footable-page-nav" data-page="prev"><a class="footable-page-link" href="javascript:void(0)" (click)="previousClick()">Previous</a></li>
                                                        <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==currentSelectedPage}" data-page="1"><a (click)="getPaginationWithIndex(i, pagnum)" class="footable-page-link" href="javascript:void(0)">{{i}}</a></li>
                                                        <li class="footable-page-nav" data-page="next"><a class="footable-page-link" href="javascript:void(0)" (click)="nextClick()">Next</a></li>

                                                    </ul>
                                                    <div class="divider"></div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
