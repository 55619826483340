import { Injectable } from "@angular/core";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { DatePipe } from "@angular/common";
import { PaymentResponseToPrint, groupeBySite } from "./models";

const EXCEL_EXTENSION = '.xlsx';
const PDF_EXTENSION = '.pdf';

@Injectable()
export class ExportHelper {

  constructor(private datePipe: DatePipe) {}

  public exportexcel(json: any[], excelFileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, excelFileName);
    XLSX.writeFile(wb, 'crm_export_' + excelFileName + '_' + new  Date().getTime() + EXCEL_EXTENSION);
}

public openPDF(pdfFileName: string, json: any[], total1: number, total2: number, username: string, period: string): void {
  const doc = new jsPDF();
  var imgData
  this.getBase64ImageFromUrl().then(res => {
    imgData = 'data:image/png;base64' + res
    const currentDateAndTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    const headerRight = "Date: " + currentDateAndTime + "\n \n" + "Printed by: " + username + "\n \n" + "Period: " + period
    const pageTitle = pdfFileName
    doc.setFontSize(8)
    doc.setFont('Times', 'bold')
    doc.text(headerRight, 150, 15)
    doc.setFontSize(8)
    doc.addImage(imgData, 'PNG', 10, 10, 50, 20)
    doc.setFontSize(10)
    doc.setFont('helvetica', 'bold')
    doc.text(pageTitle, doc.internal.pageSize.getWidth() / 2, 45, {align: 'center'})
    doc.setFontSize(10)
    doc.setFont('times')
    doc.text('Number of Elements: ' + json.length, 10, 60)
    doc.text('Total Credit Union Fees: XAF ' + total1, 10, 70)
    doc.text('Total CAMCCUL Fees: XAF ' + total2, 10, 80)
    var header: RowInput[] = [];
    var rows: string[][] = [];
    json.forEach(element => {
      header.push(Object.keys(element))
    })
    json.forEach(element => {
      rows.push(Object.values(element))
    })
    autoTable(doc, {
      head: [header[0]],
      body: rows,
      theme: 'striped',
      styles: {font: 'times'},
      headStyles: {fontStyle: 'bold', fillColor: '0D47A1', fontSize: 8},
      bodyStyles: {fontSize: 6},
      startY: 90,
    });
    const pageCount = (doc as any).internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setFontSize(8);
      doc.setPage(i);
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.getWidth() / 2, pageHeight - 8);
    }
    doc.save('crm_export_' + pdfFileName + '_' + new  Date().getTime() + PDF_EXTENSION)
  })
}

public openMiniTabs(pdfFileName: string, json: groupeBySite[], total: number, username: string, branchName: string, period: string): void {
  const doc = new jsPDF();
  var imgData
  var pageSize = doc.internal.pageSize;
  var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

  console.log(pageHeight)
  this.getBase64ImageFromUrl().then(res => {
    imgData = 'data:image/png;base64' + res
    const currentDateAndTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    const headerRight = "Date: " + currentDateAndTime + "\n \n" + "Printed by: " + username + "\n \n" + "Period: " + period
    const pageTitle = pdfFileName
    const next = branchName
    doc.setFontSize(8)
    doc.setFont('Times', 'bold')
    doc.text(headerRight, 150, 15)
    doc.setFontSize(8)
    doc.addImage(imgData, 'PNG', 10, 10, 50, 20)
    doc.setFontSize(10)
    doc.setFont('helvetica', 'bold')
    doc.text(pageTitle, doc.internal.pageSize.getWidth() / 2, 45, {align: 'center'})
    doc.setFontSize(10)
    doc.setFont('helvetica', 'bold')
    doc.text(next, doc.internal.pageSize.getWidth() / 2, 50, {align: 'center'})
    doc.setFontSize(10)
    doc.setFont('times')
    doc.text('Number of Elements: ' + json.length, 10, 60)
    doc.text('Total Fees: XAF ' + total, 10, 70)
    var startFirst = 80
    json.forEach(element => {
      var height = element.payment.length * 7
      var header: RowInput[] = [];
      var rows: string[][] = [];
      element.payment.forEach(item => {
        header.push(Object.keys(new PaymentResponseToPrint(item)))
      })
      element.payment.forEach(item => {
        rows.push(Object.values(new PaymentResponseToPrint(item)))
      })
      console.log(startFirst)
      autoTable(doc, {
        head: [header[0]],
        body: rows,
        foot: [['', '', '', '', '', '', '', 'Agency Fees: ', element.total]],
        theme: 'striped',
        styles: {font: 'times'},
        headStyles: {fontStyle: 'bold', fillColor: '37474f', fontSize: 8},
        bodyStyles: {fontSize: 6},
        footStyles: {fontSize: 8, fillColor: '516670', textColor: 'FFFFFF'},
        startY: startFirst,
      });

      startFirst = startFirst + height + 15

      if(startFirst > pageHeight){
        startFirst = startFirst - pageHeight + 35
      }
    })
    const pageCount = (doc as any).internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setFontSize(8);
      doc.setPage(i);
      doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.getWidth() / 2, pageHeight - 8);
    }
    doc.save('crm_export_' + pdfFileName + '_' + new  Date().getTime() + PDF_EXTENSION)
  })
}

getBase64ImageFromUrl = async() => {
  var res = await fetch('assets/images/log111.png');
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
}
}
