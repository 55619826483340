import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { User, UserRole, BankResponseType, Chapter, Agency, APIResponse } from 'src/app/shared/models';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var showLoader;

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  usr: User;
  uId: number;
  roles: Array<UserRole>;
  banks: Array<BankResponseType>;
  chaps: Array<Chapter>;
  branchs: Array<Agency>;
  selectbanks: Array<BankResponseType>;
  selectbranchs: Array<Agency>;
  showChap: boolean;
  showCre: boolean;
  showBranch: boolean;


  constructor(private ds: DataService, private loc: Location, private router: Router, private route: ActivatedRoute, private alert: ToastrService) {
    this.initView();

  }

  ngOnInit(): void {

  }

  getUsersById(): void {
    showLoader(true);
    this.ds.get(`/users/${this.uId}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.usr = res.body.data;
        if (Number(this.usr.roleId) === Number(2)) {
          this.showChap = true;
          this.showCre = false;
          this.showBranch = false;
        } else if (Number(this.usr.roleId) === Number(3) || Number(this.usr.roleId) === Number(4)) {
          this.showChap = true;
          this.showCre = true;
          this.showBranch = true;
        } else {
          this.showChap = false;
          this.showCre = false;
          this.showBranch = false;
        }
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onUpdate() {
    showLoader(true);
    this.ds.put('/users/update/' + this.uId, this.usr).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.router.navigate(["/management/users"]);
        this.alert.success('SUCCESS', 'USER UPDATED SUCCESSFULLY');
        this.initView();
      } else {
        showLoader(false);
        this.router.navigate(["/management/users"]);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
      //console.log(err);
    });
  }

  getRoles(): void {
    showLoader(true);
    this.ds.get(`/roles/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.roles = res.body.data;
        // console.log(this.roles);
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.banks = res.body.data;
        this.selectbanks = res.body.data;
        this.getBranches();
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  getChapters(): void {
    showLoader(true);
    this.ds.get(`/chapters/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.chaps = res.body.data;
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  onChangeRol(val: number) {
    if (Number(val) === Number(2)) {
      this.showChap = true;
      this.showCre = false;
      this.showBranch = false;
    } else if (Number(val) === Number(3) || Number(val) === Number(4)) {
      this.showChap = true;
      this.showCre = true;
      this.showBranch = true;
    } else {
      this.showChap = false;
      this.showCre = false;
      this.showBranch = false;
    }
  }

  onChangeChap(val: string) {
    if (Number(this.usr.roleId) === Number(2)) {
      this.selectbanks = this.banks;
    } else {
      this.showCre = true;
      this.selectbanks = this.banks.filter((x) => {
        return val.toLowerCase() === x.chapter.code.toLowerCase();
      });
    }

  }

  onChangeChapter(val: string) {
    if (this.usr.roleId == 2) {
      this.selectbanks = this.banks;
    } else {
      this.showCre = true;
      this.selectbanks = this.banks.filter(bank => {
        if (bank.chapter) {
          return bank.chapter.code == val;
        }
      });
    }
  }

  onChangeBank(val: string) {
    this.showBranch = true;
    const userBank = this.banks.find(bank => bank.bank.code === val);
    this.usr.bqId = userBank.bank.id.toString();

    this.getBranches();
  }

  getBranches(): void {
    showLoader(true);
    this.ds.get(`/branches/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.branchs = res.body.data;

        const userBank = this.selectbanks.find(bank => bank.bank.code == this.usr.bqId)
        this.selectbranchs = this.branchs.filter(branch => {
          return branch.bqId == userBank.bank.id;
        });

      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  initView() {
    this.usr = {} as User;
    this.usr.active = true;
    this.roles = new Array<UserRole>();
    this.banks = new Array<BankResponseType>();
    this.chaps = new Array<Chapter>();
    this.branchs = new Array<Agency>();
    this.selectbanks = new Array<BankResponseType>();
    this.selectbranchs = new Array<Agency>();
    this.showCre = false;
    this.showChap = false;
    this.showBranch = false;
    this.getRoles();
    this.getChapters();
    this.getbanks();
    //this.getBranches();
    this.route.params.subscribe((param) => {
      this.uId = param.id;
      this.getUsersById();
    });
  }

  backClicked() {
    this.loc.back();
  }

}
