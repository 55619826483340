import { Component, OnInit } from '@angular/core';
import { AlertMessage, PageChapter, APIResponse, UserRole, BankResponseType, Agency, Numero, NumeroReq } from 'src/app/shared/models';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/shared/DataService';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-alert-message-list',
  templateUrl: './alert-message-list.component.html',
  styleUrls: ['./alert-message-list.component.css']
})
export class AlertMessageListComponent implements OnInit {

  page: number;
  pageUser: PageChapter;
  pages: Array<number>;
  alerts: Array<AlertMessage>;
  alertss: Array<AlertMessage>;
  filterAlerts: Array<AlertMessage>;
  banks: Array<BankResponseType>;
  branchs: Array<Agency>;
  base64File: string;
  msg: string;
  ro: boolean;
  ur: UserRole;
  currentSelectedPage: number = 0;
  pag: number;
  pagnum: number;
  selectNumsc: Array<Numero>;
  selectNumsb: Array<Numero>;
  numreq: NumeroReq;
  isGroupc: boolean;
  isGroupb: boolean;

  constructor(private ds: DataService, private loc: Location, private alert: ToastrService, private auth: AuthService) {
    this.initView();
  }

  ngOnInit(): void {
    this.initView();
  }

  initView() {
    this.page = 0;
    this.pageUser = {} as PageChapter;
    this.alerts = new Array<AlertMessage>();
    this.ur = {} as UserRole;
    this.alertss = new Array<AlertMessage>();
    this.filterAlerts = new Array<AlertMessage>();
    this.banks = new Array<BankResponseType>();
    this.branchs = new Array<Agency>();
    this.selectNumsc = new Array<Numero>();
    this.selectNumsb = new Array<Numero>();
    this.numreq = {} as NumeroReq;
    this.base64File = '';
    this.pages =[];
    this.pagnum = 20;
    this.ro = false;
    this.isGroupb = false;
    this.isGroupc = false;
    this.msg ='';
    this.getAlertsList(0);
    this.getRoles(this.auth.getUser().roleId);
    this.getBranches();
    this.getbanks();
  }

  getAlertsList(page: number): void {
    showLoader(true);
    this.ds.get(`/alerts/list/${this.pagnum}?page=${page}`).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
        this.pageUser = res.body.data;
        this.pages =  Array(this.pageUser.totalPages).fill(0).map((x,i)=>i);
        this.alerts = this.pageUser.content;
        //console.log(this.users);
        this.filterAlerts = this.pageUser.content;
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  sendSms(alrt: AlertMessage) {
    this.ds.post(`/alerts/send/sms`, alrt).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
          this.alert.success('SUCCESS', 'ALERT MESSAGE HAS BEEN SENT TO EVERY CREDIT UNION');
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  sendSmsNew() {
    this.numreq.bNum = this.selectNumsb;
    this.numreq.cNum = this.selectNumsc;
    //console.log(this.numreq);
    this.ds.post(`/alerts/send/smsnew`, this.numreq).subscribe((res: HttpResponse<APIResponse>) => {
      showLoader(false);
      if (res.body.status === 200) {
          this.alert.success('SUCCESS', 'ALERT MESSAGE HAS BEEN SENT TO SELECTED ITEMS');
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
       //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }


  onSearch(query: string): void {
    if (query !== undefined && query.length > 0) {
      this.filterAlerts = this.alerts.filter((cre) => {
        return cre.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          cre.msg.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    } else {
      this.filterAlerts = this.alerts;
    }
  }

  setPage(i) {
    this.getAlertsList(i);
  }

  getPaginationWithIndex(index: number, pag: any) {
    this.setPage(index);
  }

  active(index: number) {
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  onChangeNumber(pag: number){
    try {
      this.pagnum = pag;
      this.getAlertsList(0);
    } catch (error) {
      
    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.pageUser.totalPages - 1) {
      this.setPage(++this.currentSelectedPage);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.setPage(--this.currentSelectedPage);
    }
  }

  onDelete(id: number) {
    showLoader(true)
    this.ds.delete('/alerts/delete/' + id)
      .subscribe((res: HttpResponse<APIResponse>) => {
        if (res.status === 200) {
          showLoader(false);
          this.initView();
          this.alert.success('SUCCESS', 'ALERT MESSAGE DELETED SUCCESSFULLY');
        } else {
          showLoader(false);
          this.alert.error('SERVER ERROR', res.body.message);
          //console.log(res.body.message);
        }
      }, (err) => {
        //console.log(err);
        showLoader(false);
        this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
      });

  }

  getRoles(id: number): void {
    showLoader(true);
    this.ds.get(`/roles/${id}`).subscribe((res: HttpResponse<APIResponse>) => {

      if (res.body.status === 200) {
        this.ur = res.body.data;
        showLoader(false);
        //console.log(this.ur);
        if (this.ur.privilege.toLowerCase() == 'RW'.toLowerCase()) {
          this.ro = true;
        }
      }
    }, (err) => {
      //console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  changeStatus(alrt: AlertMessage) {
    showLoader(true);
    alrt.state = !alrt.state;
    this.ds.put('/alerts/activate/' +  alrt.id, alrt).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.alert.success('SUCCESS', 'ALERT MESSAGE UPDATED SUCCESSFULLY');
        this.initView();
      } else {
        showLoader(false);
        this.alert.error('ERROR', res.body.message);
      }
    }, (err) => {
      showLoader(false);
      this.alert.error('SERVER ERROR', 'THERE WAS AN INTERNAL SERVER ERROR');
      //console.log(err);
    });
  }

  getbanks(): void {
    showLoader(true);
    this.ds.get(`/banks/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        this.banks = res.body.data;
        this.banks.sort((a, b) => (a.bank.name > b.bank.name) ? 1 : ((b.bank.name > a.bank.name) ? -1 : 0));
        //console.log(this.banks );
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
     // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED'); 
    });
  }

  getBranches(): void {
    showLoader(true);
    this.ds.get(`/branches/`).subscribe((res: HttpResponse<APIResponse>) => {
      if (res.body.status === 200) {
        showLoader(false);
        //console.log(res.body.data);
        this.branchs = res.body.data;
        this.branchs.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      
      }else {
        showLoader(false);
        this.alert.error('SERVER ERROR', res.body.message);
        //console.log(res.body.message);
      }
    }, (err) => {
      // console.log(err);
      showLoader(false);
      this.alert.error('SERVER ERROR', 'AN UNEXPECTED ERROR HAS OCCURED');
    });
  }

  backClicked() {
    this.loc.back();
  }

  onSelectAllc(event) {
    try {
      this.selectNumsc = new Array<Numero>();
      const checked = event.target.checked;
      this.banks.forEach((item) => {
        item.selected = checked;
        if (checked == true || this.selectNumsc === []) {
          let b = {} as Numero;
          b.num = item.bank.tel;
          b.id = item.bank.id;
          b.code = item.bank.code;
          b.msg = this.msg;
          this.selectNumsc.push(b);
        } else {
          this.selectNumsc = new Array<Numero>();
        }
      });
     // console.log(this.selectNumsc);

      if(this.selectNumsc.length === 0){
        this.isGroupc = false ;
      } else {
        this.isGroupc = true;
      }
    } catch (error) {

    }
  }

  onSelectedc(event, d: BankResponseType) {
    try {
      let nu = {} as Numero;
      nu.code = d.bank.code;
      nu.id = d.bank.id;
      nu.num = d.bank.tel;
      nu.msg = this.msg;
      let idx = this.selectNumsc.indexOf(nu);
      if (event.target.checked) {
        this.selectNumsc.push(nu);
        //console.log(this.selectNumsc);
      } else {
        this.selectNumsc.splice(idx, 1);
       // console.log(this.selectNumsc);
      }

      if(this.selectNumsc.length === 0){
        this.isGroupc = false ;
      } else {
        this.isGroupc = true;
      }

    } catch (error) {

    }
  }

  onSelectAllb(event) {
    try {
      this.selectNumsb = new Array<Numero>();
      const checked = event.target.checked;
      this.branchs.forEach((item) => {
        item.selected = checked;
        if (checked == true || this.selectNumsb === []) {
          let b = {} as Numero;
          b.num = item.tel;
          b.id = item.id;
          b.code = item.code;
          b.msg = this.msg;
          this.selectNumsb.push(b);
        } else {
          this.selectNumsb = new Array<Numero>();
        }
      });
      //console.log(this.selectNumsb);

      if(this.selectNumsb.length === 0){
        this.isGroupb = false ;
      } else {
        this.isGroupb = true;
      }
    } catch (error) {

    }
  }

  onSelectedb(event, d: Agency) {
    try {
      let nu = {} as Numero;
      nu.code = d.code;
      nu.id = d.id;
      nu.num = d.tel;
      nu.msg = this.msg;
      let idx = this.selectNumsb.indexOf(nu);
      if (event.target.checked) {
        this.selectNumsb.push(nu);
        //console.log(this.selectNumsb);
      } else {
        this.selectNumsb.splice(idx, 1);
        //console.log(this.selectNumsb);
      }

      if(this.selectNumsb.length === 0){
        this.isGroupb = false ;
      } else {
        this.isGroupb = true;
      }

    } catch (error) {

    }
  }

}



