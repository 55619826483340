<div class="page-header card">
    <div class="row align-items-end">
        <div class="col-lg-7">
            <div class="page-header-title">
                <a href="javascript:void(0);" (click)="backClicked()"><i class="feather icon-arrow-left  bg-c-blue"></i></a>
                <div class="d-inline">
                    <h5>Billing Report</h5>
                    <span>This page allows the admin to view all the billing reports</span>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="page-header-breadcrumb">
                <ul class=" breadcrumb breadcrumb-title">
                    <li class="breadcrumb-item">
                        <a href="index.html"><i class="feather icon-home"></i></a>
                    </li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Management</a> </li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Billing Report</a> </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="pcoded-inner-content">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="page-body">
                <button class="btn btn-mat waves-effect waves-light btn-primary"><i class="fa fa-print"></i>Print</button>
                <button *ngIf="showDetail" (click)="closeDetail()" class="btn btn-mat waves-effect waves-light btn-danger m-l-10">Close Detail</button>

                <div *ngIf="showDetail" class="card m-t-10">
                    <div class="card-header">
                        <div class="row align-items-end">
                            <div class="col-lg-7">
                                <h5>Billing Details for : {{bilDet[0].branch}}</h5>
                            </div>
                            <div class="col-lg-5">

                            </div>
                        </div>
                    </div>
                    <div class="card-block">
                        <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                            <thead>
                                <tr class="footable-header table-inverse">
                                    <th class="footable-sortable footable-first-visible" style="display: table-cell;">Credit Union<span class="fooicon fooicon-sort"></span></th>
                                    <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Branch<span class="fooicon fooicon-sort"></span></th>
                                    <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">User<span class="fooicon fooicon-sort"></span></th>
                                    <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Operation<span class="fooicon fooicon-sort"></span></th>
                                    <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Frequency<span class="fooicon fooicon-sort"></span></th>
                                    <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Unit Price (XAF)<span class="fooicon fooicon-sort"></span></th>
                                    <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Total (XAF)<span class="fooicon fooicon-sort"></span></th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let bd of bilDet" data-toggle="tooltip" data-placement="top" title="Click to view detail" (click)="getBillingDetail(bd.BQID, bd.AGCCPT)">

                                    <td class="footable-first-visible" style="display: table-cell; width: 30%">{{bd.bank}}</td>
                                    <td style="display: table-cell; width: 50%">{{bd.branch}}</td>
                                    <td style="display: table-cell; width: 10%">{{bd.name}}</td>
                                    <td style="display: table-cell; width: 10%">{{getOperationName(bd.act).name}}</td>
                                    <td style="display: table-cell; width: 10%" class="text-right"> {{bd.FREQ | number}}</td>
                                    <td style="display: table-cell; width: 10%" class="text-right">{{bd.TOTAL_AMOUNT / bd.FREQ | number}}</td>
                                    <td style="display: table-cell; width: 10%" class="text-right">{{bd.TOTAL_AMOUNT | number}}</td>
                                </tr>
                                <tr class="bg-primary">
                                    <td colspan="6" style="display: table-cell;">
                                        <h5><b>Total:</b></h5>
                                    </td>
                                    <td class="text-right" style="display: table-cell;">
                                        <h5><b>{{detailTot | number}}</b></h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="card m-t-10">
                    <div class="card-header">
                        <div class="row align-items-end">
                            <div class="col-lg-7">
                                <h5>Billing Summary</h5>
                            </div>
                            <div class="col-lg-5 ">
                                <span class="f-40 f-w-900 float-right">Total Billing Summary (XAF): {{tot | number}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-block">
                        <table id="demo-foo-filtering" class="table table-striped footable footable-1 footable-paging footable-paging-center table-styling breakpoint-lg">
                            <thead>
                                <tr class="footable-header table-inverse">
                                    <th class="footable-sortable footable-first-visible" style="display: table-cell;">Credit Union<span class="fooicon fooicon-sort"></span></th>
                                    <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Branch<span class="fooicon fooicon-sort"></span></th>
                                    <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Frequency<span class="fooicon fooicon-sort"></span></th>
                                    <th data-breakpoints="xs" class="footable-sortable" style="display: table-cell;">Total (XAF)<span class="fooicon fooicon-sort"></span></th>

                                </tr>
                            </thead>
                            <tbody *ngFor="let bs of bills">
                                <tr class="bg-primary">
                                    <td class="text-center" colspan="4" style="display: table-cell;">
                                        <h5><b>{{bs.bank}}</b></h5>
                                    </td>
                                </tr>
                                <tr *ngFor="let b of bs.agc" (click)="getBillingDetail(b.BQID, b.AGCCPT)">

                                    <td class="footable-first-visible" style="display: table-cell; width: 30%">{{bs.bank}}</td>
                                    <td style="display: table-cell; width: 50%">{{b.branch}}</td>
                                    <td style="display: table-cell; width: 10%" class="text-right">{{b.FREQ}}</td>
                                    <td style="display: table-cell; width: 10%" class="text-right">{{b.TOTAL_AMOUNT | number}}</td>
                                </tr>

                            </tbody>
                            <tfoot>
                                <tr class="footable-paging">
                                    <td colspan="5">
                                        <ul class="pagination">
                                            <!-- <li class="footable-page-nav disabled" data-page="first"><a class="footable-page-link" href="#">«</a></li>
                                            <li class="footable-page-nav disabled" data-page="prev"><a class="footable-page-link" href="#">‹</a></li> -->
                                            <li *ngFor="let p of pages; let i=index" class="footable-page visible" [ngClass]="{'active' : i==page}" data-page="1"><a (click)="setPage(i, $event)" class="footable-page-link" href="#">{{i}}</a></li>
                                            <!-- <li class="footable-page-nav" data-page="next"><a class="footable-page-link" href="#">›</a></li>
                                            <li class="footable-page-nav" data-page="last"><a class="footable-page-link" href="#">»</a></li> -->
                                        </ul>
                                        <div class="divider"></div>
                                    </td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>